import { omit, sortBy } from 'lodash';

import { TableBuilderStore as Store } from '../../stores';
import {
  ITableBuilderRow as IRow,
  ITableBuilderRowsGroup as IRowsGroup,
} from '../../../models/data';
import {
  ITableBuilderRowConfig as IRowConfig,
  ITableBuilderRowsGroupConfig as IRowsGroupConfig,
} from '../../../models/configs';
import { lazyInject, provide } from '../../../../../utils/helpers/mobx';

@provide.transient()
class TableBuilderRowsService {
  @lazyInject(Store)
  protected store: Store;

  addRowsGroupList = (
    builderId: string,
    configList: IRowsGroupConfig[],
    options?: {
      rootRowId?: string;
    }
  ): void => {
    const rowsGroupList = this.createRowsGroupList(builderId, configList, options);

    this.store.setRowsGroupList(builderId, rowsGroupList);
  };

  protected createRowsGroupList = (
    builderId: string,
    configList: IRowsGroupConfig[],
    options?: {
      rootRowId?: string;
      initialOrder?: number;
    }
  ): IRowsGroup[] => {
    const previouslyCreated = this.store.getRowsGroupList(builderId, {
      byRootRowId: options?.rootRowId,
    });

    const orderedList = sortBy(previouslyCreated, 'order');
    const lastOrder: number = !orderedList.length ? 0 : orderedList[orderedList.length - 1].order;

    const rowGroupList = configList.map((config, i) => {
      const order = lastOrder + 1 + i;

      const rowsGroup = this.createRowsGroup(builderId, config, order, options);

      return rowsGroup;
    });

    return rowGroupList;
  };

  protected createRowsGroup = (
    builderId: string,
    config: IRowsGroupConfig,
    order: number,
    options?: {
      rootRowId?: string;
    }
  ): IRowsGroup => {
    const omittedConfig = omit(config, ['rowConfigList']);

    const rowsGroup: IRowsGroup = {
      ...omittedConfig,
      builderId,
      order,
      rowIdList: [],
      renderType: null,
    };

    if (options?.rootRowId) {
      rowsGroup.rootRowId = options.rootRowId;
    }

    this.addRowList(builderId, config.id, config.rowConfigList);

    rowsGroup.rowIdList = config.rowConfigList.map(({ id }) => id);

    if (config?.autoRenderConfig) {
      rowsGroup.renderType = 'auto';
    }

    if (config?.customRenderConfig) {
      rowsGroup.renderType = 'custom';
    }

    return rowsGroup;
  };

  addRowList = (
    builderId: string,
    rowsGroupId: string,
    configList: IRowConfig[],
    options?: { isClearPreviousList?: boolean }
  ): void => {
    const rowList = this.createRowList(builderId, rowsGroupId, configList);

    this.store.setRowList(builderId, rowList, options);
  };

  protected createRowList = (
    builderId: string,
    rowsGroupId: string,
    configList: IRowConfig[]
  ): IRow[] => {
    const rowList = configList.map((config, i) =>
      this.createRow(builderId, rowsGroupId, config, i + 1)
    );

    return rowList;
  };

  protected createRow = (
    builderId: string,
    rowsGroupId: string,
    config: IRowConfig,
    order: number
  ): IRow => {
    const omittedConfig = omit(config, ['rowsGroupConfigList']);

    const row: IRow = {
      ...omittedConfig,
      builderId,
      rowsGroupId,
      order,
      renderType: null,
    };

    if (config?.autoRenderConfig) {
      row.renderType = 'auto';
    }

    if (config?.customRenderConfig) {
      row.renderType = 'custom';
    }

    if (config.rowsGroupConfigList) {
      row.renderType = 'nested';

      this.addRowsGroupList(builderId, config.rowsGroupConfigList, { rootRowId: row.id });
    }

    return row;
  };

  addPartialRowConfig = (builderId: string, partialConfig: Partial<IRowConfig>): void => {
    this.store.setPartialRowConfig(builderId, partialConfig);
  };
}

export default TableBuilderRowsService;
