import { useEffect } from 'react';

import { EContextualParentPath } from '../../constants/contextualPath';
import { useStore } from '../../utils/helpers/mobx';
import { ReferencesService } from '../../mobx/services/as-fields/ReferencesService';
import { useContextualHelpActions } from '../../features/ContextualHelp/hooks';

const usePageContextualHelp = (parentPath: EContextualParentPath) => {
  const referencesService = useStore(ReferencesService);
  const helpActions = useContextualHelpActions();

  useEffect(() => {
    helpActions.initiateContextualHelpList(parentPath, async () => {
      const response = await referencesService.getReferenceList({
        active: true,
        size: 2000,
        parentPath,
      });

      if (response.content) return response.content;
      else return [];
    });
  }, []);
};

export default usePageContextualHelp;
