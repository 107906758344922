import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../common/assets/styles/colors';
import { Container } from '../../../../../../../../../common/features/UI';

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  color: ${ECommonColor.GeneralBlack};
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 100px;
`;

const StyledApproval = {
  Title,
  HeaderWrapper,
  Container: StyledContainer,
};

export default StyledApproval;
