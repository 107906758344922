import styled from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

const Wrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 24px 32px;
  margin-bottom: 100px;
  border-radius: 16px;
  background-color: ${Colors.generalWhite};
`;

const Title = styled.div`
  font-family: Proxima Nova;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.5px;

  padding-bottom: 12px;

  border-bottom: 1px dashed ${Colors.borderGray};
`;

const StyledTotalComparison = {
  Wrapper,
  Container,
  Title,
};

export default StyledTotalComparison;
