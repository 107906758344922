import { observer } from 'mobx-react';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { CreateObservationController } from '../../containers/CreateObservation/mobx/controllers';
import { CreateObservationStore } from '../../containers/CreateObservation/mobx/stores';
import {
  createExpirimentOptionList,
  createOptionListFromUsers,
} from '../../containers/CreateObservation/utils/functions';
import { useForm } from '../../../../../../../../../common/features/form/utils/hooks';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import {
  REQUIRED_TEXT,
  START_DATE_NOT_AFTER_END_DATE_ERROR_TEXT,
} from '../../../../../../../../../common/utils/constants/dateValidation';
import { formatDateToISO } from '../../../../../../../../../common/utils/helpers/dates';
import { ISeason } from '../../../../../../../../../../api/models/as-fields/seasons';
import { TechOperationController } from '../../../../../../../experiments/modules/createExperiment/modules/history/containers/History/mobx/controllers/TechOperation.controller';
import { AuditInfo } from '../../../Info/modules/auditInfo';
import { EDashboardRoute } from '../../../../../../../../routes/DashboardRoute';
import { EAuditsRoute } from '../../../../../../routes';
import { EAuditRoute } from '../../../../routes';
import { createExpStepSelectOptionList } from '../../../../../../../../../common/utils/helpers/selectOptions';

import {
  COMMON_INFORMATION_FORM_KEY,
  createCommonInformationFormConfig,
  ICommonInformationForm,
} from './forms/config/commonInformationFormConfig';

const CommonInformation: FC = observer(() => {
  const {
    elements,
    formData,
    registerForm,
    addOptionList,
    isSubmitDisabled,
    submitForm,
    addDatePickerParams,
    setElementErrorShow,
    changeListOfFormValue,
    addPaginationConfig,
  } = useForm<ICommonInformationForm>(COMMON_INFORMATION_FORM_KEY);

  const {
    fetchAudit,
    saveExperimentStep,
    organizationsSearchQueryHandler,
    experimentSearchQueryHandler,
    usersSearchQueryHandler,
    operationTypesSearchQueryHandler,
    experimentStepsSearchQueryHandler,
    changeDropdownPageNumber,
    onOrganizationListScroll,
    onExperimentListScroll,
    onExperimentStepListScroll,
    onTechOperationListScroll,
    onAssigneeListScroll,
  } = useStore(CreateObservationController);

  const {
    expiriments,
    allUsers,
    expirimentSteps,
    setExperimentId,
    setOrganizationId,
    getExperimentInfoById,
    searchQueries,
    setOrganizationOptionList,
    dropdownPageNumbers,
    organizationOptionList,
    setExperimentOptionList,
    experimentOptionList,
    experimentStepOptionList,
    setExperimentStepOptionList,
    assigneeOptionList,
    setAssigneeOptionList,
    operationOptionList,
    setOperationOptionList,
    getExpStepOption,
    clearStore,
  } = useStore(CreateObservationStore);

  const { getCurrentSeason } = useStore(TechOperationController);

  const [currentSeason, setCurrentSeason] = useState<ISeason | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      clearStore();
    };
  }, []);

  useEffect(() => {
    registerForm(
      createCommonInformationFormConfig({
        organizationsSearchQueryHandler,
        experimentSearchQueryHandler,
        usersSearchQueryHandler,
        operationTypesSearchQueryHandler,
        experimentStepsSearchQueryHandler,
      })
    );
  }, []);

  useEffect(() => {
    if (formData?.organizationId) {
      setOrganizationId(formData?.organizationId);
      experimentSearchQueryHandler('');
    }
  }, [formData?.organizationId]);

  useEffect(() => {
    const experimentList = createExpirimentOptionList(expiriments);
    addOptionList('experimentId', experimentList);
  }, [expiriments]);

  useEffect(() => {
    (async () => {
      if (formData?.experimentId) {
        const { experimentId } = formData;

        setExperimentId(experimentId);

        const experimentInfo = getExperimentInfoById(experimentId);

        const seasonInfo = await getCurrentSeason(
          formData.organizationId,
          experimentInfo.seasonYear
        );

        setCurrentSeason(seasonInfo);
      }
    })();
  }, [formData?.experimentId]);

  useEffect(() => {
    if (currentSeason) {
      const { startDate, endDate } = currentSeason;

      const minDate = new Date(startDate);
      const maxDate = new Date(endDate);

      const dateRangeParams = {
        minDate,
        maxDate,
      };

      addDatePickerParams('planStartDate', {
        startDate: minDate,
        dateRange: dateRangeParams,
      });

      addDatePickerParams('planEndDate', {
        startDate: maxDate,
        dateRange: dateRangeParams,
      });

      changeListOfFormValue({
        planStartDate: startDate,
        planEndDate: endDate,
      });

      experimentStepsSearchQueryHandler('');
      operationTypesSearchQueryHandler('');
      usersSearchQueryHandler('');
    }
  }, [currentSeason, formData?.organizationId]);

  useEffect(() => {
    if (formData?.planEndDate && formData?.planStartDate) {
      const startDate = new Date(formData.planStartDate);
      const endDate = new Date(formData.planEndDate);

      if (startDate.getTime() > endDate.getTime()) {
        setElementErrorShow('planEndDate', true, START_DATE_NOT_AFTER_END_DATE_ERROR_TEXT);
      } else {
        setElementErrorShow('planEndDate', false, REQUIRED_TEXT);
      }
    }
  }, [formData?.planEndDate, formData?.planStartDate]);

  useEffect(() => {
    if (formData?.parentStepId) {
      const expStep = getExpStepOption(formData.parentStepId).initialModel;

      if (expStep?.techOperationType) {
        const presetOption = {
          value: expStep.techOperationType.id,
          label: expStep.techOperationType.name,
        };

        addOptionList('techOperationTypeId', [presetOption]);

        setOperationOptionList([presetOption]);
        changeListOfFormValue({ techOperationTypeId: expStep.techOperationType.id });
      }
    }
  }, [formData?.parentStepId]);

  useEffect(() => {
    const stepsList = createExpStepSelectOptionList(expirimentSteps, { isAddInitialModel: true });
    addOptionList('parentStepId', stepsList);
  }, [expirimentSteps]);

  useEffect(() => {
    const usersList = createOptionListFromUsers(allUsers);
    addOptionList('assigneeId', usersList);
  }, [allUsers]);

  const onOrganizationScroll = async () => {
    const newOrganizationOptionList = await onOrganizationListScroll(
      searchQueries.organizationSearchQuery
    );

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('organizationId', [...organizationOptionList, ...newOrganizationOptionList]);

    setOrganizationOptionList([...organizationOptionList, ...newOrganizationOptionList]);
  };

  useEffect(() => {
    addPaginationConfig('organizationId', {
      currentPage: dropdownPageNumbers.organizationCurrentPageNumber,
      totalPages: dropdownPageNumbers.organizationTotalPageNumber,
      onScroll: onOrganizationScroll,
      onPageChange: () => changeDropdownPageNumber('organizationCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.organizationCurrentPageNumber,
    dropdownPageNumbers.organizationTotalPageNumber,
    onOrganizationScroll,
    changeDropdownPageNumber,
  ]);

  const onExperimentScroll = async () => {
    const newExperimentOptionList = await onExperimentListScroll(
      searchQueries.experimentSearchQuery
    );

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('experimentId', [...experimentOptionList, ...newExperimentOptionList]);

    setExperimentOptionList([...experimentOptionList, ...newExperimentOptionList]);
  };

  useEffect(() => {
    addPaginationConfig('experimentId', {
      currentPage: dropdownPageNumbers.experimentCurrentPageNumber,
      totalPages: dropdownPageNumbers.experimentTotalPageNumber,
      onScroll: onExperimentScroll,
      onPageChange: () => changeDropdownPageNumber('experimentCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.experimentCurrentPageNumber,
    dropdownPageNumbers.experimentTotalPageNumber,
    onExperimentScroll,
    changeDropdownPageNumber,
  ]);

  const onExperimentStepScroll = async () => {
    const newExperimentStepOptionList = await onExperimentStepListScroll(
      searchQueries.experimentStepSearchQuery
    );

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('parentStepId', [...experimentStepOptionList, ...newExperimentStepOptionList]);

    setExperimentStepOptionList([...experimentStepOptionList, ...newExperimentStepOptionList]);
  };

  useEffect(() => {
    addPaginationConfig('parentStepId', {
      currentPage: dropdownPageNumbers.experimentStepCurrentPageNumber,
      totalPages: dropdownPageNumbers.experimentStepTotalPageNumber,
      onScroll: onExperimentStepScroll,
      onPageChange: () => changeDropdownPageNumber('experimentStepCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.experimentStepCurrentPageNumber,
    dropdownPageNumbers.experimentStepTotalPageNumber,
    onExperimentStepScroll,
    changeDropdownPageNumber,
  ]);

  const onTechOperationScroll = async () => {
    const newTechOperationOptionList = await onTechOperationListScroll(
      searchQueries.techOperationSearchQuery
    );

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('techOperationTypeId', [...operationOptionList, ...newTechOperationOptionList]);

    setOperationOptionList([...operationOptionList, ...newTechOperationOptionList]);
  };

  useEffect(() => {
    addPaginationConfig('techOperationTypeId', {
      currentPage: dropdownPageNumbers.techOperationCurrentPageNumber,
      totalPages: dropdownPageNumbers.techOperationTotalPageNumber,
      onScroll: onTechOperationScroll,
      onPageChange: () => changeDropdownPageNumber('techOperationCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.techOperationCurrentPageNumber,
    dropdownPageNumbers.techOperationTotalPageNumber,
    onTechOperationScroll,
    changeDropdownPageNumber,
  ]);

  const onAssigneeScroll = async () => {
    const newAssigneeOptionList = await onAssigneeListScroll(searchQueries.assigneeSearchQuery);

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('assigneeId', [...assigneeOptionList, ...newAssigneeOptionList]);

    setAssigneeOptionList([...assigneeOptionList, ...newAssigneeOptionList]);
  };

  useEffect(() => {
    addPaginationConfig('assigneeId', {
      currentPage: dropdownPageNumbers.assigneeCurrentPageNumber,
      totalPages: dropdownPageNumbers.assigneeTotalPageNumber,
      onScroll: onAssigneeScroll,
      onPageChange: () => changeDropdownPageNumber('assigneeCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.assigneeCurrentPageNumber,
    dropdownPageNumbers.assigneeTotalPageNumber,
    onAssigneeScroll,
    changeDropdownPageNumber,
  ]);

  const handleReadyClick = useCallback(async () => {
    await submitForm(async valideedData => {
      valideedData.planEndDate = formatDateToISO(valideedData.planEndDate);
      valideedData.planStartDate = formatDateToISO(valideedData.planStartDate);

      const experimentStepInfo = await saveExperimentStep(valideedData);

      if (experimentStepInfo?.id) {
        const audit = await fetchAudit(experimentStepInfo.id);
        navigate(
          generatePath(`/${EDashboardRoute.Audits}/${EAuditsRoute.Audit}/${EAuditRoute.Info}`, {
            auditId: audit.id,
          })
        );
      }
    });
  }, []);

  return (
    <AuditInfo
      formElements={elements}
      handleReadyClick={handleReadyClick}
      titleName={'Общая информация'}
      buttonName={'Создать наблюдение'}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
});

export default memo(CommonInformation);
