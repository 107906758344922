import styled, { css } from 'styled-components';

const FixedWrapper = styled.div<{
  $columnsWidth: string;
  $hasFullContainerWidth: boolean;
  $isHeader: boolean;
}>`
  display: grid;

  grid-template-columns: ${({ $columnsWidth }) => $columnsWidth};

  & > :not(:last-child) {
    padding-right: 16px;
  }

  ${({ $isHeader }) =>
    $isHeader &&
    css`
      align-items: center;
    `}}
  
  ${({ $hasFullContainerWidth }) =>
    $hasFullContainerWidth &&
    css`
      width: 100%;
    `}}
`;

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  gap: 16px;
`;

const StyledComparisonTableBuilderGrid = {
  FixedWrapper,
  Wrapper,
};

export default StyledComparisonTableBuilderGrid;
