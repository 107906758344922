import { IExperimentCultureZone } from '../../../../../../../../../../../api/models/as-fields/experiments';
import { IDictionaryEntity } from '../../../../../../../../../../../api/models/da-dictionary/dictionary';

export const createSelectOptionsInCultureZones = (cultureZones: IExperimentCultureZone[]) => {
  return cultureZones.map(cultureZone => ({
    label: cultureZone.cultureZone.name,
    value: cultureZone.id,
  }));
};

export const createSelectOptionInBbch = (bbch: IDictionaryEntity[]) => {
  return bbch.map(item => ({ label: `${item.name}`, value: item.id }));
};
