import { observer } from 'mobx-react';
import { FC, memo, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AuditInfoController } from '../mobx/controllers';
import { AuditInfoStore } from '../mobx/stores';
import { useForm } from '../../../../../../../../../../common/features/form/utils/hooks';
import { useStore } from '../../../../../../../../../../common/utils/helpers/mobx';
import { TAuditsParams } from '../../../../../../../models/params/AuditsParams.model';
import { AuditInfo } from '../../../modules/auditInfo';
import { CreateObservationController } from '../../../../CreateAudit/containers/CreateObservation/mobx/controllers';
import { formatDateToISO } from '../../../../../../../../../../common/utils/helpers/dates';
import { generateAuditsPath } from '../../../../../../../utils/helpers';
import { CreateObservationStore } from '../../../../CreateAudit/containers/CreateObservation/mobx/stores';

import {
  AUDIT_INFO_FORM_KEY,
  createAuditInfoFormConfig,
  IAuditInfoForm,
} from './forms/config/auditInfoFormConfig';

const EditAuditInfo: FC = observer(() => {
  const auditInfoStore = useStore(AuditInfoStore);

  const { auditId } = useParams<TAuditsParams>();

  const { auditInfo, setAuditStartDate, setAuditName, clearStore } = auditInfoStore;

  const { searchQueries, dropdownPageNumbers, assigneeOptionList, setAssigneeOptionList } =
    useStore(CreateObservationStore);

  const {
    elements,
    registerForm,
    addOptionList,
    changeListOfFormValue,
    submitForm,
    formData,
    addPaginationConfig,
  } = useForm<IAuditInfoForm>(AUDIT_INFO_FORM_KEY);

  const { getAuditInfo } = useStore(AuditInfoController);

  const {
    usersSearchQueryHandler,
    fetchAudit,
    updateExperimentStep,
    onAssigneeListScroll,
    changeDropdownPageNumber,
  } = useStore(CreateObservationController);

  const navigate = useNavigate();

  useEffect(() => {
    getAuditInfo(auditId);
    registerForm(createAuditInfoFormConfig({ usersSearchQueryHandler }));

    return () => {
      clearStore();
    };
  }, []);

  useEffect(() => {
    if (auditInfo && auditId) {
      const {
        organization,
        experiment,
        techOperationType,
        assignee,
        name,
        parentStep,
        planStartDate,
        planEndDate,
      } = auditInfo;

      changeListOfFormValue({
        organizationId: organization?.id,
        experimentId: experiment?.id,
        planStartDate,
        planEndDate,
        parentStepId: parentStep?.id,
        techOperationTypeId: techOperationType?.id,
        assigneeId: assignee?.id,
        name,
      });

      addOptionList('organizationId', [{ label: organization?.name, value: organization?.id }]);
      addOptionList('experimentId', [{ label: experiment?.code, value: experiment?.id }]);
      addOptionList('parentStepId', [{ label: parentStep?.name, value: parentStep?.id }]);
      addOptionList('assigneeId', [{ label: assignee?.fullName, value: assignee?.id }]);
      addOptionList('techOperationTypeId', [
        { label: techOperationType?.name, value: techOperationType?.id },
      ]);

      setAuditStartDate(planStartDate);
      setAuditName(name);
    }
  }, [auditInfo]);

  const onAssigneeScroll = async () => {
    const newAssigneeOptionList = await onAssigneeListScroll(searchQueries.assigneeSearchQuery);

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('assigneeId', [...assigneeOptionList, ...newAssigneeOptionList]);

    setAssigneeOptionList([...assigneeOptionList, ...newAssigneeOptionList]);
  };

  useEffect(() => {
    addPaginationConfig('assigneeId', {
      currentPage: dropdownPageNumbers.assigneeCurrentPageNumber,
      totalPages: dropdownPageNumbers.assigneeTotalPageNumber,
      onScroll: onAssigneeScroll,
      onPageChange: () => changeDropdownPageNumber('assigneeCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.assigneeCurrentPageNumber,
    dropdownPageNumbers.assigneeTotalPageNumber,
    onAssigneeScroll,
    changeDropdownPageNumber,
  ]);

  const handleReadyClick = useCallback(async () => {
    await submitForm(async valideedData => {
      valideedData.planEndDate = formatDateToISO(valideedData.planEndDate);
      valideedData.planStartDate = formatDateToISO(valideedData.planStartDate);

      const experimentStepInfo = await updateExperimentStep(valideedData, auditId);

      if (experimentStepInfo?.id) {
        await fetchAudit(experimentStepInfo.id);
        navigate(generateAuditsPath('auditInfo', { auditId: experimentStepInfo?.id }));
      }
    });
  }, [formData, submitForm]);

  return (
    <AuditInfo
      formElements={elements}
      handleReadyClick={handleReadyClick}
      titleName={'Общая информация'}
      buttonName={'Сохранить'}
    />
  );
});

export default memo(EditAuditInfo);
