import React, { FC, memo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../utils/helpers/mobx';
import { DynamicTableStore } from '../../../mobx/stores';
import { ComparisonTableImagesStage as ImagesStage } from '../../../components/stages';

interface IProps {
  tableId: string;
  imagesStageId: string;
}

const ComparisonTableImagesStageContainer: FC<IProps> = ({ tableId, imagesStageId }) => {
  const { getStage, getImagesConfig } = useStore(DynamicTableStore);

  const imagesStage = getStage(tableId, imagesStageId);
  const imagesConfig = getImagesConfig(imagesStageId);

  return (
    <>
      {imagesStage ? <ImagesStage extendedStage={imagesStage} imagesConfig={imagesConfig} /> : null}
    </>
  );
};

ComparisonTableImagesStageContainer.displayName = 'ComparisonTableImagesStageContainer';

export default memo(observer(ComparisonTableImagesStageContainer));
