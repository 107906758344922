import { IComparisonTableBuilderConfig } from '../../../../../../../../../../common/features/ComparisonTableBuilder/models/configs/ComparisonTableBuilderConfig/ComparisonTableBuilderConfig';
import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { ComparisonStore } from '../../stores';
import { ExperimentService } from '../../../../../../../../../../common/mobx/services/as-fields';
import { ComparisonService } from '../../services';
import { ComparisonTableBuilderController } from '../../../../../../../../../../common/features/ComparisonTableBuilder/mobx/controllers';
import { tabContent } from '../../../utils/constants';
import { createComparisonTableBuilderId as createTableBuilderId } from '../../../utils/helpers/createComparisonTableBuilderId';
import { EComparisonTableName } from '../../../../../../../../../constants/features';
import {
  PairWiseComparisonColumnsService,
  PairWiseComparisonRowsService,
} from '../../services/pairWise';
import { ComparisonHeader as Header } from '../../../components';

@provide.transient()
class ComparisonController {
  @lazyInject(ComparisonStore)
  comparisonStore: ComparisonStore;

  @lazyInject(ComparisonService)
  comparisonService: ComparisonService;

  @lazyInject(ExperimentService)
  experimentService: ExperimentService;

  @lazyInject(ComparisonTableBuilderController)
  protected comparisonTableController: ComparisonTableBuilderController;

  @lazyInject(PairWiseComparisonRowsService)
  protected pairWiseRowsService: PairWiseComparisonRowsService;

  @lazyInject(PairWiseComparisonColumnsService)
  protected pairWiseColumnsService: PairWiseComparisonColumnsService;

  initiateTables = async (experimentId: string): Promise<void> => {
    const data = await this.experimentService.getExperimentPlanTable({
      id: experimentId,
    });

    if (data) {
      this.comparisonStore.setHeaderList(data.headers);
      this.comparisonStore.setComparisonTableList(data.comparisonTables);

      tabContent.forEach(tab => {
        /**
         * Не собирать таблицу сравнения, если меньше 2 участков.
         */
        if (data.headers.length < 2 && tab.id === 'pairwise') {
          return;
        }

        if (tab.id === 'pairwise') {
          const config = this.comparisonService.createTableConfig(
            data,
            tab.id,
            this.changeTableRows,
            [
              { id: data.headers[0].id, name: data.headers[0].name, position: 0 },
              { id: data.headers[1].id, name: data.headers[1].name, position: 1 },
            ]
          );

          this.comparisonTableController.initiateTable(config);
        } else {
          const config = this.comparisonService.createTableConfig(data, tab.id);

          this.comparisonTableController.initiateTable(config);
        }
      });
    } else {
      this.comparisonStore.setIsComparisonDataExist(false);
    }

    this.disablePageLoading();
  };

  changeTableRows = (newSelectedFields): void => {
    this.clearPairWiseTableData();

    const config: IComparisonTableBuilderConfig = {
      id: createTableBuilderId(tabContent[1].id, EComparisonTableName.Comparison),
      name: createTableBuilderId(tabContent[1].id, EComparisonTableName.Comparison),
      headerConfig: {
        customRenderConfig: {
          render: (header, columnsChildren, dataTestId) => (
            <Header dataTestId={dataTestId}>{columnsChildren}</Header>
          ),
        },
      },
      columnConfigList: this.pairWiseColumnsService.createColumnConfigList(
        this.comparisonStore.headerList,
        newSelectedFields,
        this.changeTableRows
      ),
      rowsGroupConfigList: this.pairWiseRowsService.createBlockRowsGroupConfigList(
        this.comparisonStore.comparisonTableList,
        newSelectedFields
      ),
    };

    config.cellConfigs = this.pairWiseRowsService.cellConfigs;

    this.comparisonTableController.initiateTable(config);
  };

  clearPairWiseTableData = () => {
    this.comparisonTableController.clearStore(
      createTableBuilderId(tabContent[1].id, EComparisonTableName.Comparison)
    );
  };

  clearAllTablesData = () => {
    this.comparisonTableController.clearStore(
      createTableBuilderId(tabContent[0].id, EComparisonTableName.Comparison)
    );

    this.clearPairWiseTableData();
  };

  disablePageLoading = (): void => {
    this.comparisonStore.setIsPageLoading(false);
  };
}

export default ComparisonController;
