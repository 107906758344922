import { FC, useCallback } from 'react';

import { ICultureZoneCell } from '../../utils/hooks/zonesListTableHooks/zonesListTable.hooks';
import { RadioButton } from '../RadioButton';
import { EExperimentCultureZoneType } from '../../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { useStore } from '../../../../../../../../../../../../../common/utils/helpers/mobx';
import { ZonesController } from '../../../../mobx/controllers';
import { useMap } from '../../../../../../../../../../../../../common/features/experimentsMap/hooks';
import { ZonesStore } from '../../../../mobx/stores';

interface IProps {
  rowData?: ICultureZoneCell;
}

export const ZoneListRadioButtonCell: FC<IProps> = ({ rowData }) => {
  const { setTableHasChanged } = useStore(ZonesStore);
  const { changeZoneType } = useStore(ZonesController);

  const { changeCultureZoneStyle } = useMap();

  const handleChange = useCallback(
    value => {
      setTableHasChanged(true);

      changeZoneType(
        { ...rowData.fwExpCultureZone, type: value, styleType: value },
        changeCultureZoneStyle
      );
    },
    [rowData.fwExpCultureZone]
  );

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}
      onClick={e => e.stopPropagation()}
    >
      <RadioButton
        name="type"
        color="blue"
        checked={rowData.type === EExperimentCultureZoneType.Control}
        onChange={() => handleChange(EExperimentCultureZoneType.Control)}
        disabled={!rowData.fwExpCultureZone.isSelected}
      >
        Контроль
      </RadioButton>
      <RadioButton
        name="type"
        color="red"
        checked={rowData.type === EExperimentCultureZoneType.Experiment}
        onChange={() => handleChange(EExperimentCultureZoneType.Experiment)}
        disabled={!rowData.fwExpCultureZone.isSelected}
      >
        Опыт
      </RadioButton>
    </div>
  );
};
