import styled from 'styled-components';

const Path = styled.path``;

const Wrapper = styled.div`
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  cursor: pointer;

  transition: 0.15s ease-in-out;

  &:hover {
    background-color: #ecf8ef;

    & ${Path} {
      fill: #3fb65f;
    }
  }
`;

const StyledContextualHelpToolTipIcon = {
  Path,
  Wrapper,
};

export default StyledContextualHelpToolTipIcon;
