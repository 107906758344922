import { FC, ReactNode, PropsWithChildren, memo } from 'react';

import Styled from './Container.styles';

interface IProps {
  className?: string;
  title?: string;
  /**
   * Иконка контекстной справки.
   */
  ContextualHelpIcon?: ReactNode;
  headerChildren?: ReactNode;
  isHideHeaderBorder?: boolean;
}

const Container: FC<PropsWithChildren<IProps>> = ({
  className,
  title,
  children,
  headerChildren,
  isHideHeaderBorder,
  ContextualHelpIcon,
}) => {
  return (
    <Styled.Wrapper className={className}>
      {headerChildren ? (
        <Styled.Header $isHideHeaderBorder={isHideHeaderBorder}>{headerChildren}</Styled.Header>
      ) : (
        <Styled.Header $isHideHeaderBorder={isHideHeaderBorder}>
          {title ? (
            <Styled.TitleWrapper>
              <Styled.Title>{title}</Styled.Title>

              {ContextualHelpIcon ? ContextualHelpIcon : null}
            </Styled.TitleWrapper>
          ) : null}
        </Styled.Header>
      )}

      <Styled.Body>{children}</Styled.Body>
    </Styled.Wrapper>
  );
};

Container.displayName = 'Container';

export default memo(Container);
