import { ContextualHelpStore } from '../../stores';
import { IReference } from '../../../../../../../api/models/as-fields/references/Reference.model';
import { lazyInject, provide } from '../../../../../utils/helpers/mobx';

@provide.singleton()
class ContextualHelpController {
  @lazyInject(ContextualHelpStore)
  protected store: ContextualHelpStore;

  initiateHelpListByParentPath = (parentPath: string, contextualHelpList: IReference[]): void => {
    this.store.setHelpListByParentPath(parentPath, contextualHelpList);
  };
}

export default ContextualHelpController;
