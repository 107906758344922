import { FC, InputHTMLAttributes } from 'react';

import Styled from './RadioButton.styles';

export const RadioButton: FC<
  InputHTMLAttributes<HTMLInputElement> & { children: string; color: 'blue' | 'red' }
> = ({ children, disabled, checked, color, ...props }) => {
  return (
    <Styled.Label $checked={checked} $color={color} $disabled={disabled}>
      <Styled.CheckMark $checked={checked} $color={color} $disabled={disabled} />
      <Styled.Text $checked={checked} $disabled={disabled}>
        {children}
      </Styled.Text>
      <Styled.Input type="radio" {...props} disabled={disabled} checked={checked} />
    </Styled.Label>
  );
};
