import { FC, memo } from 'react';

import { downloadIcon, trashIcon } from '../../assets';

import Styled from './AuditDocumentsActionButtons.styled';

interface IActionButtonsProps {
  id: string;
  url: string;
  onDeleteHandler: (id: string) => void;
}

const AuditDocumentsActionButtons: FC<IActionButtonsProps> = ({ id, url, onDeleteHandler }) => (
  <Styled.ButtonGroup data-test-id="audit-documents-action-buttons">
    <Styled.IconButton
      as="a"
      download
      href={url}
      data-test-id="audit-documents-action-download"
      $icon={downloadIcon}
    />
    <Styled.IconButton
      data-test-id="audit-documents-action-delete"
      $icon={trashIcon}
      onClick={() => onDeleteHandler(id)}
    />
  </Styled.ButtonGroup>
);

export default memo(AuditDocumentsActionButtons);
