import { FC, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { FlattenSimpleInterpolation } from 'styled-components';

import Styled from './MenuDropdown.styles';

export interface IMenuDropdown {
  config: {
    menu: {
      button: any;
      menuStyle?: FlattenSimpleInterpolation;
    };
    menuItems: {
      icon: string;
      label: string;
      onClick: () => void;
      iconStyle?: FlattenSimpleInterpolation;
      labelStyle?: FlattenSimpleInterpolation;
      itemStyle?: FlattenSimpleInterpolation;
    }[];
  };
}

const MenuDropdown: FC<IMenuDropdown> = ({ config }) => {
  const { menu, menuItems } = config;
  const [open, setOpen] = useState(false);

  return (
    <Styled.Wrapper>
      <Popover
        isOpen={open}
        containerStyle={{ zIndex: '10001' }}
        onClickOutside={() => {
          setOpen(false);
        }}
        content={
          <Styled.Menu $menuStyle={menu.menuStyle}>
            {menuItems.map(menuItem => (
              <Styled.MenuItem
                key={menuItem.label}
                onClick={e => {
                  e.stopPropagation();
                  menuItem.onClick();
                  setOpen(false);
                }}
                $itemStyle={menuItem.itemStyle}
              >
                <Styled.Icon $url={menuItem.icon} $iconStyle={menuItem.iconStyle} />
                <Styled.MenuItemLabel $labelStyle={menuItem.labelStyle}>
                  {menuItem.label}
                </Styled.MenuItemLabel>
              </Styled.MenuItem>
            ))}
          </Styled.Menu>
        }
      >
        <menu.button
          onClick={e => {
            e.stopPropagation();
            setOpen(!open);
          }}
        />
      </Popover>
    </Styled.Wrapper>
  );
};

MenuDropdown.displayName = 'MenuDropdown';

export default MenuDropdown;
