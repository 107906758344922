import { useNavigate } from 'react-router-dom';

// @ts-ignore
import noPermissionIcon from '../../../../../../../../../../../common/components/table/assets/icons/cancel.svg';

const useGetPlugInfo = () => {
  const navigate = useNavigate();

  const plugIcon = noPermissionIcon;

  const plugTitle = 'Вы не можете просматривать этот раздел';

  const buttonTitle = 'На главную';

  const plugDescription = 'Для смены настройки прав доступа, обратитесь к руководству';

  const onButtonClick = () => navigate('/');

  return { plugIcon, plugTitle, plugDescription, buttonTitle, onButtonClick };
};

export default useGetPlugInfo;
