import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { ButtonLink, TButtonLinkOnClick } from '@farmlink/farmik-ui';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { IExperimentFactTableAudit } from '../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';
import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';
import { generateAuditsPath } from '../../../../../../../observations/utils/helpers';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { CreateExperimentStore } from '../../../../mobx/stores';
import { checkEditableExperimentStatuses } from '../../helpers';
import { ExecutionTableSlider } from '../ExecutionTableSlider';
import { ExecutionController } from '../../mobx/controllers';
import { ExecutionStore } from '../../mobx/stores';
import { ExecutionTableDeleteIcon } from '../ExecutionTableDeleteIcon';
import { useWarningBeforeDeleting } from '../../../../../../../../../common/utils/hooks';

import Styled from './ExecutionTableRowsGroup.styles';
import { ReactComponent as ArrowTop } from './assets/icons/arrow-top-24x24.svg';
import { ReactComponent as ArrowDown } from './assets/icons/arrow-down-24x24.svg';

interface IProps {
  title: string;
  name: string;
  rowsChildren: ReactNode;
  isShowDeleteIcon: boolean;
  stepId: string;
  zoneId: string;
  dataTestId: string;
  audits?: IExperimentFactTableAudit[];
}

const ExecutionTableRowsGroup: FC<IProps> = observer(
  ({ rowsChildren, title, name, isShowDeleteIcon, stepId, zoneId, dataTestId, audits }) => {
    const executionController = useStore(ExecutionController);
    const executionStore = useStore(ExecutionStore);

    const [isOpen, setIsOpen] = useState(true);

    const createExperimentStore = useStore(CreateExperimentStore);

    const warningBeforeDeletingActions = useWarningBeforeDeleting();

    const navigate = useNavigate();

    const handleHeaderClick = useCallback(() => {
      setIsOpen(prevState => !prevState);
    }, []);

    const handleDeleteAuditClick = useCallback(event => {
      event.stopPropagation();

      warningBeforeDeletingActions.showWarningBeforeDeleting(
        `техоперацию: «${title.substring(3)}»`,
        () => {
          executionController.deleteExperimentStep(stepId);
        }
      );
    }, []);

    const handleAddAuditButtonClick = useCallback<TButtonLinkOnClick>(event => {
      event.stopPropagation();

      navigate(
        generateAuditsPath('createAudit', {
          auditId: 'create',
        })
      );
    }, []);

    const hasInvalidAttributeInGroup =
      executionController.getInvalidAttributesInGroup(stepId, zoneId)?.length > 0;

    const getDataTestId = useDataTestIdV2(dataTestId);

    useEffect(() => {
      if (executionStore.unhandledInvalidRowId && !isOpen && executionStore.scrollStep === 2) {
        setIsOpen(true);
      }
    }, [executionStore.unhandledInvalidRowId, executionStore.scrollStep, isOpen]);

    useEffect(() => {
      if (
        isOpen &&
        hasInvalidAttributeInGroup &&
        executionStore.unhandledInvalidRowId &&
        executionStore.scrollStep === 2
      ) {
        const rowElement = document.getElementById(executionStore.unhandledInvalidRowId);

        setTimeout(() => {
          rowElement?.scrollIntoView({ behavior: 'smooth' });
          executionStore.clearUnhandledInvalidRowId();
          executionStore.clearScrollStep();
        }, 50);
      }
    }, [
      isOpen,
      hasInvalidAttributeInGroup,
      executionStore.unhandledInvalidRowId,
      executionStore.scrollStep,
    ]);

    return (
      <Styled.Wrapper {...getDataTestId()}>
        <Styled.Header onClick={handleHeaderClick} {...getDataTestId('header')}>
          <Styled.TitleWrapper>
            <Styled.Title {...getDataTestId('title')}>{title}</Styled.Title>
            {hasInvalidAttributeInGroup ? (
              <Styled.ErrorText>Исправьте данные</Styled.ErrorText>
            ) : null}
            {isShowDeleteIcon ? (
              <ExecutionTableDeleteIcon
                onClick={handleDeleteAuditClick}
                dataTestId={'delete-audit-button'}
              />
            ) : null}
          </Styled.TitleWrapper>

          <Styled.ButtonsWrapper {...getDataTestId('buttons-wrapper')}>
            {checkEditableExperimentStatuses(createExperimentStore?.selectedExp?.status) ? (
              <ButtonLink
                color={'accent'}
                onClick={handleAddAuditButtonClick}
                dataTestId={getDataTestId('add-audit-button')['data-test-id']}
              >
                + Добавить наблюдение
              </ButtonLink>
            ) : null}

            <Styled.ArrowWrapper {...getDataTestId('arrow-wrapper')}>
              {isOpen ? (
                <ArrowTop {...getDataTestId('arrow-top')} />
              ) : (
                <ArrowDown {...getDataTestId('arrow-down')} />
              )}
            </Styled.ArrowWrapper>
          </Styled.ButtonsWrapper>
        </Styled.Header>

        {isOpen ? (
          <Styled.Content {...getDataTestId('content')}>
            <ExecutionTableSlider name={name} audits={audits} dataTestId={dataTestId} />

            {rowsChildren}
          </Styled.Content>
        ) : null}
      </Styled.Wrapper>
    );
  }
);

ExecutionTableRowsGroup.displayName = 'ExecutionTableRowsGroup';

export default ExecutionTableRowsGroup;
