import { FC, UIEvent, useCallback, useState } from 'react';
import { Button, EModalType, NewScrollbar, TModalConfig, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { IReference } from '../../../../../../api/models/as-fields/references/Reference.model';

import Styled from './ContextualHelpModal.styles';

export interface IContextualHelpModalPayload {
  contextualHelp?: IReference;
}

const ContextualHelpModal: FC = () => {
  const [isContentHiddenByScroll, setIsContentHiddenByScroll] = useState<boolean>(false);

  const modalActions = useModal();
  const { contextualHelp } = (modalActions.getModalPayload() ?? {}) as IContextualHelpModalPayload;

  const handleContentScroll = useCallback((event: UIEvent<HTMLDivElement>): void => {
    setIsContentHiddenByScroll(Boolean(event.currentTarget.scrollTop));
  }, []);

  return (
    <Styled.Wrapper>
      {contextualHelp?.title ? (
        <Styled.Header $isContentHiddenByScroll={isContentHiddenByScroll}>
          <Styled.Title>{contextualHelp.title}</Styled.Title>
        </Styled.Header>
      ) : null}

      {contextualHelp?.text ? (
        <NewScrollbar onScroll={handleContentScroll}>
          <Styled.Content dangerouslySetInnerHTML={{ __html: contextualHelp.text }} />
        </NewScrollbar>
      ) : null}

      <Styled.Footer>
        <Button color={'primary'} onClick={() => modalActions.closeModal()} alignment={'center'}>
          Понятно
        </Button>
      </Styled.Footer>
    </Styled.Wrapper>
  );
};

ContextualHelpModal.displayName = 'ContextualHelpModal';

const ContextualHelpModalConfig: TModalConfig = {
  name: 'contextualHelpModal',
  title: '',
  type: EModalType.Custom,
  id: 'contextualHelpModal',
  children: observer(ContextualHelpModal),
  styledProps: {
    $modalPaddings: '40px',
    $size: '440px',
    $height: 'calc(100vh - 32px)',
  },
  closeOnClickOutside: true,
};

export default ContextualHelpModalConfig;
