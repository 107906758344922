import { FC, useCallback, useRef } from 'react';
import { observer } from 'mobx-react';
import {
  ButtonLink,
  ENotificationType,
  ENotificatorTheme,
  useNotificator,
} from '@farmlink/farmik-ui';

import { useFileUpload } from '../../../../../../../common/hooks/useFileUpload';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { ContractSidePanelStore } from '../../mobx/stores';

import { DocumentsTableController } from './components/DocumentsTable/mobx/controllers';
import { DocumentsTable } from './components';
import Styled from './DocumentsTab.styles';
import { DocumentsTableStore } from './components/DocumentsTable/mobx/stores';

interface IDocumentsTabProps {
  isAllowToEditContracts: boolean;
}

export const DocumentsTab: FC<IDocumentsTabProps> = observer(({ isAllowToEditContracts }) => {
  const { updateAttachedFiles } = useStore(DocumentsTableController);

  const { selectedContract } = useStore(ContractSidePanelStore);

  const { attachedFiles } = useStore(DocumentsTableStore);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const { handleFileChange } = useFileUpload(inputRef, { maxSize: 1e8 });

  const { setNotification } = useNotificator();

  const addDocToContract = useCallback(
    doc => {
      const { id } = doc;
      const updatedAttachedFiles = attachedFiles?.map(file => file.id) || [];
      const payload = {
        id: selectedContract?.id,
        attachedFiles: [...updatedAttachedFiles, id],
      };

      updateAttachedFiles(payload);
    },
    [selectedContract?.id, attachedFiles]
  );

  const uploadNewDocument = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleFileChange(e)
        .then(doc => {
          addDocToContract(doc);
          setNotification({
            message: 'Документ успешно загружен',
            style: {
              placement: 'top-center',
              type: ENotificationType.Success,
              theme: ENotificatorTheme.Dark,
            },
          });
        })
        .catch((err: Error) => {
          setNotification({
            message: err.message,
            style: {
              placement: 'top-center',
              type: ENotificationType.Warning,
              theme: ENotificatorTheme.Dark,
            },
          });
        });
    },
    [addDocToContract]
  );

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  return (
    <>
      <Styled.Wrapper>
        <DocumentsTable />
      </Styled.Wrapper>
      {isAllowToEditContracts && (
        <>
          <ButtonLink color={'accent'} onClick={handleUploadClick}>
            Загрузить документ
          </ButtonLink>
          <Styled.FileInput type="file" ref={inputRef} onChange={uploadNewDocument} />
        </>
      )}
    </>
  );
});
