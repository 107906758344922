import { makeAutoObservable } from 'mobx';
import { MutableRefObject } from 'react';

import { provide } from '../../../../../../../../common/utils/helpers/mobx';
import {
  IExperiment,
  IExperimentCultureZone,
} from '../../../../../../../../../api/models/as-fields/experiments';
import { ICultureZone } from '../../../../../../../../../api/models/as-fields/fields/CultureZone';

@provide.singleton()
class CreateExperimentStore {
  private _selectedExp: IExperiment | null = null;
  private _mapIdToCultureZone: Map<string, ICultureZone> = new Map();
  private _mapIdToExperimentCultureZone: Map<string, IExperimentCultureZone> = new Map();
  private _isAllowMapAccess = false;
  private _tableHeaderInfo: {
    ref?: MutableRefObject<HTMLDivElement>;
    isStuck?: boolean;
    stuckPosition?: number;
  } = {};

  constructor() {
    makeAutoObservable(this);
  }

  get selectedExp() {
    return this._selectedExp;
  }

  get cultureZones() {
    return [...this._mapIdToCultureZone.values()];
  }

  get hasCultureZones() {
    return this._mapIdToCultureZone.size > 0;
  }

  get experimentCultureZones() {
    return [...this._mapIdToExperimentCultureZone.values()];
  }

  get hasExperimentCultureZones() {
    return this._mapIdToExperimentCultureZone.size > 0;
  }

  get isAllowMapAccess() {
    return this._isAllowMapAccess;
  }

  get tableHeaderInfo() {
    return this._tableHeaderInfo;
  }

  setSelectedExp = (exp: IExperiment) => {
    this._selectedExp = exp;
  };

  setCultureZones = (cultureZones: ICultureZone[]) => {
    const map: Map<string, ICultureZone> = new Map();

    for (const cultureZone of cultureZones) {
      map.set(cultureZone.id, cultureZone);
    }

    this._mapIdToCultureZone = map;
  };

  setExperimentCultureZones = (experimentCultureZones: IExperimentCultureZone[]) => {
    const map: Map<string, IExperimentCultureZone> = new Map();

    for (const cultureZone of experimentCultureZones) {
      map.set(cultureZone.id, cultureZone);
    }

    this._mapIdToExperimentCultureZone = map;
  };

  setIsAllowMapAccess = (value: boolean) => {
    this._isAllowMapAccess = value;
  };

  setTableHeaderInfo = (tableHeaderInfo: {
    ref?: MutableRefObject<HTMLDivElement>;
    isStuck?: boolean;
    stuckPosition?: number;
  }) => {
    this._tableHeaderInfo = { ...this._tableHeaderInfo, ...tableHeaderInfo };
  };

  clearSelectedExp = () => {
    this._selectedExp = null;
  };

  clearCultureZones = () => {
    this._mapIdToCultureZone = new Map();
  };

  clearExperimentCultureZones = () => {
    this._mapIdToExperimentCultureZone = new Map();
  };

  clearIsAllowMapAccess = () => {
    this._isAllowMapAccess = false;
  };

  clearTableHeaderInfo = () => {
    this._tableHeaderInfo = {};
  };

  clearStore = (): void => {
    this.clearSelectedExp();
    this.clearCultureZones();
    this.clearExperimentCultureZones();
    this.clearIsAllowMapAccess();
    this.clearTableHeaderInfo();
  };
}

export default CreateExperimentStore;
