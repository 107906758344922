import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';
import { ExperimentsListConfigsService as ConfigsService } from '../../services';
import { TableBuilderController } from '../../../../../../../../common/features/TableBuilder/mobx/controllers';
import { IExperiment } from '../../../../../../../../../api/models/as-fields/experiments';
import {
  EExperimentsFiltersBuilderId,
  EExperimentsTableBuilderId,
} from '../../../../../constants/configs';
import { TableFiltersBuilderController } from '../../../../../../../../common/features/TableFiltersBuilder/mobx/controllers';
import { IExperimentsFilters } from '../../../../ExperimentsFilters/models';
import { TableBuilderStore } from '../../../../../../../../common/features/TableBuilder/mobx/stores';
import { ExperimentService } from '../../../../../../../../common/mobx/services/as-fields';

@provide.transient()
class ExperimentsListController {
  @lazyInject(ConfigsService)
  protected configService: ConfigsService;

  @lazyInject(ExperimentService)
  protected experimentsService: ExperimentService;

  @lazyInject(TableBuilderStore)
  protected tableBuilderStore: TableBuilderStore;

  @lazyInject(TableFiltersBuilderController)
  protected tableFiltersBuilderController: TableFiltersBuilderController<IExperimentsFilters>;

  @lazyInject(TableBuilderController)
  protected tableBuilderController: TableBuilderController;

  initiateTable = (): void => {
    const config = this.configService.createConfig();

    this.tableBuilderController.initiateTable(config);

    this.tableBuilderController.addPaginationScrollEvent(
      EExperimentsTableBuilderId.Experiments,
      () => {
        return this.fetchExperimentList(true);
      }
    );
  };

  /**
   * Метод получения списка опытов согласно фильтрам.
   * @param payload - выбранные фильтры.
   * @param isSavePagination - флаг, который говорит о том, сохраняем мы пагинацию или нет.
   * Когда активируется запрос после обновленных фильтров, то обнуляем пагинацию.
   */
  fetchExperimentList = async (isSavePagination?: boolean): Promise<void> => {
    const appliedFilters = this.tableFiltersBuilderController.getAppliedFilters(
      EExperimentsFiltersBuilderId.Experiments
    );

    if (!isSavePagination) {
      this.tableBuilderController.addCurrentPage(EExperimentsTableBuilderId.Experiments, 0);
      this.tableBuilderController.addTotalPages(EExperimentsTableBuilderId.Experiments, 0);
    }

    const currentPage = this.tableBuilderStore.getCurrentPage(
      EExperimentsTableBuilderId.Experiments
    );

    await this.experimentsService.getExperimentList(appliedFilters, {
      query: {
        page: isSavePagination ? currentPage : 0,
        size: 25,
      },
      actions: {
        showLoader: () => {
          this.tableBuilderController.showLoader(EExperimentsTableBuilderId.Experiments);
        },
        hideLoader: () => {
          this.tableBuilderController.hideLoader(EExperimentsTableBuilderId.Experiments);
          this.tableBuilderController.hideDefaultPlug(EExperimentsTableBuilderId.Experiments);
        },
        success: response => {
          this.tableBuilderController.addTotalPages(
            EExperimentsTableBuilderId.Experiments,
            response.totalPages
          );

          this.tableBuilderController.addRowsToRowsGroupListById(
            EExperimentsTableBuilderId.Experiments,
            EExperimentsTableBuilderId.Experiments,
            response.content,
            {
              isClearPreviousList: !isSavePagination,
            }
          );
        },
      },
    });
  };
}

export default ExperimentsListController;
