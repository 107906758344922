import { makeAutoObservable } from 'mobx';

import { provide, lazyInject } from '../../../../../../../../../../common/utils/helpers/mobx';
import { IAttachedFile } from '../../../../../../../../../../../api/models/as-fields/experiments';
import { ExperimentSubsectionsStore } from '../../../../../mobx/stores';

@provide.singleton()
export class DocumentsStore {
  @lazyInject(ExperimentSubsectionsStore)
  protected experimentsSubsectionsStore: ExperimentSubsectionsStore;

  constructor() {
    makeAutoObservable(this);
    this._attachedFiles = this.experimentsSubsectionsStore.experiment?.attachedFiles || [];
  }

  private _attachedFiles: IAttachedFile[];

  get attachedFiles(): IAttachedFile[] {
    return this._attachedFiles;
  }

  setAttachedFiles = (attachedFiles: IAttachedFile[]) => {
    this._attachedFiles = attachedFiles;
  };

  clearAttachedFiles = () => {
    this._attachedFiles = [];
  };

  clearStore = () => {
    this.clearAttachedFiles();
  };
}
