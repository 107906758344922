import { format } from 'date-fns';

import { IExperimentStep } from '../../../../../api/models/as-fields/experiments';
import { INutritionHistory } from '../../../../../api/models/as-fields/plan/NutrationHistory';
import { ITechOperationData } from '../model/ITechOperationData.model';

const createTechOperationsTableBuilderData = (
  experimentSteps: IExperimentStep[],
  nutritionHistories: INutritionHistory[]
): ITechOperationData[] => {
  const experimentStepWithInventoryValues = experimentSteps.map(experimentStep => ({
    ...experimentStep,
    inventoryValues: nutritionHistories.reduce((acc, nutritionHistory) => {
      if (nutritionHistory.experimentStep.id === experimentStep.id) {
        acc.push(...nutritionHistory.items);
      }

      return acc;
    }, []),
    planStartDate: experimentStep.planStartDate
      ? format(new Date(experimentStep.planStartDate), 'dd.MM.yyyy')
      : nutritionHistories.find(history => history.experimentStep.id === experimentStep.id)?.date
      ? format(
          new Date(
            nutritionHistories.find(
              history => history.experimentStep.id === experimentStep.id
            )?.date
          ),
          'dd.MM.yyyy'
        )
      : '—',
    phenophase: experimentStep?.phenophase
      ? experimentStep?.phenophase
      : {
          name: '—',
          description: '—',
          code: '—',
        },
  }));

  const sortedExperimentStep = experimentStepWithInventoryValues.sort(
    (prev, next) => prev.order - next.order
  );

  const rows = sortedExperimentStep.map((experimentStep, index) => ({
    id: experimentStep.id,
    number: index + 1,
    name: experimentStep.name,
    date: experimentStep.planStartDate,
    phenophase: experimentStep.phenophase?.name,
    bbch: experimentStep?.phenophase?.code,
    method: experimentStep.techOperationType.name,
    inventoryValue: '—',
    dosage: '—',
    children: experimentStep.inventoryValues,
    actions: null,
    usageMethodTypeName: experimentStep?.usageMethodType?.name,
  }));

  return rows;
};

export { createTechOperationsTableBuilderData };
