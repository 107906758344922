import { FC, memo, PropsWithChildren } from 'react';

import Styled from './SidePanel.styles';

interface IProps {
  onClose: () => void;
}

export type TSidePanelProps = PropsWithChildren<IProps>;

const SidePanel: FC<TSidePanelProps> = ({ onClose, children }) => {
  return (
    <Styled.Overlay onClick={onClose}>
      <Styled.Wrapper>{children}</Styled.Wrapper>
    </Styled.Overlay>
  );
};

SidePanel.displayName = 'SidePanel';

export default memo(SidePanel);
