import { lazyInject, provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { AxiosService } from '../../../../../../../../../../../../common/mobx/services/axios/AxiosService';
import { CreateObservationStore } from '../../stores';
import { IOrganization } from '../../../../../../../../../../../../../api/models/da-profile/organizations/Organization.model';
import {
  IExperiment,
  IExperimentStep,
} from '../../../../../../../../../../../../../api/models/as-fields/experiments';
import { IEmployee } from '../../../../../../../../../../../../../api/models/da-profile/users';
import { EExperimentStepType } from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentStep/ExperimentStep.model';

@provide.singleton()
class CreateObservationService {
  @lazyInject(AxiosService)
  axiosService: AxiosService;

  @lazyInject(CreateObservationStore)
  createObservationStore: CreateObservationStore;

  getAllUsers = async (
    querySearch: string,
    organizationId?: string,
    page?: number
  ): Promise<{ content: IEmployee[]; totalPages: number }> => {
    try {
      const { content, totalPages } = await this.axiosService.api.getOrganizationUserList({
        fullName: querySearch,
        status: 'ACTIVE',
        size: 20,
        organizationID: organizationId,
        page,
      });

      return { content, totalPages };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  getOrganizations = async (
    querySearch: string,
    page?: number
  ): Promise<{ content: IOrganization[]; totalPages: number }> => {
    try {
      const { content, totalPages } = await this.axiosService.api.getExperimentOrganizationList({
        nameOrInn: querySearch,
        size: 20,
        page,
      });

      return { content, totalPages };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  getExpiriments = async (
    querySearch: string,
    page?: number
  ): Promise<{ content: IExperiment[]; totalPages: number }> => {
    try {
      const { organizationId } = this.createObservationStore;

      const { content, totalPages } = await this.axiosService.api.getExperimentsList(
        {
          page,
          size: 20,
          organizationId,
        },
        {
          query: {
            name: querySearch,
            sort: 'name,asc',
          },
        }
      );

      return { content, totalPages };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  getExperimentStepFullList = async (
    experimentId: string,
    querySearch: string,
    page: number
  ): Promise<{ content: IExperimentStep[]; totalPages: number }> => {
    try {
      const { content, totalPages } = await this.axiosService.api.getExperimentStepFullList(
        {
          experimentId,
          type: EExperimentStepType.Plan,
        },
        {
          query: {
            name: querySearch,
            page,
            size: 20,
          },
        }
      );

      return { content, totalPages };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  saveExperimentStep = async data => {
    try {
      const experimentId = await this.axiosService.api.createExperimentStep({
        ...data,
        type: EExperimentStepType.Audit,
      });

      return experimentId;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  updateExperimentStep = async data => {
    try {
      const experimentId = await this.axiosService.api.updateExperimentStep({
        ...data,
        type: EExperimentStepType.Audit,
      });

      return experimentId;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
}

export default CreateObservationService;
