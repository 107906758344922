import moment from 'moment/moment';
import { v4 as uuid } from 'uuid';

import {
  AuditReportInstWithCustomProp,
  ICompleteAggregateAttrWithVal,
  ICompleteAggregateInstance,
} from '../../../models';
import { IAttributeValue } from '../../../../../../../../../../common/features/DynamicTable/models';
import { EChecklistAttributeType } from '../../../../../../../../../../../api/models/as-fields/checklists/Attribute/ChecklistAttribute.model';
import {
  AuditReportDateAttrVal as DateAttrVal,
  AuditReportViewTaskAttrVal as ViewTaskAttrVal,
} from '../../../components';
import { formatNumValue } from '../../../../../../../../../../common/utils/helpers/numbers';
import { IMonitoringReportTableAttributeValue } from '../../../../../../../../../../../api/models/as-fields/experiments/MonitoringReportData/MonitoringReportData';

const formatMinAndMaxVal = (min: number, max: number): string => {
  if (min !== undefined && max !== undefined) {
    return `(${formatNumValue(min)}–${formatNumValue(max)})`;
  }
};

const formatEnumAndUserDictVal = (
  listVal:
    | IMonitoringReportTableAttributeValue['userDictionaryValue']
    | IMonitoringReportTableAttributeValue['enumValue']
): string => {
  return listVal
    ? listVal
        .reduce<string[]>((acc, el) => {
          acc.push(el);

          return acc;
        }, [])
        .join(', ')
    : '—';
};

const formatDictVal = (
  dictValList: IMonitoringReportTableAttributeValue['dictionaryValueList']
): string => {
  return dictValList
    ? dictValList
        .reduce<string[]>((acc, el) => {
          acc.push(el.name);

          return acc;
        }, [])
        .join(', ')
    : '—';
};

const getIntegerVal = ({
  intValue,
  intMinValue,
  intMaxValue,
}: IMonitoringReportTableAttributeValue): string | number => {
  const minAndMaxVal = formatMinAndMaxVal(intMinValue, intMaxValue);

  if (minAndMaxVal) {
    return `${intValue} ${minAndMaxVal}`;
  } else if (intValue) {
    return intValue;
  }

  return '—';
};

const getDoubleVal = ({
  doubleValue,
  doubleMinValue,
  doubleMaxValue,
}: IMonitoringReportTableAttributeValue): string | number => {
  if (doubleMinValue && doubleMaxValue) {
    const minAndMaxVal = formatMinAndMaxVal(
      formatNumValue(doubleMinValue),
      formatNumValue(doubleMaxValue)
    );

    return `${formatNumValue(doubleValue)} ${minAndMaxVal}`;
  } else if (doubleValue) {
    return formatNumValue(doubleValue);
  }

  return '—';
};

const getBoolVal = ({ booleanValue }: IMonitoringReportTableAttributeValue): string => {
  if (booleanValue) {
    return 'да';
  } else {
    return 'нет';
  }
};

const getDateVal = ({ dateValue }: IMonitoringReportTableAttributeValue): string => {
  return moment(dateValue).format('DD.MM.YYYY');
};

const getStringVal = ({ stringValue }: IMonitoringReportTableAttributeValue): string => {
  return stringValue ?? '—';
};

const getLongStringVal = ({ longStringValue }: IMonitoringReportTableAttributeValue): string => {
  return longStringValue ?? '—';
};

const getEnumVal = ({ enumValue }: IMonitoringReportTableAttributeValue): string => {
  return formatEnumAndUserDictVal(enumValue);
};

const getUserDictVal = ({ userDictionaryValue }: IMonitoringReportTableAttributeValue): string => {
  return formatEnumAndUserDictVal(userDictionaryValue);
};

const getDictVal = ({ dictionaryValueList }: IMonitoringReportTableAttributeValue): string => {
  return formatDictVal(dictionaryValueList);
};

const getFileVal = ({ fileValue }: IMonitoringReportTableAttributeValue): string => {
  return fileValue ? `Фотографий: ${fileValue.length}` : '—';
};

const getValueByType = (
  attrVal: IMonitoringReportTableAttributeValue,
  type: EChecklistAttributeType
): string | number => {
  switch (type) {
    case EChecklistAttributeType.Int:
      return getIntegerVal(attrVal);
    case EChecklistAttributeType.Double:
      return getDoubleVal(attrVal);
    case EChecklistAttributeType.Boolean:
      return getBoolVal(attrVal);
    case EChecklistAttributeType.Date:
      return getDateVal(attrVal);
    case EChecklistAttributeType.String:
      return getStringVal(attrVal);
    case EChecklistAttributeType.LongString:
      return getLongStringVal(attrVal);
    case EChecklistAttributeType.Enum:
      return getEnumVal(attrVal);
    case EChecklistAttributeType.UserDictionaryLink:
      return getUserDictVal(attrVal);
    case EChecklistAttributeType.DictionaryLink:
      return getDictVal(attrVal);
    case EChecklistAttributeType.FileLink:
      return getFileVal(attrVal);
    default:
      return '—';
  }
};

const createAttrVal = (
  expZoneId: string,
  { dynamicTableAttr, checklistAttrVal }: ICompleteAggregateAttrWithVal,
  index: number
): IAttributeValue => {
  const { stageId, type, id } = dynamicTableAttr;

  return {
    instanceId: expZoneId,
    stageId,
    id: uuid(),
    attributeId: id,
    value: getValueByType(checklistAttrVal[index], type),
  };
};

const createViewTaskAttrVal = (expZoneId: string): IAttributeValue => {
  return {
    instanceId: expZoneId,
    stageId: 'info-stage',
    id: uuid(),
    attributeId: 'view-task-attr',
    value: 'Посмотреть задачу',
    Component: ViewTaskAttrVal,
  };
};

const createDateAttrVal = (expZoneId: string, startedDate: string): IAttributeValue => {
  return {
    instanceId: expZoneId,
    stageId: 'info-stage',
    id: uuid(),
    attributeId: 'date-attr',
    value: startedDate,
    Component: DateAttrVal,
  };
};

const createAttrValList = (
  expZoneId: string,
  attrWithVal: ICompleteAggregateAttrWithVal[] = [],
  index: number
): IAttributeValue[] => {
  return attrWithVal.map(attrVal => createAttrVal(expZoneId, attrVal, index));
};

const createInstance = (
  { expZone, attrWithValList, task, customProps }: ICompleteAggregateInstance,
  index: number
): AuditReportInstWithCustomProp => {
  const formatedResearchDate = moment(customProps.researchDate).format('DD.MM.YYYY');
  const isTaskDataExist = task.hasCompletedInstances && task.hasPoints;

  return {
    id: expZone.id,
    tableId: 'auditsAuditReport',
    name: expZone.cultureZone.name || 'Безымянный',
    attributeValues: [
      ...createAttrValList(expZone.id, attrWithValList, index),
      createDateAttrVal(expZone.id, formatedResearchDate),
      createViewTaskAttrVal(expZone.id),
    ],
    customProp: {
      taskId: isTaskDataExist ? task.id : null,
    },
  };
};

const createInstanceList = (
  completeInstList: ICompleteAggregateInstance[]
): AuditReportInstWithCustomProp[] => {
  return completeInstList.map(createInstance);
};

const DynamicTableInstanceHelpers = { createInstanceList };

export default DynamicTableInstanceHelpers;
