import { FC, memo, MouseEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Container } from '../../../../../../../common/features/UI';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { AuditStore } from '../../mobx/store';
import { IDynamicTableConfig } from '../../../../../../../common/features/DynamicTable/models';
import { DynamicTable } from '../../../../../../../common/features/DynamicTable';
import { useForm } from '../../../../../../../common/features/form/utils/hooks';
import { usePageContextualHelp } from '../../../../../../../common/hooks/usePageContextualHelp';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../common/constants/contextualPath';
import { useContextualHelpActions } from '../../../../../../../common/features/ContextualHelp/hooks';

import Styled from './AuditReport.styles';
import AuditReportController from './mobx/controller/AuditReport.controller';
import { AuditReportCommentSection, AuditReportStepFooterContainer } from './containers';
import {
  AUDIT_REPORT_FORM_KEY,
  createAuditReportFormConfig,
  IAuditReportForm,
} from './containers/AuditReportCommentSection/forms/config/auditReportFormConfig';

const AuditReport: FC = () => {
  const { selectedAudit, selectedExp } = useStore(AuditStore);
  const { getDynamicTableConfig, updateExperimentStep, fetchExperimentStepData } =
    useStore(AuditReportController);

  const [dynamicTableConfig, setDynamicTableConfig] = useState<IDynamicTableConfig | null>(null);

  usePageContextualHelp(EContextualParentPath.AuditsAuditReportAudit);
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.AuditsAuditReportAudit,
    ContextualPaths.AuditsAuditReportAuditComparisonTableTitle
  );

  useEffect(() => {
    (async () => {
      if (!selectedExp || !selectedAudit) {
        return;
      }

      const config = await getDynamicTableConfig();

      setDynamicTableConfig(config);
    })();
  }, [selectedAudit, selectedExp]);

  const { elements, registerForm, changeListOfFormValue, submitForm } =
    useForm<IAuditReportForm>(AUDIT_REPORT_FORM_KEY);

  useEffect(() => {
    registerForm(createAuditReportFormConfig());
  }, []);

  const handleSubmit = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();

    await submitForm(async validatedData => {
      await updateExperimentStep(selectedAudit.id, validatedData);
    });
  };

  useEffect(() => {
    if (selectedAudit?.id) {
      (async () => {
        const stepData = await fetchExperimentStepData(selectedAudit.id);

        changeListOfFormValue({
          specialNotes: stepData.specialNotes || '',
          technologicalDeviation: stepData.technologicalDeviation || '',
          generalComment: stepData.generalComment || '',
        });
      })();
    }
  }, [selectedAudit?.id]);

  return (
    <Styled.FormWrapper>
      <Container title={'Сравнение по участкам'} ContextualHelpIcon={ContextualHelpIcon}>
        {dynamicTableConfig ? <DynamicTable config={dynamicTableConfig} /> : null}
      </Container>

      <Container title={'Комментарии'}>
        <AuditReportCommentSection elements={elements} />
      </Container>

      <AuditReportStepFooterContainer onSubmit={handleSubmit} />
    </Styled.FormWrapper>
  );
};

AuditReport.displayName = 'AuditReport';

export default memo(observer(AuditReport));
