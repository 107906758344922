import { IFormConfig } from '../../../../../../../../../../../common/features/form/models';

const EXPERIMENTS_CREATE_AXO_FORM_KEY = 'createAxoFormKey';

const REQUIRED_TEXT = 'Это поле не может быть пустым';

interface ICreateAgrochemicalForm {
  date: string;
  type: string;
  mechanicalComposition: string;
  mechanicalCompositionMethod: string;
  humus: string;
  humusMethod: string;
  ph: string;
  phMethod: string;
  po: string;
  poMethod: string;
  ko: string;
  koMethod: string;
}

const createAgrochemicalFormConfig: IFormConfig<ICreateAgrochemicalForm> = {
  formKey: EXPERIMENTS_CREATE_AXO_FORM_KEY,
  form: {
    date: null,
    type: '',
    mechanicalComposition: '',
    humus: '',
    ph: '',
    po: '',
    ko: '',
    mechanicalCompositionMethod: '',
    koMethod: '',
    phMethod: '',
    poMethod: '',
    humusMethod: '',
  },
  elements: {
    date: {
      name: 'date',
      type: 'date',
      order: 1,
      isRequired: true,
      label: 'Дата проведения',
      placeholder: 'Не выбрана',
      datePickerOptions: {
        placement: 'bottom-start',
      },
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
    },
    type: {
      name: 'type',
      type: 'select',
      order: 1,
      isRequired: true,
      label: 'Тип почвы',
      placeholder: 'Не выбрано',
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
      selectOptions: {
        defaultValue: {
          label: 'Не выбрано',
          value: '',
        },
        valueAfterCleaning: {
          label: 'Не выбрано',
          value: '',
        },
        optionList: [],
      },
    },
    mechanicalComposition: {
      name: 'mechanicalComposition',
      type: 'select',
      order: 1,
      isRequired: true,
      label: 'Мех. состав',
      placeholder: 'Не указан',
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
      selectOptions: {
        defaultValue: {
          label: 'Не указан',
          value: '',
        },
        valueAfterCleaning: {
          label: 'Не указан',
          value: '',
        },
        optionList: [],
      },
    },
    mechanicalCompositionMethod: {
      name: 'mechanicalCompositionMethod',
      type: 'select',
      order: 1,
      isRequired: true,
      label: 'Метод',
      placeholder: 'Не выбран',
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
      selectOptions: {
        defaultValue: {
          label: 'Не выбран',
          value: '',
        },
        valueAfterCleaning: {
          label: 'Не выбран',
          value: '',
        },
        optionList: [],
      },
    },
    humusMethod: {
      name: 'humusMethod',
      dependencyName: 'humus',
      type: 'select',
      order: 1,
      label: 'Метод',
      placeholder: 'Не выбран',
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
      selectOptions: {
        defaultValue: {
          label: 'Не выбран',
          value: '',
        },
        valueAfterCleaning: {
          label: 'Не выбран',
          value: '',
        },
        optionList: [],
      },
    },
    phMethod: {
      name: 'phMethod',
      type: 'select',
      order: 1,
      label: 'Метод',
      dependencyName: 'ph',
      placeholder: 'Не выбран',
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
      selectOptions: {
        defaultValue: {
          label: 'Не выбран',
          value: '',
        },
        valueAfterCleaning: {
          label: 'Не выбран',
          value: '',
        },
        optionList: [],
      },
    },
    poMethod: {
      name: 'poMethod',
      type: 'select',
      order: 1,
      label: 'Метод',
      dependencyName: 'po',
      placeholder: 'Не выбран',
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
      selectOptions: {
        defaultValue: {
          label: 'Не выбран',
          value: '',
        },
        valueAfterCleaning: {
          label: 'Не выбран',
          value: '',
        },
        optionList: [],
      },
    },
    koMethod: {
      name: 'koMethod',
      type: 'select',
      order: 1,
      label: 'Метод',
      dependencyName: 'ko',
      placeholder: 'Не выбран',
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
      selectOptions: {
        defaultValue: {
          label: 'Не выбран',
          value: '',
        },
        valueAfterCleaning: {
          label: 'Не выбран',
          value: '',
        },
        optionList: [],
      },
    },
    humus: {
      name: 'humus',
      type: 'input',
      order: 1,
      label: 'Гумус, %',
      placeholder: 'Не указан',
      nativeType: 'number',
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
      validation: {
        onChange: {
          regExp: {
            expression: new RegExp(/^\d+(,|\.)?(\d{1,2})?$/),
            errorTitle: 'Ошибка валидации',
          },
        },
      },
    },
    ph: {
      name: 'ph',
      type: 'input',
      order: 1,
      label: 'pH',
      placeholder: 'Не указан',
      nativeType: 'number',
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
      validation: {
        onChange: {
          regExp: {
            expression: new RegExp(/^\d+(,|\.)?(\d{1,2})?$/),
            errorTitle: 'Ошибка валидации',
          },
        },
      },
    },
    po: {
      name: 'po',
      type: 'input',
      order: 1,
      label: 'P₂O₅',
      placeholder: 'Не указан',
      nativeType: 'number',
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
      validation: {
        onChange: {
          regExp: {
            expression: new RegExp(/^\d+(,|\.)?(\d{1,2})?$/),
            errorTitle: 'Ошибка валидации',
          },
        },
      },
    },
    ko: {
      name: 'ko',
      type: 'input',
      order: 1,
      label: 'K₂O',
      placeholder: 'Не указан',
      nativeType: 'number',
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
      validation: {
        onChange: {
          regExp: {
            expression: new RegExp(/^\d+(,|\.)?(\d{1,2})?$/),
            errorTitle: 'Ошибка валидации',
          },
        },
      },
    },
  },
};

export type { ICreateAgrochemicalForm };

export { createAgrochemicalFormConfig };
