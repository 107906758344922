import { FC, memo } from 'react';

import { IAttachedFile } from '../../../../../../../../../../api/models/as-fields/experiments';
import downloadIcon from '../../assets/icons/download.svg';
import deleteIcon from '../../assets/icons/delete.svg';

import Styled from './DocumentsActions.styled';

interface IActionCellComponent {
  row: IAttachedFile;
  onClickDownloadFile: (file: IAttachedFile) => void;
  onClickDeleteFile: (fileId: string) => void;
}

const DocumentsActions: FC<IActionCellComponent> = ({
  row,
  onClickDownloadFile,
  onClickDeleteFile,
}) => {
  const handleDownloadClick = () => {
    onClickDownloadFile(row);
  };

  const handleDeleteClick = () => {
    onClickDeleteFile(row.id);
  };

  return (
    <Styled.Wrapper>
      <Styled.ActionButton onClick={handleDownloadClick}>
        <Styled.Icon src={downloadIcon} />
      </Styled.ActionButton>
      <Styled.ActionButton onClick={handleDeleteClick}>
        <Styled.Icon src={deleteIcon} />
      </Styled.ActionButton>
    </Styled.Wrapper>
  );
};

DocumentsActions.displayName = 'DocumentsActions';

export default memo(DocumentsActions);
