import { FC, memo } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { ICultureZoneCell } from '../../utils/hooks/zonesListTableHooks/zonesListTable.hooks';
import { useShowTooltip } from '../../../../../../../../../../../../../common/utils/hooks';

import Styled from './ZoneListCellWithDisabled.styles';

interface IZoneListCellWithDisabledProps {
  rowData: ICultureZoneCell;
  text: string;
}

const ZoneListCellWithDisabled: FC<IZoneListCellWithDisabledProps> = ({
  text,
  rowData: { fwExpCultureZone },
}) => {
  const { ref, showTooltip } = useShowTooltip<HTMLDivElement>();

  return (
    <AutoTooltip position="top" content={text} disabled={!showTooltip}>
      <Styled.Wrapper ref={ref} $isDisabled={!fwExpCultureZone.isSelected}>
        {text}
      </Styled.Wrapper>
    </AutoTooltip>
  );
};

ZoneListCellWithDisabled.displayName = 'ZoneListCellWithDisabled';

export default memo(ZoneListCellWithDisabled);
