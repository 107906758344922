import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import {
  IExperimentTotalComparisonDictionaryValue,
  IExperimentTotalComparisonValue as IValue,
} from '../../../../../../../../../../../api/models/as-fields/experiments/ExperimentTotalComparisonTable/ExperimentTotalComparisonTable';
import { IComparisonTableBuilderCellConfig as ICellConfig } from '../../../../../../../../../../common/features/ComparisonTableBuilder/models/configs';
import { TotalComparisonStore } from '../../stores';
import { TTotalComparisonRowConfig as TRowConfig } from '../../../types';
import { formatDoubleNum } from '../../../../../../../../../../common/utils/helpers/numbers';
import { TotalComparisonComparableCell as ComparableCell } from '../../../components';

@provide.transient()
class TotalComparisonCellsService {
  @lazyInject(TotalComparisonStore)
  protected totalComparisonStore: TotalComparisonStore;

  createCellConfigList = (rowConfig: TRowConfig, valueList: IValue[]): ICellConfig[] => {
    const cellConfigList = valueList.map((value, i) => {
      return this.createCellConfigByAttributeType(rowConfig, value, i);
    });

    return cellConfigList;
  };

  createCellConfigByAttributeType = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    switch (rowConfig.initialModel.type) {
      case 'string':
        return this.createStringCellConfig(rowConfig, value, position);

      case 'double':
        return this.createDoubleCellConfig(rowConfig, value, position);

      case 'dictionary':
        return this.createDictionaryCellConfig(rowConfig, value, position);

      case 'formula':
        return this.createDoubleCellConfig(rowConfig, value, position);

      case 'summary':
        return this.createSummaryCellConfig(rowConfig, value, position);

      default:
    }
  };

  createStringCellConfig = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    const header = this.totalComparisonStore.getHeaderByPosition(position);

    const cellConfig: ICellConfig = {
      columnId: header.id,
      rowId: rowConfig.id,
    };

    if (value.tooltip) {
      cellConfig.customRenderConfig = {
        render: (cell, dataTestId) => {
          return (
            <ComparableCell
              factValue={value.stringValue}
              planValue={value.tooltip as string}
              dataTestId={dataTestId}
            />
          );
        },
      };

      return cellConfig;
    }

    cellConfig.autoRenderConfig = {
      preset: 'default',
      value: value.skip ? '–' : value.stringValue,
    };

    return cellConfig;
  };

  createDoubleCellConfig = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    const header = this.totalComparisonStore.getHeaderByPosition(position);

    const cellConfig: ICellConfig = {
      columnId: header.id,
      rowId: rowConfig.id,
    };

    if (value.tooltip || value.tooltip === 0) {
      cellConfig.customRenderConfig = {
        render: (cell, dataTestId) => {
          return (
            <ComparableCell
              factValue={formatDoubleNum(value.doubleValue) ?? formatDoubleNum(0)}
              planValue={formatDoubleNum(value.tooltip as number) ?? formatDoubleNum(0)}
              dataTestId={dataTestId}
            />
          );
        },
      };

      return cellConfig;
    }

    cellConfig.autoRenderConfig = {
      preset: 'default',
      value: value.skip ? formatDoubleNum(0) : formatDoubleNum(value.doubleValue),
    };

    return cellConfig;
  };

  createDictionaryCellConfig = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    const header = this.totalComparisonStore.getHeaderByPosition(position);

    const cellConfig: ICellConfig = {
      columnId: header.id,
      rowId: rowConfig.id,
    };

    if (value.tooltip) {
      cellConfig.customRenderConfig = {
        render: (cell, dataTestId) => {
          const tooltipValue = value.tooltip as IExperimentTotalComparisonDictionaryValue[];

          return (
            <ComparableCell
              factValue={this.createFullDictionaryLabel(value.dictionaryValues?.[0])}
              planValue={this.createFullDictionaryLabel(tooltipValue[0])}
              dataTestId={dataTestId}
            />
          );
        },
      };

      return cellConfig;
    }

    cellConfig.autoRenderConfig = {
      preset: 'default',
      value: value.skip ? '–' : this.createFullDictionaryLabel(value.dictionaryValues[0]),
    };

    return cellConfig;
  };

  createSummaryCellConfig = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    const header = this.totalComparisonStore.getHeaderByPosition(position);

    const cellConfig: ICellConfig = {
      columnId: header.id,
      rowId: rowConfig.id,
    };

    if (value.tooltip || value.tooltip === 0) {
      cellConfig.customRenderConfig = {
        render: (cell, dataTestId) => {
          return (
            <ComparableCell
              factValue={formatDoubleNum(value.doubleValue) ?? formatDoubleNum(0)}
              planValue={formatDoubleNum(value.tooltip as number) ?? formatDoubleNum(0)}
              dataTestId={dataTestId}
              isBold
            />
          );
        },
      };

      return cellConfig;
    }

    cellConfig.autoRenderConfig = {
      preset: 'bold',
      value: value.skip ? '–' : formatDoubleNum(value.doubleValue),
    };

    return cellConfig;
  };

  protected createFullDictionaryLabel = (
    value: IExperimentTotalComparisonDictionaryValue
  ): string => {
    if (value?.description) {
      return `${value?.name} (${value?.code})`;
    }

    return value?.name;
  };
}

export default TotalComparisonCellsService;
