import { IFormConfig } from '../../../../../../../../../common/features/form/models';
import { ISelectOption } from '../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { experimentTypeListOptions } from '../../../../../../../../../common/utils/constants/experiments';

const EXPERIMENTS_ATTRIBUTES_FORM_KEY = 'experimentsAttributes';

const REQUIRED_TEXT = 'Это поле не может быть пустым';

interface IExperimentAttributesForm {
  organizationId: string;
  contractId: string;
  responsibleId: string;
  name: string;
  type: string;
  seasonYear: string;
  cultureId: string;
  regionId: string;
  assigneeId: string;
  topic: string;
  purpose: string;
}

interface IExperimentAttributesFormForSave {
  organizationId: string;
  contractId: string;
  responsibleId: string;
  name: string;
  type: string;
  seasonYear: string;
  cultureId: string;
  regionId: string;
  assigneeId: string;
  topic: string;
  purpose: string;
}

interface ISearchQueryHandlers {
  contractorSearchQueryHandler: (v: string) => Promise<ISelectOption[]>;
  contractSearchQueryHandler: (v: string) => Promise<ISelectOption[]>;
  employeeSearchQueryHandler: (v: string) => Promise<ISelectOption[]>;
  regionSearchQueryHandler: (v: string) => Promise<ISelectOption[]>;
  experimentUserSearchQueryHandler: (v: string) => Promise<ISelectOption[]>;
  cultureSearchQueryHandler: (v: string) => Promise<ISelectOption[]>;
}

interface formConfigSetting {
  isViewMode?: boolean;
  isViewModeForContracts?: boolean;
}

const createExperimentAttributesFormConfig = ({
  contractorSearchQueryHandler,
  contractSearchQueryHandler,
  employeeSearchQueryHandler,
  regionSearchQueryHandler,
  experimentUserSearchQueryHandler,
  cultureSearchQueryHandler,
  isViewMode,
  isViewModeForContracts,
}: ISearchQueryHandlers & formConfigSetting): IFormConfig<IExperimentAttributesForm> => {
  const experimentAttributesFormConfig: IFormConfig<IExperimentAttributesForm> = {
    formKey: EXPERIMENTS_ATTRIBUTES_FORM_KEY,
    form: {
      organizationId: '',
      contractId: '',
      name: '',
      type: '',
      seasonYear: '',
      cultureId: '',
      regionId: '',
      assigneeId: '',
      responsibleId: '',
      topic: '',
      purpose: '',
    },
    elements: {
      organizationId: {
        name: 'organizationId',
        type: 'select',
        order: 1,
        isRequired: true,
        isLoading: true,
        isBlocked: isViewMode,
        label: 'Организация',
        selectOptions: {
          defaultValue: { label: 'Не выбрана', value: '' },
          emptyOption: { label: 'Не выбрана', value: '' },
          search: {},
          searchQuery: {
            handler: contractorSearchQueryHandler,
          },
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
      },
      contractId: {
        name: 'contractId',
        dependencyName: 'organizationId',
        type: 'select',
        order: 2,
        label: 'Договор',
        isLoading: true,
        isBlocked: isViewModeForContracts,
        selectOptions: {
          defaultValue: { label: 'Без договора', value: '' },
          emptyOption: { label: 'Без договора', value: '' },
          search: {},
          searchQuery: {
            handler: contractSearchQueryHandler,
          },
        },
      },
      responsibleId: {
        name: 'responsibleId',
        dependencyName: 'organizationId',
        type: 'select',
        order: 3,
        label: 'Ответственный от контрагента',
        isLoading: true,
        isBlocked: isViewMode,
        selectOptions: {
          defaultValue: { label: 'Не выбран', value: '' },
          emptyOption: { label: 'Не выбран', value: '' },
          search: {},
          searchQuery: {
            handler: employeeSearchQueryHandler,
          },
        },
      },
      name: {
        name: 'name',
        type: 'input',
        order: 4,
        isRequired: true,
        isBlocked: isViewMode,
        label: 'Название опыта',
        placeholder: 'Введите название',
        inputOptions: {
          maxLength: 50,
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
      },
      type: {
        name: 'type',
        type: 'select',
        order: 5,
        isRequired: true,
        isBlocked: isViewMode,
        label: 'Тип опыта',
        selectOptions: {
          optionList: experimentTypeListOptions,
          defaultValue: { label: 'Не выбран', value: '' },
          valueAfterCleaning: { label: 'Не выбран', value: '' },
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
      },
      seasonYear: {
        name: 'seasonYear',
        type: 'select',
        order: 6,
        isRequired: true,
        isBlocked: isViewMode,
        label: 'Сезон ',
        selectOptions: {
          optionList: [],
          defaultValue: { label: 'Не выбран', value: null },
          valueAfterCleaning: { label: 'Не выбран', value: null },
          search: {},
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
      },
      cultureId: {
        name: 'cultureId',
        type: 'select',
        order: 7,
        isRequired: true,
        isLoading: true,
        isBlocked: isViewMode,
        label: 'Культура',
        selectOptions: {
          defaultValue: { label: 'Не выбрана', value: '' },
          emptyOption: { label: 'Не выбрана', value: '' },
          search: {},
          searchQuery: {
            handler: cultureSearchQueryHandler,
          },
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
      },
      regionId: {
        name: 'regionId',
        type: 'select',
        order: 8,
        label: 'Регион',
        isRequired: true,
        isLoading: true,
        isBlocked: isViewMode,
        selectOptions: {
          defaultValue: { label: 'Не выбран', value: '' },
          emptyOption: { label: 'Не выбран', value: '' },
          search: {},
          searchQuery: {
            handler: regionSearchQueryHandler,
          },
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
      },
      assigneeId: {
        name: 'assigneeId',
        type: 'select',
        order: 9,
        label: 'Ответственный консультант',
        isRequired: true,
        isLoading: true,
        isBlocked: isViewMode,
        selectOptions: {
          defaultValue: { label: 'Не выбран', value: '' },
          emptyOption: { label: 'Не выбран', value: '' },
          search: {},
          searchQuery: {
            handler: experimentUserSearchQueryHandler,
          },
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
      },
      topic: {
        name: 'topic',
        type: 'textarea',
        order: 10,
        label: 'Тема опыта',
        placeholder: 'Не указана',
        isBlocked: isViewMode,
        textareaOptions: {
          maxLength: 500,
        },
      },
      purpose: {
        name: 'purpose',
        type: 'textarea',
        order: 11,
        label: 'Цель исследования',
        placeholder: 'Не указана',
        isBlocked: isViewMode,
        textareaOptions: {
          maxLength: 500,
        },
      },
    },
  };

  return experimentAttributesFormConfig;
};

export type { IExperimentAttributesForm, IExperimentAttributesFormForSave };

export { EXPERIMENTS_ATTRIBUTES_FORM_KEY, createExperimentAttributesFormConfig };
