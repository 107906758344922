import { FC, memo } from 'react';

import Styled from './ContextualHelpModalIcon.styles';

interface IProps {
  onClick: () => void;
}

const ContextualHelpModalIcon: FC<IProps> = ({ onClick }) => {
  return (
    <Styled.Wrapper onClick={onClick}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Styled.Path
          d="M7.97266 11.9648C7.67059 11.9648 7.42578 11.72 7.42578 11.418C7.42578 11.1159 7.67059 10.8711 7.97266 10.8711C8.27472 10.8711 8.51953 11.1159 8.51953 11.418C8.51953 11.72 8.27472 11.9648 7.97266 11.9648ZM8.51953 9.50391V8.76958C9.57697 8.51376 10.3096 7.55438 10.2678 6.4695C10.2224 5.28816 9.25322 4.32761 8.06131 4.28296C7.4245 4.25882 6.82315 4.48697 6.36856 4.92479C5.92177 5.35513 5.67578 5.93256 5.67578 6.55078C5.67578 6.85284 5.92059 7.09766 6.22266 7.09766C6.52472 7.09766 6.76953 6.85284 6.76953 6.55078C6.76953 6.23248 6.89653 5.9348 7.12724 5.71263C7.36586 5.48288 7.68277 5.36336 8.02029 5.37585C8.64418 5.39935 9.15132 5.89816 9.17493 6.51158C9.19682 7.08324 8.80226 7.58835 8.23648 7.71246C7.75914 7.81725 7.42578 8.22975 7.42578 8.71574V9.50391C7.42578 9.80597 7.67059 10.0508 7.97266 10.0508C8.27472 10.0508 8.51953 9.80597 8.51953 9.50391ZM11.622 13.9912C11.8804 13.8347 11.9629 13.4984 11.8064 13.2402C11.6499 12.9818 11.3136 12.8992 11.0553 13.0557C10.1368 13.6121 9.08018 13.9062 8 13.9062C4.74332 13.9062 2.09375 11.2567 2.09375 8C2.09375 4.74332 4.74332 2.09375 8 2.09375C11.2567 2.09375 13.9062 4.74332 13.9062 8C13.9062 9.16457 13.56 10.298 12.9048 11.2782C12.7369 11.5292 12.8044 11.8688 13.0554 12.0367C13.3065 12.2046 13.6462 12.1371 13.814 11.886C14.59 10.7255 15 9.38171 15 8C15 6.13026 14.2719 4.37236 12.9498 3.05025C11.6276 1.72813 9.86974 1 8 1C6.13026 1 4.37236 1.72813 3.05025 3.05025C1.72813 4.37236 1 6.13026 1 8C1 9.86974 1.72813 11.6276 3.05025 12.9498C4.37236 14.2719 6.13026 15 8 15C9.28003 15 10.5325 14.6512 11.622 13.9912Z"
          fill="#151F32"
        />
      </svg>
    </Styled.Wrapper>
  );
};

ContextualHelpModalIcon.displayName = 'ContextualHelpModalIcon';

export default memo(ContextualHelpModalIcon);
