import { observer } from 'mobx-react';
import { FC, memo } from 'react';

import Styled from './AuditReportCommentSection.style';
import { IAuditReportForm } from './forms/config/auditReportFormConfig';

interface IAuditReportCommentSectionProps {
  elements: Record<keyof IAuditReportForm, FC<{}>>;
}

const AuditReportCommentSection: FC<IAuditReportCommentSectionProps> = ({ elements }) => {
  const {
    specialNotes: SpecialNotes,
    technologicalDeviation: TechnologicalDeviation,
    generalComment: GeneralComment,
  } = elements;

  return (
    <Styled.Wrapper>
      <Styled.Attribute>{SpecialNotes && <SpecialNotes />}</Styled.Attribute>
      <Styled.Attribute $padding={'16px 0 0 0'}>
        {TechnologicalDeviation && <TechnologicalDeviation />}
      </Styled.Attribute>
      <Styled.Attribute $padding={'16px 0 0 0'}>
        {GeneralComment && <GeneralComment />}
      </Styled.Attribute>
    </Styled.Wrapper>
  );
};

AuditReportCommentSection.displayName = 'AuditReportCommentSection';

export default memo(observer(AuditReportCommentSection));
