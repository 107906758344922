export enum EReferenceType {
  Tips = 'TIPS',
  Modal = 'MODAL',
}

export interface IReference {
  id: string;
  path: string;
  parentPath: string;
  type: EReferenceType;
  title: string;
  text: string;
  active: boolean;
  description: string;
  creationDate: string;
  updateDate: string;
  createdBy: string;
}
