import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { ComparisonTableBuilderStore as ComparisonTableStore } from '../../../../../../../../../../common/features/ComparisonTableBuilder/mobx/stores';
import { ExecutionStore } from '../../stores';
import { formatDoubleNum } from '../../../../../../../../../../common/utils/helpers/numbers';
import { EExecutionTableColumnId as EColumnId } from '../../../types';
import { IExperimentFactTableValue } from '../../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';
import { IComparisonTableBuilderCell } from '../../../../../../../../../../common/features/ComparisonTableBuilder/models/data';
import { IExecutionDeviationsCellFormattedValue as IDeviationsFormattedValue } from '../../../components/cells/ExecutionDeviationsCell/ExecutionDeviationsCell.types';
import { getExecutionTableAttributeIdByRowId as getAttributeIdByRowId } from '../../../helpers';
import { IExecutionStepsValueAdditionalData as IValueAdditionalData } from '../../../containers/ExecutionSteps/types';

@provide.transient()
class ExecutionCellsService {
  @lazyInject(ComparisonTableStore)
  protected comparisonTableStore: ComparisonTableStore;

  @lazyInject(ExecutionStore)
  protected executionStore: ExecutionStore;

  protected getFormattedDeviationsConditionalValue = (
    planValue: string,
    factValue: string
  ): IDeviationsFormattedValue => {
    if (planValue && factValue) {
      const isEqual = planValue === factValue;

      return {
        color: isEqual ? 'default' : 'red',
        value: isEqual ? 'Нет' : 'Да',
      };
    }

    if (!planValue && factValue) {
      return {
        color: 'red',
        value: 'Да',
      };
    }

    return {
      color: 'default',
      value: '—',
    };
  };

  protected getFormattedDeviationsDoubleValue = (
    planValue: string | number,
    factValue: string | number
  ): IDeviationsFormattedValue => {
    const planNumber = formatDoubleNum(planValue !== '' ? planValue : null);
    const factNumber = formatDoubleNum(factValue !== '' ? factValue : null);

    if (this.hasNumber(planNumber) && this.hasNumber(factNumber)) {
      const result = Number(factNumber) - Number(planNumber);
      const isPositive = result > 0;
      const isEqual = result === 0;

      const formattedResult = isPositive ? `+${formatDoubleNum(result)}` : formatDoubleNum(result);

      return {
        color: isEqual ? 'default' : 'red',
        value: formattedResult,
      };
    }

    if (!this.hasNumber(planNumber) && this.hasNumber(factNumber)) {
      return {
        color: 'red',
        value: `+${factNumber}`,
      };
    }

    return {
      color: 'default',
      value: '—',
    };
  };

  protected getPlanCellDoubleValue = <V = any>(
    builderId: string,
    rowId: string
  ): string | number => {
    return this.comparisonTableStore.getCell<IExperimentFactTableValue & V>(
      builderId,
      rowId,
      EColumnId.Plan
    ).initialModel.doubleValue;
  };

  protected getPlanCellDateValue = <V = any>(builderId: string, rowId: string): string => {
    return this.comparisonTableStore.getCell<IExperimentFactTableValue & V>(
      builderId,
      rowId,
      EColumnId.Plan
    ).initialModel.dateValue;
  };

  protected getPlanCellDictionaryValue = <V = any>(builderId: string, rowId: string): string => {
    const [firstValue] =
      this.comparisonTableStore.getCell<IExperimentFactTableValue & V>(
        builderId,
        rowId,
        EColumnId.Plan
      ).initialModel.dictionaryValues || [];

    if (firstValue) {
      return firstValue.id;
    }
  };

  protected getFactCellDoubleValue = <V = any>(
    builderId: string,
    rowId: string
  ): string | number => {
    return this.comparisonTableStore.getCell<IExperimentFactTableValue & V>(
      builderId,
      rowId,
      EColumnId.Fact
    )?.initialModel?.doubleValue;
  };

  protected getFactCellDateValue = <V = any>(builderId: string, rowId: string): string => {
    return this.comparisonTableStore.getCell<IExperimentFactTableValue & V>(
      builderId,
      rowId,
      EColumnId.Fact
    ).initialModel.dateValue;
  };

  protected getFactCellDictionaryValue = <V = any>(builderId: string, rowId: string): string => {
    const [firstValue] =
      this.comparisonTableStore.getCell<IExperimentFactTableValue & V>(
        builderId,
        rowId,
        EColumnId.Fact
      ).initialModel.dictionaryValues || [];

    if (firstValue) {
      return firstValue.id;
    }
  };

  protected getDeviationsCell = <V = any>(
    builderId: string,
    rowId: string
  ): IComparisonTableBuilderCell<V> => {
    return this.comparisonTableStore.getCell<IExperimentFactTableValue & V>(
      builderId,
      rowId,
      EColumnId.Deviations
    );
  };

  protected getZoneFactUpdatedValue = (builderId: string, rowId: string): string | number => {
    const attributeId = getAttributeIdByRowId(rowId);

    return this.executionStore.getZoneForUpdate(this.executionStore.selectedZoneId)?.[
      attributeId
    ] as string;
  };

  protected getStepFactUpdatedValue = (
    builderId: string,
    rowId: string,
    options?: {
      isPhenophase?: boolean;
    }
  ): string => {
    const attributeId = options?.isPhenophase
      ? `${getAttributeIdByRowId(rowId)}Id`
      : getAttributeIdByRowId(rowId);

    const deviationsCell = this.getDeviationsCell<IValueAdditionalData>(builderId, rowId);

    return this.executionStore.getStepForUpdate(
      this.executionStore.selectedZoneId,
      deviationsCell?.initialModel?.stepId
    )?.[attributeId] as string;
  };

  protected getItemFactUpdatedValue = (builderId: string, rowId: string): string | number => {
    const attributeId = getAttributeIdByRowId(rowId);

    const deviationsCell = this.getDeviationsCell<IValueAdditionalData>(builderId, rowId);

    return this.executionStore.getItemForUpdate(
      this.executionStore.selectedZoneId,
      deviationsCell?.initialModel?.stepId,
      deviationsCell?.initialModel?.fertilizerId
    )?.[attributeId] as string;
  };

  protected getFactZoneDoubleValue = (builderId: string, rowId: string): string => {
    const factInitialValue = this.getFactCellDoubleValue(builderId, rowId);
    const factUpdatedValue = this.getZoneFactUpdatedValue(builderId, rowId);

    return formatDoubleNum(factUpdatedValue ?? factInitialValue);
  };

  protected getFactStepDoubleValue = (builderId: string, rowId: string): string => {
    const factInitialValue = this.getFactCellDoubleValue(builderId, rowId);
    const factUpdatedValue = this.getStepFactUpdatedValue(builderId, rowId);

    return formatDoubleNum(factUpdatedValue ?? factInitialValue);
  };

  protected getFactFertilizerDoubleValue = (builderId: string, rowId: string): string => {
    const factInitialValue = this.getFactCellDoubleValue(builderId, rowId);
    const factUpdatedValue = this.getItemFactUpdatedValue(builderId, rowId);

    return formatDoubleNum(factUpdatedValue ?? factInitialValue);
  };

  protected hasNumber = (doubleNum: string): boolean => {
    return Boolean(doubleNum || doubleNum === '0');
  };
}

export default ExecutionCellsService;
