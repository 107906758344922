import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

type Color = 'blue' | 'red';

const CheckMark = styled.span<{ $checked?: boolean; $color: Color; $disabled?: boolean }>`
  display: block;
  height: 12px;
  width: 12px;
  border: 2px solid
    ${({ $checked, $color, $disabled }) =>
      !$disabled && $checked
        ? $color === 'blue'
          ? Colors.blue
          : Colors.red
        : Colors.secondaryDarkGray};
  border-radius: 50%;
  background-color: transparent;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Text = styled.div<{ $checked?: boolean; $disabled?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: ${({ $checked, $disabled }) =>
    $checked && !$disabled ? Colors.black : Colors.secondaryDarkGray};
`;

const Label = styled.label<{ $checked?: boolean; $color: Color; $disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  position: relative;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  padding: 4px 8px;
  border-radius: 16px;
  background-color: ${({ $checked, $color, $disabled }) =>
    !$disabled && $checked
      ? $color === 'blue'
        ? Colors.lightBlue
        : Colors.lightPink
      : Colors.secondaryGray};

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :hover {
    background-color: ${({ $color, $disabled }) =>
      !$disabled && ($color === 'blue' ? Colors.lightBlue : Colors.lightPink)};
  }

  :hover ${CheckMark} {
    border-color: ${({ $color, $disabled }) =>
      !$disabled && ($color === 'blue' ? Colors.blue : Colors.red)};
  }

  :hover ${Text} {
    color ${({ $disabled }) => !$disabled && Colors.black};
  }
`;

const StyledRadioButton = {
  Label,
  Input,
  CheckMark,
  Text,
};

export default StyledRadioButton;
