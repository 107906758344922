import { FC } from 'react';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';
import { isNaN } from 'lodash';

import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';

import Styled from './TotalComparisonColumn.styles';

interface IAnalyzedLetter {
  id: number;
  tag: 'sub' | 'span';
  val: string;
}

const createAnalyzedLetterList = (word: string): IAnalyzedLetter[] => {
  return word.split('').map<IAnalyzedLetter>((letter, index) => {
    const number = Number(letter);

    return {
      id: index,
      tag: isNaN(number) ? 'span' : 'sub',
      val: letter,
    };
  });
};

interface IProps {
  name: string;
  dataTestId: string;
}

const TotalComparisonFormulaRow: FC<IProps> = ({ name, dataTestId }) => {
  const { ref: nameRef, isShowTooltip: isShowNameTooltip } = useShowTooltip<HTMLDivElement>();

  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <span>
      {createAnalyzedLetterList(name).map(({ id, tag, val }) => {
        if (tag === 'sub') {
          return <sub key={id}>{val}</sub>;
        } else {
          return <span key={id}>{val}</span>;
        }
      })}
    </span>
  );
};

TotalComparisonFormulaRow.displayName = 'TotalComparisonFormulaRow';

export default TotalComparisonFormulaRow;
