import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import {
  IExperiment,
  IExperimentStep,
} from '../../../../../../../../../../../../../api/models/as-fields/experiments';
import { IOrganization } from '../../../../../../../../../../../../../api/models/da-profile/organizations/Organization.model';
import { IEmployee } from '../../../../../../../../../../../../../api/models/da-profile/users';
import { IDictionaryEntity } from '../../../../../../../../../../../../../api/models/da-dictionary/dictionary';
import { ISelectOption } from '../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';

@provide.singleton()
class ObservationStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _mapIdToExpiriments: Map<string, IExperiment> = new Map();
  private _mapIdToOrganizations: Map<string, IOrganization> = new Map();
  private _mapIdToAllUsers: Map<string, IEmployee> = new Map();
  private _mapIdToExperimentSteps: Map<string, IExperimentStep> = new Map();

  private _organizationId = null;
  private _experimentId = null;

  private _searchQueries = {
    organizationSearchQuery: '',
    experimentSearchQuery: '',
    experimentStepSearchQuery: '',
    techOperationSearchQuery: '',
    assigneeSearchQuery: '',
  };

  private _dropdownPageNumbers = {
    organizationCurrentPageNumber: 0,
    organizationTotalPageNumber: 0,
    experimentCurrentPageNumber: 0,
    experimentTotalPageNumber: 0,
    experimentStepCurrentPageNumber: 0,
    experimentStepTotalPageNumber: 0,
    techOperationCurrentPageNumber: 0,
    techOperationTotalPageNumber: 0,
    assigneeCurrentPageNumber: 0,
    assigneeTotalPageNumber: 0,
  };

  private _organizationOptionList: Array<ISelectOption> = [];

  private _experimentOptionList: Array<ISelectOption> = [];

  private _experimentStepOptionList: ISelectOption<IExperimentStep>[] = [];

  private _assigneeOptionList: Array<ISelectOption> = [];

  private _operationOptionsById: Map<string, ISelectOption<IDictionaryEntity>> = new Map();

  get allUsers() {
    return [...this._mapIdToAllUsers.values()];
  }

  get organizations() {
    return [...this._mapIdToOrganizations.values()];
  }

  get expiriments() {
    return [...this._mapIdToExpiriments.values()];
  }

  get expirimentSteps() {
    return [...this._mapIdToExperimentSteps.values()];
  }

  get organizationId() {
    return this._organizationId;
  }

  get experimentId() {
    return this._experimentId;
  }

  get searchQueries() {
    return this._searchQueries;
  }

  get dropdownPageNumbers() {
    return this._dropdownPageNumbers;
  }

  get organizationOptionList() {
    return this._organizationOptionList;
  }

  get experimentOptionList() {
    return this._experimentOptionList;
  }

  getExpStepOption = (stepId: string): ISelectOption<IExperimentStep> => {
    return this._experimentStepOptionList.find(option => option.value === stepId);
  };

  get experimentStepOptionList() {
    return this._experimentStepOptionList;
  }

  get assigneeOptionList() {
    return this._assigneeOptionList;
  }

  get operationOptionList() {
    return [...this._operationOptionsById.values()];
  }

  setDropdownPageNumbers = (dropdownName: string, pageNumber: number): void => {
    this._dropdownPageNumbers[dropdownName] = pageNumber;
  };

  setDropdownSearchQuery = (dropdownName: string, query: string): void => {
    this._searchQueries[dropdownName] = query;
  };

  setOrganizationOptionList = (optionList: ISelectOption[]): void => {
    this._organizationOptionList = optionList;
  };

  setExperimentOptionList = (optionList: ISelectOption[]): void => {
    this._experimentOptionList = optionList;
  };

  setExperimentStepOptionList = (optionList: ISelectOption[]): void => {
    this._experimentStepOptionList = optionList;
  };

  setAssigneeOptionList = (optionList: ISelectOption[]): void => {
    this._assigneeOptionList = optionList;
  };

  setOrganizationId = (organizationId): void => {
    this._organizationId = organizationId;
  };

  setExperimentId = (experimentId): void => {
    this._experimentId = experimentId;
  };

  setOperationOptionList = (
    optionList: ISelectOption<IDictionaryEntity>[],
    options?: {
      isClearPreviousList?: boolean;
    }
  ): void => {
    const previousEntryList = options?.isClearPreviousList
      ? []
      : this._operationOptionsById.entries();

    const newCollection = new Map<string, ISelectOption<IDictionaryEntity>>(previousEntryList);

    optionList.forEach(option => {
      newCollection.set(option.value as string, option);
    });

    this._operationOptionsById = newCollection;
  };

  getExperimentInfoById = (experimentId): IExperiment => {
    return this._mapIdToExpiriments.get(experimentId);
  };

  clearOperationOptionsById = (): void => {
    this._operationOptionsById.clear();
  };

  clearStore = (): void => {
    this.clearOperationOptionsById();
  };
}

export default ObservationStore;
