import { observer } from 'mobx-react';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TAuditsParams } from '../../../../../../models/params/AuditsParams.model';
import { generateAuditsPath } from '../../../../../../utils/helpers';

import Styled from './AuditInfo.style';

type TAuditInfoProps = {
  formElements: any;
  handleReadyClick: () => void;
  titleName: string;
  buttonName: string;
  isSubmitDisabled?: boolean;
  isShowEditIcon?: boolean;
};

const AuditInfo: FC<TAuditInfoProps> = ({
  formElements,
  handleReadyClick,
  titleName,
  buttonName,
  isSubmitDisabled = false,
  isShowEditIcon = false,
}) => {
  const {
    organizationId: Organization,
    experimentId: Expiriment,
    planStartDate: ObservationStartDate,
    planEndDate: ObservationEndDate,
    parentStepId: ExpirimentStep,
    techOperationTypeId: Operation,
    assigneeId: Executor,
    name: Name,
  } = formElements;

  const navigate = useNavigate();

  const { auditId } = useParams<TAuditsParams>();

  const onIconClick = () => {
    navigate(generateAuditsPath('editAudit', { auditId }));
  };

  const commonInformationDataTestId = 'audit-info';

  return (
    <>
      <Styled.Container data-test-id={`${commonInformationDataTestId}-wrapper`}>
        <Styled.Header data-test-id={`${commonInformationDataTestId}-header`}>
          <Styled.Title data-test-id={`${commonInformationDataTestId}-header-title`}>
            {titleName}
          </Styled.Title>
          {isShowEditIcon && (
            <Styled.Icon
              onClick={onIconClick}
              data-test-id={`${commonInformationDataTestId}-header-icon`}
            />
          )}
        </Styled.Header>
        <Styled.Body data-test-id={`${commonInformationDataTestId}-body`}>
          <Styled.Attribute
            marginBottom="16px"
            marginRight="12px"
            data-test-id={`${commonInformationDataTestId}-organization-wrapper`}
          >
            {Organization ? (
              <Organization data-test-id={`${commonInformationDataTestId}-organization`} />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            data-test-id={`${commonInformationDataTestId}-expiriment-wrapper`}
            marginBottom="16px"
          >
            {Expiriment ? (
              <Expiriment data-test-id={`${commonInformationDataTestId}-expiriment`} />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            marginBottom="16px"
            marginRight="12px"
            data-test-id={`${commonInformationDataTestId}-observation-start-date-wrapper`}
          >
            {ObservationStartDate ? (
              <ObservationStartDate
                data-test-id={`${commonInformationDataTestId}-observation-start-date`}
              />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            data-test-id={`${commonInformationDataTestId}-observation-end-date-wrapper`}
            marginBottom="16px"
          >
            {ObservationEndDate ? (
              <ObservationEndDate
                data-test-id={`${commonInformationDataTestId}-observation-end-date`}
              />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            marginBottom="16px"
            marginRight="12px"
            data-test-id={`${commonInformationDataTestId}-expiriment-step-wrapper`}
          >
            {ExpirimentStep ? (
              <ExpirimentStep data-test-id={`${commonInformationDataTestId}-expiriment-step`} />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            data-test-id={`${commonInformationDataTestId}-operation-wrapper`}
            marginBottom="16px"
          >
            {Operation ? (
              <Operation data-test-id={`${commonInformationDataTestId}-operation`} />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            marginBottom="16px"
            marginRight="12px"
            data-test-id={`${commonInformationDataTestId}-executor-wrapper`}
          >
            {Executor ? (
              <Executor data-test-id={`${commonInformationDataTestId}-executor`} />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            marginBottom="16px"
            data-test-id={`${commonInformationDataTestId}-name-wrapper`}
          >
            {Name ? <Name data-test-id={`${commonInformationDataTestId}-name`} /> : null}
          </Styled.Attribute>
        </Styled.Body>
      </Styled.Container>

      <Styled.ButtonWrapper
        type={'button'}
        color={'primary'}
        fontSize={'14px'}
        onClick={handleReadyClick}
        disabled={isSubmitDisabled}
        data-test-id={`${commonInformationDataTestId}-button-wrapper`}
      >
        {buttonName}
      </Styled.ButtonWrapper>
    </>
  );
};
export default observer(AuditInfo);
