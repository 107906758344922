import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';

import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { ContentLoader } from '../../../../../../../common/features/UI/loaders/ContentLoader';
import { useDataTestIdV2 } from '../../../../../../../common/utils/hooks/locators';
import { ComparisonTableBuilder } from '../../../../../../../common/features/ComparisonTableBuilder';
import { SpinnerLoader } from '../../../../../../../common/components/ui/loaders';
import { EComparisonTableName } from '../../../../../../constants/features';
import { useSelectedExp, useZonesPlugResolver } from '../../hooks';
import useExperimentsParams from '../../../../hooks/useExperimentsParams/useExperimentsParams';

import { NO_ZONES_TITLE, NOT_SELECTED_ZONES_TITLE } from './constants/titles';
import { TotalComparisonController } from './mobx/controllers';
import Styled from './TotalComparison.styles';
import { TotalComparisonStore } from './mobx/stores';
import { TotalComparisonPlug } from './components';

const TotalComparison: FC = () => {
  const totalComparisonStore = useStore(TotalComparisonStore);
  const totalComparisonController = useStore(TotalComparisonController);

  const selectedExp = useSelectedExp({ isClearOnUnmount: true });

  const params = useExperimentsParams();
  const isExperimentCreating = params.experimentId === 'create';

  const {
    loading,
    showPlug,
    plugType,
    plugTitle,
    Plug: ZonesPlug,
  } = useZonesPlugResolver(selectedExp?.id, NO_ZONES_TITLE, NOT_SELECTED_ZONES_TITLE);

  useEffect(() => {
    return () => {
      totalComparisonController.clearStore();
    };
  }, []);

  useEffect(() => {
    if (isExperimentCreating) {
      return;
    }

    totalComparisonController.initiateTables(params.experimentId);
  }, [params.experimentId]);

  const getDataTestId = useDataTestIdV2('total-comparison');

  if (loading) return <SpinnerLoader needToHideContent={false} />;

  if (showPlug) {
    return <ZonesPlug type={plugType} title={plugTitle} />;
  }

  return (
    <Styled.Wrapper {...getDataTestId()}>
      {totalComparisonStore.noData ? (
        <Styled.Container>
          <Styled.Title>Сравнение</Styled.Title>
          <TotalComparisonPlug />
        </Styled.Container>
      ) : totalComparisonStore.isPageLoading ? (
        <ContentLoader
          overlayStyles={{
            position: 'relative',
            height: '240px',
            width: '100%',
            borderRadius: '16px',
          }}
          dataTestId={getDataTestId('content-loader')['data-test-id']}
        />
      ) : (
        <ComparisonTableBuilder
          builderId={EComparisonTableName.TotalComparison}
          tableName={EComparisonTableName.TotalComparison}
        />
      )}
    </Styled.Wrapper>
  );
};

TotalComparison.displayName = 'TotalComparison';

export default observer(TotalComparison);
