import { useMemo } from 'react';

import { useStore } from '../../../../../utils/helpers/mobx';
import formStore from '../../../mobx/stores/FormStore/Form.store';
import { FormController } from '../../../mobx/controllers';

const useCheckChangedForm = () => {
  const { lastChangedElement } = useStore(formStore);
  const { focusChangedElement } = useStore(FormController);

  const isFormChanged = useMemo<boolean>(() => {
    return Boolean(lastChangedElement);
  }, [lastChangedElement]);

  return {
    isFormChanged,
    focusChangedElement,
  };
};

export default useCheckChangedForm;
