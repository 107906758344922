import { useCallback } from 'react';
import { TModalConfig, useModal } from '@farmlink/farmik-ui';

import { ContextualHelpController } from '../../mobx/controllers';
import {
  EReferenceType,
  IReference,
} from '../../../../../../api/models/as-fields/references/Reference.model';
import { ContextualHelpStore } from '../../mobx/stores';
import ContextualHelpModalConfig from '../../components/ContextualHelpModal/ContextualHelpModal';
import { ContextualHelpModalIcon, ContextualHelpToolTipIcon } from '../../components';
import { useStore } from '../../../../utils/helpers/mobx';

const useContextualHelpActions = () => {
  const store = useStore(ContextualHelpStore);
  const controller = useStore(ContextualHelpController);

  const modalActions = useModal();

  const initiateContextualHelpList = useCallback(
    async (parentPath: string, getHandler: () => Promise<IReference[]>) => {
      const helpList = (await getHandler()) ?? [];

      controller.initiateHelpListByParentPath(parentPath, helpList);
    },
    []
  );

  const getModalIcon = useCallback((help: IReference) => {
    const updatedModalConfig: TModalConfig = {
      ...ContextualHelpModalConfig,
      id: help.path,
    };

    const handleClick = () => {
      modalActions.registerModalList([updatedModalConfig], 'contextual-help');
      modalActions.openModalByModalId(updatedModalConfig.id, {
        contextualHelp: help,
      });
    };

    return <ContextualHelpModalIcon onClick={handleClick} />;
  }, []);

  const getToolTipIcon = useCallback((help: IReference) => {
    return <ContextualHelpToolTipIcon help={help} />;
  }, []);

  const getContextualHelpIcon = useCallback((parentPath: string, path: string) => {
    const help = store.getHelp(parentPath, path);

    if (!help) return null;

    switch (help.type) {
      case EReferenceType.Modal:
        return getModalIcon(help);

      case EReferenceType.Tips:
        return getToolTipIcon(help);

      default:
        return null;
    }
  }, []);

  return {
    initiateContextualHelpList,
    getContextualHelpIcon,
  };
};

export default useContextualHelpActions;
