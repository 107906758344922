import { FC, memo, useCallback } from 'react';
import { Switcher } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { _Stage, IComparisonTableImagesConfig } from '../../../models';
import { DynamicTableController } from '../../../mobx/controllers';
import { useStore } from '../../../../../utils/helpers/mobx';
import { ComparisonTableImagesAttributeContainer as ImagesAttributeContainer } from '../../../containers/attributes';

import Styled from './ComparisonTableImagesStage.styles';

interface IProps {
  extendedStage: _Stage;
  imagesConfig: IComparisonTableImagesConfig | null;
}

const ComparisonTableImagesStage: FC<IProps> = ({ extendedStage, imagesConfig }) => {
  const { name, attributeIds } = extendedStage;

  const { onToggleImagesStageSelection, onToggleImagesStageOrientation } =
    useStore(DynamicTableController);

  const handleSelectClick = useCallback((isSelected: boolean) => {
    onToggleImagesStageSelection(extendedStage.id, isSelected);
  }, []);

  const handleHorizontalClick = useCallback(() => {
    onToggleImagesStageOrientation(extendedStage.id, 'horizontal');
  }, []);

  const handleVerticalClick = useCallback(() => {
    onToggleImagesStageOrientation(extendedStage.id, 'vertical');
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.FlexWrapper>
          <Switcher value={imagesConfig?.isSelected} onChange={handleSelectClick} />

          <Styled.Name>{name}</Styled.Name>
        </Styled.FlexWrapper>

        <Styled.FlexWrapper $isHidden={!imagesConfig?.isSelected}>
          <Styled.OrientationLabel>Группирока в отчёте:</Styled.OrientationLabel>

          <Styled.FlexWrapper>
            <Styled.HorizontalOrientationButton
              onClick={handleHorizontalClick}
              $isSelected={imagesConfig?.orientation === 'horizontal'}
            />

            <Styled.VerticalOrientationButton
              onClick={handleVerticalClick}
              $isSelected={imagesConfig?.orientation === 'vertical'}
            />
          </Styled.FlexWrapper>
        </Styled.FlexWrapper>
      </Styled.Header>

      <Styled.Body $isHidden={!imagesConfig?.isSelected}>
        {attributeIds.map(attributeId => (
          <ImagesAttributeContainer
            key={attributeId}
            tableId={extendedStage.tableId}
            attributeId={attributeId}
          />
        ))}
      </Styled.Body>
    </Styled.Wrapper>
  );
};

ComparisonTableImagesStage.displayName = 'ComparisonTableImagesStage';

export default memo(observer(ComparisonTableImagesStage));
