import { AxiosError } from 'axios';

import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { AxiosService } from '../../../../../../../../../../common/mobx/services/axios/AxiosService';
import { IOrganization } from '../../../../../../../../../../../api/models/da-profile/organizations/Organization.model';
import {
  TCreateExperimentReq,
  TCreateExperimentRes,
  TGetDictionaryEntityListReq,
  TGetExperimentContractListReq,
  TGetExperimentUserListReq,
  TGetOrganizationListReq,
  TGetOrganizationUserListReq,
} from '../../../../../../../../../../../api';
import { IEmployee, IUser } from '../../../../../../../../../../../api/models/da-profile/users';
import { ISeason } from '../../../../../../../../../../../api/models/as-fields/seasons';
import { IDictionaryEntity } from '../../../../../../../../../../../api/models/da-dictionary/dictionary';
import { IExperimentContract } from '../../../../../../../../../../../api/models/as-fields/experiments';
import { IShowErrorModal } from '../../../../../../../../../../common/utils/hooks/useErrorModal';

@provide.singleton()
class ExperimentAttributesService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  createExperiment = async (
    payload: TCreateExperimentReq,
    showErrorHandler?: IShowErrorModal
  ): Promise<TCreateExperimentRes | never> => {
    const { createExperiment } = this.axiosService.api;

    try {
      const createdExperiment = await createExperiment(payload);

      return createdExperiment;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      showErrorHandler?.(e as AxiosError);
    }
  };

  getContractorList = async (
    payload?: TGetOrganizationListReq
  ): Promise<{ content: IOrganization[]; totalPages: number }> => {
    const { getExperimentOrganizationList } = this.axiosService.api;

    try {
      const { content, totalPages } = await getExperimentOrganizationList({
        size: 2000,
        ...(payload ?? {}),
      });

      return { content, totalPages };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getContractList = async (
    payload: TGetExperimentContractListReq
  ): Promise<{ content: IExperimentContract[]; totalPages: number }> => {
    const { getExperimentContractList } = this.axiosService.api;

    try {
      const { content, totalPages } = await getExperimentContractList(payload);

      return { content, totalPages };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getEmployeeList = async (
    payload: TGetOrganizationUserListReq
  ): Promise<{ content: IEmployee[]; totalPages: number }> => {
    const { getOrganizationUserList } = this.axiosService.api;

    try {
      const { content, totalPages } = await getOrganizationUserList({
        status: 'ACTIVE',
        ...payload,
      });

      return { content, totalPages };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getExperimentUserList = async (
    payload: TGetExperimentUserListReq
  ): Promise<{ content: IUser[]; totalPages: number }> => {
    const { getExperimentUserList } = this.axiosService.api;

    try {
      const { content, totalPages } = await getExperimentUserList(payload);

      return { content, totalPages };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getSeasonList = async (organizationId: string): Promise<ISeason[]> => {
    const { getSeasonList } = this.axiosService.api;

    try {
      const seasonList = await getSeasonList({
        organizationId,
        allowStub: false,
        limit: 40,
        sort: 'DESC',
        endYear: 2030,
      });

      return seasonList;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getCurrentContractorRegion = async (code: string): Promise<any> => {
    try {
      const fetchedRegions = await this.axiosService.api.getRegionList({ codeFilter: code });

      return fetchedRegions;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  getDictionaryEntityList = async (
    payload: Partial<TGetDictionaryEntityListReq>,
    query?: Record<string, string | number>
  ): Promise<{ content: IDictionaryEntity[]; totalPages: number }> => {
    const { getDictionaryEntityList } = this.axiosService.api;

    try {
      const { content, totalPages } = await getDictionaryEntityList(payload, query && { query });

      return { content, totalPages };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default ExperimentAttributesService;
