import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../../../../../../../../../../common/assets/styles/colors';

const { SecondaryDarkGray, GeneralBlack } = ECommonColor;

const CultureNameList = styled.div<{ $isDisabled: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${GeneralBlack};

  cursor: pointer;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5;
      cursor: auto;
      pointer-events: none;
    `}
`;

const AddCultureButton = styled.button<{ $isDisabled: boolean }>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  background: transparent;
  border: none;
  padding: 0;

  cursor: pointer;
  color: ${SecondaryDarkGray};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const Wrapper = styled.div`
  width: 100%;
  white-space: nowrap;
`;

const StyledZoneListPredecessorCell = {
  CultureNameList,
  AddCultureButton,
  Wrapper,
};

export default StyledZoneListPredecessorCell;
