import { EObservationStatus } from '../../../../../modules/common/utils/constants/observation';
import { ETaskStatus } from '../../../../../modules/common/utils/constants/task';
import { EExperimentCultureZoneType } from '../ExperimentCultureZone';

export type TExperimentFactTableAttributeType = 'double' | 'date' | 'dictionary' | 'nested';

export enum EExperimentFactTableStepAttributeId {
  PlanStartDate = 'planStartDate',
  PlanEndDate = 'planEndDate',
  Phenophase = 'phenophase',
  PhenophaseEnd = 'phenophaseEnd',
  ServicePricePerArea = 'servicePricePerArea',
  Fertilizers = 'fertilizers',
  Fertilizer = 'fertilizer',
  Concentration = 'concentration',
  PricePerUnit = 'pricePerUnit',
}

export enum EExperimentFactTableCalculationsAttributeId {
  CropPrice = 'cropPrice',
  Yield = 'yield',
  Revenue = 'revenue',
  TotalFertilizerPrice = 'totalFertilizerPrice',
  TotalServicePrice = 'totalServicePrice',
  TotalPrice = 'totalPrice',
}

/**
 * Таблица для итого отчета с фактическими данными.
 *
 * @DTO ExperimentFactTableDataDto
 * @interface IExperimentFactTable
 */
export interface IExperimentFactTable {
  zones: IExperimentFactTableZone[];
}

export interface IExperimentFactTableZone {
  id: string;
  name: string;
  type: EExperimentCultureZoneType;
  area: number;
  calculations: IExperimentFactTableStage[];
  steps: IExperimentFactTableStage[];
}

export interface IExperimentFactTableStage {
  id: string;
  order: number;
  name: string;
  attributes: IExperimentFactTableAttribute[];
  deletable?: boolean;
  audits?: IExperimentFactTableAudit[];
}

export interface IExperimentFactTableAttribute {
  id: EExperimentFactTableCalculationsAttributeId | string;
  name: string;
  type: TExperimentFactTableAttributeType;
  editable: boolean;
  values: IExperimentFactTableValue[];
  isTitle?: boolean;
}

export interface IExperimentFactTableValue {
  editable: boolean;
  entityId?: string;
  skip?: boolean;
  doubleValue?: string | number;
  dateValue?: string;
  stages?: IExperimentFactTableStage[];
  dictionaryValues?: {
    id: string;
    name: string;
    description?: string;
    code?: string;
  }[];
}

export interface IExperimentFactTableAudit {
  id: string;
  name: string;
  status: EObservationStatus;
  date: string;
  diffDegree: string;
  taskStatus: ETaskStatus;
}
