import { observer } from 'mobx-react';
import { FC, memo } from 'react';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { DropdownButton, TButtonMultiAction } from '@farmlink/farmik-ui';

import { ExperimentService } from '../../../../../../../../../common/mobx/services/as-fields';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { CreateExperimentStore } from '../../../../mobx/stores';
import { ECreateExperimentRoute } from '../../../../routes';
import { EExperimentStatus } from '../../../../../../../../../../api/models/as-fields/experiments';

import Styled from './HeaderContent.style';

const HeaderContent: FC = observer(() => {
  const { selectedExp } = useStore(CreateExperimentStore);

  const experimentService = useStore(ExperimentService);
  const { generateTechTaskReport, generateFinalReport } = experimentService;

  const params = useParams();
  const navigate = useNavigate();

  const generateTechTask = async () => {
    try {
      const response = await generateTechTaskReport(params.experimentId);

      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      saveAs(blob, `ТЗ - ${selectedExp?.name || 'опыта'}`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const generateFinal = async () => {
    try {
      const response = await generateFinalReport(params.experimentId);

      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      saveAs(blob, `Итоговый отчет - ${selectedExp?.name || 'опыта'}`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const generateDocumentsPath = () => {
    navigate(
      generatePath(`${ECreateExperimentRoute.Documents}`, {
        experimentId: params.experimentId,
      })
    );
  };

  const generateApprovalPath = () => {
    navigate(
      generatePath(`${ECreateExperimentRoute.Approval}`, {
        experimentId: params.experimentId,
      })
    );
  };

  const buttonActions: TButtonMultiAction[] = [
    {
      title: 'Посмотреть документы',
      onClick: generateDocumentsPath,
    },
    {
      title: 'Скачать ТЗ',
      onClick: generateTechTask,
    },
    {
      title: 'Скачать итоговый отчет',
      onClick: generateFinal,
    },
  ];

  const isContractsButtonDisabled = selectedExp?.status === EExperimentStatus.FullCompleted;

  return (
    <>
      {params?.experimentId === 'create' ? null : (
        <Styled.StyledWrapper>
          <Styled.OutlinedButton
            color="default"
            type="button"
            styleType="outlined"
            onClick={generateApprovalPath}
          >
            Чат согласования
          </Styled.OutlinedButton>
          <DropdownButton
            title={'Документы'}
            multiActionList={buttonActions}
            disabled={isContractsButtonDisabled}
            dataTestId="fullscreen-action-button"
            style={{ size: 'sm', placement: 'bottom', color: 'default' }}
          />
        </Styled.StyledWrapper>
      )}
    </>
  );
});

export default memo(HeaderContent);
