import { makeAutoObservable } from 'mobx';
import _ from 'lodash';

import { CreateObservationService } from '../../services';
import { CreateObservationStore } from '../../stores';
import { createExpirimentOptionList, createOptionListFromUsers } from '../../../utils/functions';
import { lazyInject, provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { ISelectOption } from '../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import {
  createDictionaryEntitySelectOptionList,
  createExpStepSelectOptionList,
  createOrganizationSelectOptionList,
} from '../../../../../../../../../../../../common/utils/helpers/selectOptions';
import { ExperimentStepsService } from '../../../../../../../../../../../../common/mobx/services/as-fields';
import { AuditStore } from '../../../../../../../mobx/store';
import { DictionaryService } from '../../../../../../../../../../../../common/mobx/services/da-dictionary';
import { IExperimentStep } from '../../../../../../../../../../../../../api/models/as-fields/experiments';

@provide.singleton()
class CreateObservationController {
  @lazyInject(AuditStore)
  protected auditStore: AuditStore;

  @lazyInject(ExperimentStepsService)
  protected experimentStepsService: ExperimentStepsService;

  @lazyInject(CreateObservationStore)
  protected createObservationStore: CreateObservationStore;

  @lazyInject(CreateObservationService)
  protected createObservationService: CreateObservationService;

  @lazyInject(DictionaryService)
  protected dictionaryService: DictionaryService;

  constructor() {
    makeAutoObservable(this);
  }

  fetchAudit = async (id: string): Promise<IExperimentStep> => {
    const { setSelectedAudit } = this.auditStore;
    const { getExperimentStep } = this.experimentStepsService;

    const fetchedAudit = await getExperimentStep({ id });

    if (fetchedAudit) {
      setSelectedAudit(fetchedAudit);

      return fetchedAudit;
    }
  };

  saveExperimentStep = async data => {
    const experimentId = await this.createObservationService.saveExperimentStep(data);

    return experimentId;
  };

  updateExperimentStep = async (data, auditId) => {
    const experimentId = await this.createObservationService.updateExperimentStep({
      ...data,
      experimentStepId: auditId,
    });

    return experimentId;
  };

  organizationsSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { content, totalPages } = await this.createObservationService.getOrganizations(
      searchQuery,
      0
    );

    if (_.isArray(content)) {
      const { setOrganizationOptionList, setDropdownPageNumbers, setDropdownSearchQuery } =
        this.createObservationStore;

      const organizationList = createOrganizationSelectOptionList(content);

      setDropdownPageNumbers('organizationCurrentPageNumber', 0);

      setDropdownPageNumbers('organizationTotalPageNumber', totalPages);

      setOrganizationOptionList(organizationList);

      setDropdownSearchQuery('organizationSearchQuery', searchQuery);

      return organizationList;
    } else {
      return [];
    }
  };

  onOrganizationListScroll = async (querySearch: string) => {
    const { dropdownPageNumbers } = this.createObservationStore;

    const { content } = await this.createObservationService.getOrganizations(
      querySearch,
      dropdownPageNumbers.organizationCurrentPageNumber
    );

    return createOrganizationSelectOptionList(content);
  };

  usersSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { organizationId } = this.createObservationStore;

    const { content, totalPages } = await this.createObservationService.getAllUsers(
      searchQuery,
      organizationId,
      0
    );

    if (_.isArray(content)) {
      const { setAssigneeOptionList, setDropdownPageNumbers, setDropdownSearchQuery } =
        this.createObservationStore;

      const userList = createOptionListFromUsers(content);

      setDropdownPageNumbers('assigneeCurrentPageNumber', 0);

      setDropdownPageNumbers('assigneeTotalPageNumber', totalPages);

      setAssigneeOptionList(userList);

      setDropdownSearchQuery('assigneeSearchQuery', searchQuery);

      return userList;
    } else {
      return [];
    }
  };

  onAssigneeListScroll = async (querySearch: string) => {
    const { dropdownPageNumbers, organizationId } = this.createObservationStore;

    const { content } = await this.createObservationService.getAllUsers(
      querySearch,
      organizationId,
      dropdownPageNumbers.assigneeCurrentPageNumber
    );

    return createOptionListFromUsers(content);
  };

  experimentStepsSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { experimentId } = this.createObservationStore;

    const { content, totalPages } = await this.createObservationService.getExperimentStepFullList(
      experimentId,
      searchQuery,
      0
    );

    if (_.isArray(content)) {
      const { setExperimentStepOptionList, setDropdownPageNumbers, setDropdownSearchQuery } =
        this.createObservationStore;

      const experimentStepList = createExpStepSelectOptionList(content, {
        isAddInitialModel: true,
      });

      setDropdownPageNumbers('experimentStepCurrentPageNumber', 0);

      setDropdownPageNumbers('experimentStepTotalPageNumber', totalPages);

      setExperimentStepOptionList(experimentStepList);

      setDropdownSearchQuery('experimentStepSearchQuery', searchQuery);

      return experimentStepList;
    } else {
      return [];
    }
  };

  onExperimentStepListScroll = async (querySearch: string) => {
    const { dropdownPageNumbers, experimentId } = this.createObservationStore;

    const { content } = await this.createObservationService.getExperimentStepFullList(
      experimentId,
      querySearch,
      dropdownPageNumbers.experimentStepCurrentPageNumber
    );

    return createExpStepSelectOptionList(content, { isAddInitialModel: true });
  };

  operationTypesSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { content, totalPages } = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'techOperationType',
        latestVersion: true,
        fetchAttributes: true,
        nameFilter: searchQuery,
        attrs: {
          forUH: true,
        },
      },
      {
        sort: 'name,asc',
        size: 20,
      }
    );

    if (_.isArray(content)) {
      const { setOperationOptionList, setDropdownPageNumbers, setDropdownSearchQuery } =
        this.createObservationStore;

      const operationOptionList = createDictionaryEntitySelectOptionList(content);
      setOperationOptionList(operationOptionList);

      setDropdownPageNumbers('techOperationCurrentPageNumber', 0);
      setDropdownPageNumbers('techOperationTotalPageNumber', totalPages);
      setDropdownSearchQuery('techOperationSearchQuery', searchQuery);

      return operationOptionList;
    } else {
      return [];
    }
  };

  onTechOperationListScroll = async (querySearch: string) => {
    const { dropdownPageNumbers } = this.createObservationStore;

    const { content } = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'techOperationType',
        latestVersion: true,
        fetchAttributes: true,
        nameFilter: querySearch,
        attrs: {
          forUH: true,
        },
      },
      {
        sort: 'name,asc',
        size: 20,
        page: dropdownPageNumbers.techOperationCurrentPageNumber,
      }
    );

    return createDictionaryEntitySelectOptionList(content);
  };

  experimentSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { setExperimentOptionList, setDropdownPageNumbers, setDropdownSearchQuery } =
      this.createObservationStore;

    const { content, totalPages } = await this.createObservationService.getExpiriments(searchQuery);

    if (_.isArray(content)) {
      const experimentList = createExpirimentOptionList(content);

      setDropdownPageNumbers('experimentCurrentPageNumber', 0);

      setDropdownPageNumbers('experimentTotalPageNumber', totalPages);

      setExperimentOptionList(experimentList);

      setDropdownSearchQuery('experimentSearchQuery', searchQuery);

      return experimentList;
    } else {
      return [];
    }
  };

  onExperimentListScroll = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { dropdownPageNumbers } = this.createObservationStore;

    const { content } = await this.createObservationService.getExpiriments(
      searchQuery,
      dropdownPageNumbers.experimentCurrentPageNumber
    );

    return createExpirimentOptionList(content);
  };

  changeDropdownPageNumber = (dropdownName: string): void => {
    const { setDropdownPageNumbers, dropdownPageNumbers } = this.createObservationStore;

    setDropdownPageNumbers(dropdownName, dropdownPageNumbers[dropdownName] + 1);
  };
}

export default CreateObservationController;
