import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ $isDisabled: boolean }>`
  overflow-x: hidden;
  text-overflow: ellipsis;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5;
    `}
`;

const StyledZoneListCellWithDisabled = {
  Wrapper,
};

export default StyledZoneListCellWithDisabled;
