import styled, { css } from 'styled-components';

const Title = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  font-style: normal;
  letter-spacing: -0.5px;

  color: #151f32;
`;

const Header = styled.div<{ $isContentHiddenByScroll: boolean }>`
  width: 100%;

  padding-bottom: 12px;

  ${({ $isContentHiddenByScroll }) =>
    !$isContentHiddenByScroll &&
    css`
      border-bottom: 1px solid #e9e6f0;
    `};
`;

const contentLinkStyles = css`
  & a {
    color: #3fb65f;
    cursor: pointer;

    transition: 0.15s ease-in-out;

    &:hover {
      color: #259a42;
    }
  }
`;

const contentSubTitleStyles = css`
  & h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.15px;

    color: #151f32;
  }
`;

const contentParagraphStyles = css`
  & p {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.1px;

    color: #151f32;
  }
`;

const Content = styled.div`
  margin-top: 12px;

  max-height: 60vh;

  ${contentLinkStyles};
  ${contentSubTitleStyles};
  ${contentParagraphStyles};
`;

const Footer = styled.div`
  padding-top: 24px;
`;

const Wrapper = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;

  // Убирает правый отступ у скроллбара.
  & .scrollbar {
    right: 0;
  }
`;

const StyledContextualHelpModal = {
  Title,
  Header,
  Content,
  Footer,
  Wrapper,
};

export default StyledContextualHelpModal;
