import { FC, memo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../utils/helpers/mobx';
import { DynamicTableStore } from '../../../mobx/stores';
import { ComparisonTableImagesAttribute as ImagesAttribute } from '../../../components/attributes';

interface IProps {
  tableId: string;
  attributeId: string;
}

const ComparisonTableImagesAttributeContainer: FC<IProps> = ({ tableId, attributeId }) => {
  const { getAttribute } = useStore(DynamicTableStore);

  const extendedAttribute = getAttribute(tableId, attributeId);

  return (
    <>{extendedAttribute ? <ImagesAttribute extendedAttribute={extendedAttribute} /> : null}</>
  );
};

ComparisonTableImagesAttributeContainer.displayName = 'ComparisonTableImagesAttributeContainer';

export default memo(observer(ComparisonTableImagesAttributeContainer));
