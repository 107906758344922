import { FC } from 'react';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';

import Styled from './TotalComparisonComparableCell.styles';

interface IProps {
  factValue: string;
  planValue: string;
  dataTestId: string;
  isBold?: boolean;
}

const TotalComparisonComparableCell: FC<IProps> = ({
  factValue,
  planValue,
  dataTestId,
  isBold,
}) => {
  const { ref: factValueRef, isShowTooltip: isShowFactValueTooltip } =
    useShowTooltip<HTMLDivElement>();

  const { ref: planValueRef, isShowTooltip: isShowPlanValueTooltip } =
    useShowTooltip<HTMLDivElement>();

  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <AutoTooltip content={factValue} position={'bottom'} disabled={!isShowFactValueTooltip}>
        <Styled.FactLabel ref={factValueRef} $isBold={isBold} {...getDataTestId('fact-value')}>
          {factValue}
        </Styled.FactLabel>
      </AutoTooltip>

      <AutoTooltip content={planValue} position={'bottom'} disabled={!isShowPlanValueTooltip}>
        <Styled.PlanLabel ref={planValueRef} $isBold={isBold} {...getDataTestId('plan-value')}>
          ({planValue})
        </Styled.PlanLabel>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

TotalComparisonComparableCell.displayName = 'TotalComparisonComparableCell';

export default TotalComparisonComparableCell;
