import { makeAutoObservable } from 'mobx';

import { IReference } from '../../../../../../../api/models/as-fields/references/Reference.model';
import { provide } from '../../../../../utils/helpers/mobx';

@provide.singleton()
class ContextualHelpStore {
  private _helpsByPathByParentPath: Map<string, Map<string, IReference>> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  getHelp = (parentPath: string, path: string): IReference => {
    return this._helpsByPathByParentPath.get(parentPath)?.get?.(path);
  };

  setHelpListByParentPath = (parentPath: string, contextualHelpList: IReference[]): void => {
    const collection = this._helpsByPathByParentPath.get(parentPath);
    const previousEntryList = [...(collection?.entries?.() || [])];

    const newCollection = new Map<string, IReference>(previousEntryList);
    contextualHelpList.forEach(help => newCollection.set(help.path, help));

    this._helpsByPathByParentPath.set(parentPath, newCollection);
  };

  deleteHelpsByParentPath = (parentPath: string): void => {
    this._helpsByPathByParentPath.delete(parentPath);
  };
}

export default ContextualHelpStore;
