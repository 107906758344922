import { useRef } from 'react';
import { ENotificationType, ENotificatorTheme, useNotificator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { Plug } from '../../../../../../../../../common/components/ui';
import { useFileUpload } from '../../../../../../../../../common/hooks/useFileUpload';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { DocumentsController } from '../../mobx/controllers';
import iconFolder from '../../assets/icons/folder.svg';

import Styled from './DocumentsNoDataPlug.styles';

export const DocumentsNoDataPlug = observer(() => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { handleFileChange } = useFileUpload(inputRef, { maxSize: 1e8 });

  const { addExperimentFile } = useStore(DocumentsController);

  const { setNotification } = useNotificator();

  const addFileToExperiment = (fileId: string) => {
    addExperimentFile(fileId);
  };

  const uploadNewDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFileChange(e)
      .then(file => {
        addFileToExperiment(file.id);
        setNotification({
          message: 'Документ успешно загружен',
          style: {
            placement: 'top-center',
            type: ENotificationType.Success,
            theme: ENotificatorTheme.Dark,
          },
        });
      })
      .catch((err: Error) => {
        setNotification({
          message: err.message,
          style: {
            placement: 'top-center',
            type: ENotificationType.Warning,
            theme: ENotificatorTheme.Dark,
          },
        });
      });
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  return (
    <Styled.Wrapper>
      <Plug
        icon={iconFolder}
        title="Пока нет документов по опыту"
        primaryButtonTitle="Загрузить документы"
        onPrimaryButtonClick={handleUploadClick}
        buttonExist
      />
      <Styled.FileInput type="file" ref={inputRef} onChange={uploadNewDocument} />
    </Styled.Wrapper>
  );
});
