import L from 'leaflet';
import { point as getTurfPoint, booleanPointInPolygon as checkIfPointInPolygon } from '@turf/turf';
import _ from 'lodash';

import { lazyInject, provide } from '../../../../../utils/helpers/mobx';
import { MapStore } from '../../stores';

@provide.singleton()
class MapController {
  @lazyInject(MapStore)
  protected mapStore: MapStore;

  initInstance = (mapKey: string) => {
    const { setInstance } = this.mapStore;

    const map = L.map(mapKey, {
      center: [55.751244, 37.618423],
      zoom: 19,
      zoomControl: true,
      minZoom: 3,
      maxZoom: 20,
    });

    map.on('click', this.handleMapClick);

    setInstance(map);
  };

  restoreStyleToPrevSelectedPolygon = (): void => {
    const { prevSelectedPolygonData } = this.mapStore;

    if (!prevSelectedPolygonData) {
      return;
    }

    const { polygon, style } = prevSelectedPolygonData;

    polygon.setStyle(style);
  };

  selectPolygon = (polyId: number): void => {
    const { prevSelectedPolygonData, getPolygon, setPrevSelectedPolygonData } = this.mapStore;

    const polygon = getPolygon(polyId);

    if (!polygon) {
      return;
    }

    if (polyId !== prevSelectedPolygonData?.id) {
      /**
       * При клике на другой полигон, когда есть уже выбранный, то приводим к "дефолтному
       * состоянию" предыдущий. В противном случае он также останется "выбранным".
       */
      this.restoreStyleToPrevSelectedPolygon();

      setPrevSelectedPolygonData({ id: polyId, polygon, style: _.cloneDeep(polygon.options) });

      polygon.setStyle({
        fillOpacity: 0,
        color: '#ffffff',
      });
    }

    this.centerMapToPoint(polyId);
  };

  handleSelectPolygon = (event: L.LeafletMouseEvent): void => {
    const polyId: number = event.target._leaflet_id;

    this.selectPolygon(polyId);
  };

  handleMapClick = (event: L.LeafletMouseEvent): void => {
    const { prevSelectedPolygonData, clearPrevSelectedPolygonData } = this.mapStore;

    if (!prevSelectedPolygonData) {
      return;
    }

    const point = getTurfPoint([event.latlng.lng, event.latlng.lat]);
    const feature = prevSelectedPolygonData.polygon.toGeoJSON();

    const isPointInPolygon = checkIfPointInPolygon(point, feature);

    if (!isPointInPolygon) {
      this.restoreStyleToPrevSelectedPolygon();
      clearPrevSelectedPolygonData();
    }
  };

  createPolygon: ICreatePolygon = (coordinates, style, tooltip, options) => {
    const { instance } = this.mapStore;

    if (!coordinates) {
      return;
    }

    const points = coordinates[0][0][0]
      ? coordinates.map(feature => feature.map(dots => [dots[1], dots[0]]))
      : [coordinates.map(dots => [dots[1], dots[0]])];

    const polygon = new L.Polygon(points);

    if (options?.isAllowToSelectPolygon) {
      polygon.on('click', this.handleSelectPolygon);
    }

    polygon.setStyle(style);

    if (tooltip) {
      polygon.bindTooltip(tooltip.content, tooltip?.options).openTooltip();
    }

    polygon.addTo(instance);

    return polygon;
  };

  changePolygonStyle: IChangePolygonStyle = (polyId, style) => {
    const { prevSelectedPolygonData, getPolygon, setPrevSelectedPolygonData } = this.mapStore;

    const polygon = getPolygon(polyId);

    if (!polygon) {
      return;
    }

    if (polyId === prevSelectedPolygonData?.id) {
      setPrevSelectedPolygonData({ ...prevSelectedPolygonData, style });

      return;
    }

    polygon.setStyle(style);
  };

  changePolygonTooltip: IChangePolygonTooltip = (polyId, tooltip) => {
    const { getPolygon } = this.mapStore;

    const polygon = getPolygon(polyId);

    if (!polygon) {
      return;
    }

    polygon.unbindTooltip();
    polygon.bindTooltip(tooltip.content, tooltip.options);
  };

  setPolygonList = (polygonList: L.Polygon[]): void => {
    const { setIdToPolygon } = this.mapStore;

    setIdToPolygon(polygonList);

    if (polygonList.length) {
      // @ts-ignore
      this.centerMapToPoint(polygonList[0]._leaflet_id);
    }
  };

  centerMapToPoint = (polyId: number): void => {
    const { instance, getPolygon } = this.mapStore;

    const polygon = getPolygon(polyId);

    if (!polygon) {
      return;
    }

    const polygonBounds = polygon.getBounds();

    instance.fitBounds(polygonBounds);
  };

  clearPolygonList = (): void => {
    const { polygonList } = this.mapStore;

    polygonList.forEach(polygon => {
      polygon.unbindTooltip();
      polygon.remove();
    });
  };

  clearStore = (): void => {
    const { clearPrevSelectedPolygonData, clearIdToPolygon } = this.mapStore;

    this.clearPolygonList();

    clearPrevSelectedPolygonData();
    clearIdToPolygon();
  };
}

interface IPolygonTooltip {
  content: L.Content | ((layer: L.Layer) => L.Content) | L.Tooltip;
  options?: L.TooltipOptions;
}

interface ICreatePolygonOptions {
  isAllowToSelectPolygon?: boolean;
}

interface ICreatePolygon {
  (
    coordinates: number[][] | number[][][],
    style?: L.PathOptions,
    tooltip?: IPolygonTooltip,
    options?: ICreatePolygonOptions
  ): L.Polygon;
}

interface IChangePolygonStyle {
  (polyId: number, style: L.PathOptions): void;
}

interface IChangePolygonTooltip {
  (polyId: number, tooltip: IPolygonTooltip): void;
}

export default MapController;
