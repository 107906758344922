import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { IAttachedFile } from '../../../../../../../../../../../api/models/as-fields/experiments';
import { TableBuilderController } from '../../../../../../../../../../common/features/TableBuilder/mobx/controllers';
import { DocumentsConfigsService } from '../../services/DocumentsConfigService';
import { DocumentsService } from '../../services/DocumentsService';
import { DocumentsStore } from '../../stores';
import { ExperimentSubsectionsStore } from '../../../../../mobx/stores';

@provide.singleton()
export class DocumentsController {
  @lazyInject(DocumentsConfigsService)
  protected configsDocumentsService: DocumentsConfigsService;

  @lazyInject(DocumentsService)
  protected documentsService: DocumentsService;

  @lazyInject(DocumentsStore)
  protected documentsStore: DocumentsStore;

  @lazyInject(ExperimentSubsectionsStore)
  protected experimentSubsectionsStore: ExperimentSubsectionsStore;

  @lazyInject(TableBuilderController)
  protected tableBuilderController: TableBuilderController;

  constructor() {
    makeAutoObservable(this);
  }

  initiateTable = (onClickDownloadFile, onClickDeleteFile): void => {
    const config = this.configsDocumentsService.createConfig(
      onClickDownloadFile,
      onClickDeleteFile
    );

    this.tableBuilderController.initiateTable(config);
  };

  addExperimentFile = async (fileId: string) => {
    const filesToUpdate = [...this.documentsStore.attachedFiles.map(file => file.id), fileId];

    const experiment = await this.documentsService.updateExperimentAttacherFiles(
      this.experimentSubsectionsStore.experiment?.id,
      filesToUpdate
    );

    this.documentsStore.setAttachedFiles(experiment?.attachedFiles);
  };

  deleteExperimentFile = async (fileId: string) => {
    const filesToUpdate = this.documentsStore.attachedFiles
      .map(file => file.id)
      .filter(file => file !== fileId);

    const experiment = await this.documentsService.updateExperimentAttacherFiles(
      this.experimentSubsectionsStore.experiment?.id,
      filesToUpdate
    );

    this.documentsStore.setAttachedFiles(experiment?.attachedFiles);
  };

  downloadFile = (file: IAttachedFile) => {
    this.documentsService.downloadFile(file);
  };
}
