import {
  ActionCellComponent,
  DosageValueCellComponent,
  InventoryValueCellComponent,
} from '../../../../../../../../../../../../common/components/ActiveSubstancesTable/cells';
import {
  ITableBuilderCellConfig as ICellConfig,
  ITableBuilderColumnConfig as IColumnConfig,
  ITableBuilderRowsGroupConfig as IRowsGroupConfig,
  ITableBuilderConfig as IBuilderConfig,
} from '../../../../../../../../../../../../common/features/TableBuilder/models/configs';
import { provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { EExperimentsTableBuilderId } from '../../../../../../../../../constants/configs';

@provide.transient()
class CalculationTechOperationConfigsService {
  createConfig = (
    onClickAddTechoperation,
    onClickDeleteTechoperation,
    onClickEditTechoperation,
    onClickDeleteInventoryValue,
    onClickEditInventoryValue,
    isViewOnly
  ): IBuilderConfig => {
    const columnList = this.createColumnList();
    const cellList = this.createCellList(
      onClickAddTechoperation,
      onClickDeleteTechoperation,
      onClickEditTechoperation,
      onClickDeleteInventoryValue,
      onClickEditInventoryValue,
      isViewOnly
    );
    const rowsGroupList = this.createRowsGroupList();

    return {
      id: EExperimentsTableBuilderId.CalculationTechOperations,
      columnConfigList: columnList,
      cellConfigList: cellList,
      rowsGroupConfigList: rowsGroupList,
    };
  };

  createColumnList = (): IColumnConfig[] => {
    const number = this.createNumberColumn();
    const date = this.createDateColumn();
    const operation = this.createOperationColumn();
    const phenophase = this.createPhenophaseColumn();
    const bbch = this.createBBCHColumn();
    const method = this.createMethodColumn();
    const inventoryValue = this.createInventoryValueColumn();
    const dosage = this.createDosageColumn();
    const actions = this.createActionsColumn();

    return [number, date, operation, phenophase, bbch, method, inventoryValue, dosage, actions];
  };

  createNumberColumn = (): IColumnConfig => {
    return {
      id: 'number',
      name: '№',
      width: {
        default: '32px',
      },
    };
  };

  createDateColumn = (): IColumnConfig => {
    return {
      id: 'date',
      name: 'Дата',
      width: {
        default: '90px',
      },
    };
  };

  createOperationColumn = (): IColumnConfig => {
    return {
      id: 'name',
      name: 'Техоперация',
      width: {
        default: '140px',
      },
    };
  };

  createPhenophaseColumn = (): IColumnConfig => {
    return {
      id: 'phenophase',
      name: 'Фаза внесения',
      width: {
        default: '135px',
      },
    };
  };

  createBBCHColumn = (): IColumnConfig => {
    return {
      id: 'bbch',
      name: 'BBCH',
      width: {
        default: '54px',
      },
    };
  };

  createMethodColumn = (): IColumnConfig => {
    return {
      id: 'usageMethodTypeName',
      name: 'Способ',
      width: {
        default: '135px',
      },
    };
  };

  createInventoryValueColumn = (): IColumnConfig => {
    return {
      id: 'inventoryValue',
      name: 'ТМЦ',
      width: {
        default: '137px',
      },
    };
  };

  createDosageColumn = (): IColumnConfig => {
    return {
      id: 'dosage',
      name: 'Дозировка',
      width: {
        default: '88px',
      },
    };
  };

  createActionsColumn = (): IColumnConfig => {
    return {
      id: 'actions',
      name: '',
      width: {
        default: '1fr',
      },
    };
  };

  createCellList = (
    onClickAddTechoperation,
    onClickDeleteTechoperation,
    onClickEditTechoperation,
    onClickDeleteInventoryValue,
    onClickEditInventoryValue,
    isViewOnly
  ): ICellConfig[] => {
    const number = this.createNumberCell();
    const date = this.createDateCell();
    const phenophase = this.createPhenophaseCell();
    const bbch = this.createBBCHCell();
    const method = this.createMethodCell();
    const operation = this.createOperationCell();
    const inventoryValue = this.createInventoryValueCell(onClickAddTechoperation, isViewOnly);
    const dosage = this.createDosageCell();
    const actions = this.createActionsCell(
      onClickDeleteTechoperation,
      onClickEditTechoperation,
      onClickDeleteInventoryValue,
      onClickEditInventoryValue,
      isViewOnly
    );

    return [number, date, operation, phenophase, bbch, method, inventoryValue, dosage, actions];
  };

  createNumberCell = (): ICellConfig => {
    return {
      id: 'number',
      autoRenderConfig: {
        renderValue: row => row.number,
        alighnItems: 'top',
        isTitle: true,
      },
    };
  };

  createDateCell = (): ICellConfig => {
    return {
      id: 'date',
      autoRenderConfig: {
        renderValue: row => row.date,
        alighnItems: 'top',
        isTitle: true,
      },
    };
  };

  createPhenophaseCell = (): ICellConfig => {
    return {
      id: 'phenophase',
      autoRenderConfig: {
        renderValue: row => row.phenophase,
        alighnItems: 'top',
        isTitle: true,
      },
    };
  };

  createOperationCell = (): ICellConfig => {
    return {
      id: 'name',
      autoRenderConfig: {
        renderValue: row => row.name,
        alighnItems: 'top',
        height: 40,
        isTitle: true,
        isWrap: true,
      },
    };
  };

  createBBCHCell = (): ICellConfig => {
    return {
      id: 'bbch',
      autoRenderConfig: {
        renderValue: row => row.bbch,
        alighnItems: 'top',
        isTitle: true,
      },
    };
  };

  createMethodCell = (): ICellConfig => {
    return {
      id: 'usageMethodTypeName',
      autoRenderConfig: {
        renderValue: row => row?.usageMethodTypeName || '—',
        alighnItems: 'top',
        isTitle: true,
      },
    };
  };

  createInventoryValueCell = (onClickAddTechoperation, isViewOnly): ICellConfig => {
    return {
      id: 'inventoryValue',
      customRenderConfig: {
        renderCell: row => (
          <InventoryValueCellComponent
            row={row}
            onClickAddTechoperation={onClickAddTechoperation}
            isViewOnly={isViewOnly}
          />
        ),
      },
    };
  };

  createDosageCell = (): ICellConfig => {
    return {
      id: 'dosage',
      customRenderConfig: {
        renderCell: row => <DosageValueCellComponent row={row} />,
      },
    };
  };

  createActionsCell = (
    onClickDeleteTechoperation,
    onClickEditTechoperation,
    onClickDeleteInventoryValue,
    onClickEditInventoryValue,
    isViewOnly
  ): ICellConfig => {
    return {
      id: 'actions',
      customRenderConfig: {
        renderCell: row =>
          isViewOnly ? (
            <></>
          ) : (
            <ActionCellComponent
              row={row}
              onClickDeleteTechoperation={onClickDeleteTechoperation}
              onClickEditTechoperation={onClickEditTechoperation}
              onClickDeleteInventoryValue={onClickDeleteInventoryValue}
              onClickEditInventoryValue={onClickEditInventoryValue}
            />
          ),
      },
    };
  };

  createRowsGroupList = (): IRowsGroupConfig[] => {
    return [
      {
        id: EExperimentsTableBuilderId.CalculationTechOperations,
        rowConfigList: [],
        customRenderConfig: {
          render: (_, rowsChildren) => <>{rowsChildren}</>,
        },
      },
    ];
  };
}

export default CalculationTechOperationConfigsService;
