import { Switcher, useModal } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../../../../../common/utils/helpers/mobx';
import { ZonesStore } from '../../../../mobx/stores';
import { ZonesController } from '../../../../mobx/controllers';
import { useMap } from '../../../../../../../../../../../../../common/features/experimentsMap/hooks';
import { EExpZoneStyle } from '../../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { zonesModalConfig } from '../../../../config/modals';

export const ZoneListSwitcherCell = ({ rowData: { type, name, fwExpCultureZone } }) => {
  const { setEditableZoneId, setTableHasChanged } = useStore(ZonesStore);
  const { changeZoneType } = useStore(ZonesController);

  const { changeCultureZoneStyle } = useMap();
  const { openModalByModalId } = useModal();

  const handleChange = (newValue: boolean): void => {
    setTableHasChanged(true);
    changeZoneType(
      {
        ...fwExpCultureZone,
        isSelected: newValue,
        styleType: (!newValue ? EExpZoneStyle.ReadyToExperiment : type) as any,
      },
      changeCultureZoneStyle
    );

    if (name === 'Безымянный' && newValue) {
      setEditableZoneId(fwExpCultureZone.cultureZoneId);
      openModalByModalId(zonesModalConfig.name.changeCultureZoneName);
    }
  };

  return <Switcher value={fwExpCultureZone.isSelected} onChange={handleChange} />;
};
