import { FC, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

import { useStore } from '../../../../../utils/helpers/mobx';
import { useFocusTarget } from '../../../../../utils/hooks';
import { FormElementContainer } from '../../../containers/elements';
import { FormController } from '../../../mobx/controllers';
import { FormStore } from '../../../mobx/stores';
import { IFormConfig, IFormElement, TFormValue } from '../../../models';
import { UniFormHelpers } from '../../helpers';
import { ISelectOption } from '../../../../../components/form/Dropdown/Dropdown.types';
import { IDatePickerOptions, IPaginationConfig } from '../../../models/FormConfig/Form.model';

interface IRegisterForm<F extends Record<keyof F, TFormValue>> {
  (formConfig: IFormConfig<F>): void;
}

interface IGetElement<F extends Record<keyof F, TFormValue>> {
  (elementName: keyof F): IFormElement<F>;
}

interface IHandleSubmitForm<F extends Record<keyof F, TFormValue>> {
  (handler: (form: F) => Promise<any>): Promise<void>;
}

interface IHandleChangeElementData<F extends Record<keyof F, TFormValue>> {
  (elementName: keyof F, partialData: Partial<IFormElement<F>>): void;
}

interface IHandleSetElementErrorShow<F extends Record<keyof F, TFormValue>> {
  (elementName: keyof F, isShowError: boolean, errorMessage?: string): void;
}

interface IHandleAddingOptionList<F extends Record<keyof F, TFormValue>> {
  (elementName: keyof F, optionList: ISelectOption[]): void;
}

interface IHandleElementFocus<F extends Record<keyof F, TFormValue>> {
  (elementName: keyof F): void;
}

interface IHandleChangeListOfFormValue<F extends Record<keyof F, TFormValue>> {
  (partialData: Partial<F>): void;
}

interface IHandleAddingSearchQueryHandler<F extends Record<keyof F, TFormValue>> {
  (elementName: keyof F, handler: (v: string) => Promise<ISelectOption[]>): void;
}

interface IHandleAddingDatePickerParams<F extends Record<keyof F, TFormValue>> {
  (elementName: keyof F, params: IDatePickerOptions): void;
}

interface IHandleAddingPaginationConfig<F extends Record<keyof F, TFormValue>> {
  (elementName: keyof F, paginationConfig: IPaginationConfig): void;
}

interface IHandleBlockElementParams<F extends Record<keyof F, TFormValue>> {
  (elementName: keyof F, isBlocked: boolean): void;
}

interface IHandleFirstElementIdWithError<F extends Record<keyof F, TFormValue>> {
  (): string;
}

const createElements = <F extends Record<keyof F, TFormValue>, E extends Record<keyof F, FC>>(
  formConfig: IFormConfig<F>,
  elementRef: MutableRefObject<HTMLInputElement>
): E => {
  const elementNameList = Object.keys(formConfig.elements);

  return elementNameList.reduce<E>((elements, name) => {
    const { createElementId } = UniFormHelpers;

    const elementId = createElementId(formConfig.formKey, name);

    elements[name] = () => (
      <FormElementContainer
        ref={elementRef}
        formKey={formConfig.formKey}
        elementName={name}
        id={elementId}
        type={formConfig.elements[name]?.type}
      />
    );

    return elements;
  }, {} as E);
};

const useForm = <
  F extends Record<keyof F, TFormValue> = any,
  E extends Record<keyof F, FC> = Record<keyof F, FC>
>(
  selectedFormKey?: string,
  options?: {
    isDoNotClearForm?: boolean;
  }
): {
  elements: E;
  getElement: IGetElement<F>;
  formData: F;
  initialFormData: F;
  isSubmitDisabled: boolean;
  isFormChanged: boolean;
  registerForm: IRegisterForm<F>;
  changeElementData: IHandleChangeElementData<F>;
  changeListOfFormValue: IHandleChangeListOfFormValue<F>;
  addSearchQueryHandler: IHandleAddingSearchQueryHandler<F>;
  addOptionList: IHandleAddingOptionList<F>;
  addDatePickerParams: IHandleAddingDatePickerParams<F>;
  addPaginationConfig: IHandleAddingPaginationConfig<F>;
  blockElement: IHandleBlockElementParams<F>;
  submitForm: IHandleSubmitForm<F>;
  setElementErrorShow: IHandleSetElementErrorShow<F>;
  changeInitialFormValue: IHandleChangeListOfFormValue<F>;
  focusElement: IHandleElementFocus<F>;
  getFirstElementIdWithError: IHandleFirstElementIdWithError<F>;
} => {
  const formStore = useStore(FormStore);
  const {
    focusedElementName,
    isElementFocused,
    getForm,
    getElement,
    getInitialForm,
    hasInvalidElements,
    setIsElementFocused,
    getIsFormChanged,
  } = formStore;

  const formController = useStore(FormController);
  const {
    registerForm,
    submitForm,
    changeElementData,
    addOptionList,
    addSearchQueryHandler,
    changeListOfFormValue,
    clearForm,
    addDatePickerParams,
    setElementErrorShow,
    blockElement,
    changeInitialFormValue,
    focusElement,
    addPaginationConfig,
    getFirstElementIdWithError,
  } = formController;

  const [formKey, setFormKey] = useState<string>(selectedFormKey || '');
  const [elements, setElements] = useState<E>({} as E);

  const elementId = UniFormHelpers.createElementId<F>(formKey, focusedElementName);
  const elementRef = useRef<HTMLInputElement>(null);

  useFocusTarget({
    targetRef: elementRef,
    focusTargetId: elementId,
    isFocused: isElementFocused,
    setIsFocused: setIsElementFocused,
  });

  const handleRegisterForm = useCallback<IRegisterForm<F>>(formConfig => {
    registerForm(formConfig);

    setFormKey(formConfig.formKey);
    setElements(createElements<F, E>(formConfig, elementRef));
  }, []);

  const handleGetElement = useCallback<IGetElement<F>>(
    elementName => {
      if (!formKey) {
        return;
      }

      return getElement<F>(formKey, elementName);
    },
    [formKey]
  );

  const handleSubmitForm = useCallback<IHandleSubmitForm<F>>(
    async handler => {
      if (!formKey) {
        return;
      }

      await submitForm<F>(formKey, handler);
    },
    [formKey]
  );

  const handleChangeElementData = useCallback<IHandleChangeElementData<F>>(
    (elementName, partialData) => {
      if (!formKey) {
        return;
      }

      changeElementData<F>(formKey, elementName, partialData);
    },
    [formKey]
  );

  const handleSetElementErrorShow = useCallback<IHandleSetElementErrorShow<F>>(
    (elementName, isShowError, errorMessage) => {
      if (!formKey) {
        return;
      }

      setElementErrorShow<F>(formKey, elementName, isShowError, errorMessage);
    },
    [formKey]
  );

  const handleAddingOptionList = useCallback<IHandleAddingOptionList<F>>(
    (elementName, optionList) => {
      if (!formKey) {
        return;
      }

      addOptionList(formKey, elementName, optionList);
    },
    [formKey, addOptionList]
  );

  const handleAddDatePickerParams = useCallback<IHandleAddingDatePickerParams<F>>(
    (elementName, params) => {
      if (!formKey) {
        return;
      }

      addDatePickerParams(formKey, elementName, params);
    },
    [formKey, addDatePickerParams]
  );

  const handleAddPaginationConfig = useCallback<IHandleAddingPaginationConfig<F>>(
    (elementName, paginationConfig) => {
      if (!formKey) {
        return;
      }

      addPaginationConfig(formKey, elementName, paginationConfig);
    },
    [formKey, addPaginationConfig]
  );

  const handleBlockElement = useCallback<IHandleBlockElementParams<F>>(
    (elementName, isBlocked) => {
      if (!formKey) {
        return;
      }

      blockElement(formKey, elementName, isBlocked);
    },
    [formKey, blockElement]
  );

  const handleChangeListOfFormValue = useCallback<IHandleChangeListOfFormValue<F>>(
    partialData => {
      if (!formKey) {
        return;
      }

      changeListOfFormValue<F>(formKey, partialData);
    },
    [formKey]
  );

  const handleChangeListOfInitialFormValue = useCallback<IHandleChangeListOfFormValue<F>>(
    partialData => {
      if (!formKey) {
        return;
      }

      changeInitialFormValue<F>(formKey, partialData);
    },
    [formKey]
  );

  const handleAddingSearchQueryHandler = useCallback<IHandleAddingSearchQueryHandler<F>>(
    (elementName, handler) => {
      if (!formKey) {
        return;
      }

      addSearchQueryHandler<F>(formKey, elementName, handler);
    },
    [formKey]
  );

  const handleElementFocus = useCallback<IHandleElementFocus<F>>(
    elementName => {
      if (!formKey) {
        return;
      }

      focusElement<F>(elementName);
    },
    [formKey]
  );

  const isSubmitDisabled = hasInvalidElements(formKey);
  const formData = getForm<F>(formKey);
  const initialFormData = getInitialForm<F>(formKey);

  const isFormChanged = getIsFormChanged<F>(formKey);

  useEffect(() => {
    return () => {
      if (!options?.isDoNotClearForm && formKey) {
        clearForm(formKey);
      }
    };
  }, [formKey]);

  const handleFirstElementIdWithError = useCallback(() => {
    return getFirstElementIdWithError(formKey);
  }, [formKey]);

  return {
    elements,
    getElement: handleGetElement,
    formData,
    isSubmitDisabled,
    isFormChanged,
    initialFormData,
    registerForm: handleRegisterForm,
    changeElementData: handleChangeElementData,
    changeListOfFormValue: handleChangeListOfFormValue,
    addSearchQueryHandler: handleAddingSearchQueryHandler,
    addOptionList: handleAddingOptionList,
    addDatePickerParams: handleAddDatePickerParams,
    addPaginationConfig: handleAddPaginationConfig,
    submitForm: handleSubmitForm,
    setElementErrorShow: handleSetElementErrorShow,
    blockElement: handleBlockElement,
    changeInitialFormValue: handleChangeListOfInitialFormValue,
    focusElement: handleElementFocus,
    getFirstElementIdWithError: handleFirstElementIdWithError,
  };
};

export default useForm;
