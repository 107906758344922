import { AxiosService } from '../../../../../../../../common/mobx/services/axios/AxiosService';
import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';
import { IExperimentContract } from '../../../../../../../../../api/models/as-fields/experiments';
import {
  TCreateExperimentContractReq,
  TChangeExperimentContractReq,
  TChangeExperimentContractByIdRes,
} from '../../../../../../../../../api';
import { GlobalService } from '../../../../../../../../common/mobx/services/system';
import { IServiceConfig } from '../../../../../../../../common/models/service';
import { IOrganization } from '../../../../../../../../../api/models/da-profile/organizations/Organization.model';

@provide.singleton()
class CreateEditContractService {
  @lazyInject(AxiosService)
  axiosService: AxiosService;

  @lazyInject(GlobalService)
  globalService: GlobalService;

  getContract = async (id: string): Promise<IExperimentContract> => {
    const { getExperimentContract } = this.axiosService.api;

    try {
      const response = await getExperimentContract({ id });

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  createContract = async (
    payload: TCreateExperimentContractReq,
    config: IServiceConfig
  ): Promise<IExperimentContract> => {
    const { createExperimentContract } = this.axiosService.api;
    const { showErrorModalHandler } = this.globalService;
    const { success } = config.actions;

    try {
      const response = await createExperimentContract(payload);
      success();

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      showErrorModalHandler(e);
    }
  };

  changeContract = async (
    payload: TChangeExperimentContractReq,
    config: IServiceConfig
  ): Promise<IExperimentContract> => {
    const { changeExperimentContract } = this.axiosService.api;
    const { showErrorModalHandler } = this.globalService;
    const { success } = config.actions;

    try {
      const response = await changeExperimentContract(payload);
      success();

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      showErrorModalHandler(e);
    }
  };

  changeContractById = async (
    payload: { experimentId: string; contractId: string },
    config?: IServiceConfig
  ): Promise<TChangeExperimentContractByIdRes> => {
    const { changeExperimentContractById } = this.axiosService.api;
    const { showErrorModalHandler } = this.globalService;

    try {
      const response = await changeExperimentContractById(
        { experimentId: payload.experimentId },
        {
          query: {
            contractId: payload.contractId,
          },
        }
      );
      config?.actions?.success();

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      showErrorModalHandler(e);
    }
  };

  getOrganizationList = async (
    querySearch: string,
    page?: number
  ): Promise<{ content: IOrganization[]; totalPages: number }> => {
    const { getExperimentOrganizationList } = this.axiosService.api;

    try {
      const { content, totalPages } = await getExperimentOrganizationList({
        size: 20,
        nameOrInn: querySearch,
        page,
      });

      return { content, totalPages };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
}

export default CreateEditContractService;
