import { makeAutoObservable } from 'mobx';

import { provide, lazyInject } from '../../../../../../../../common/utils/helpers/mobx';
import { ExperimentSubsectionsService } from '../../services';
import { ExperimentSubsectionsStore } from '../../stores';
import { DocumentsStore } from '../../../modules/documents/mobx/stores';

@provide.singleton()
export class ExperimentSubsectionsController {
  @lazyInject(ExperimentSubsectionsService)
  protected experimentSubsectionsService: ExperimentSubsectionsService;

  @lazyInject(ExperimentSubsectionsStore)
  protected experimentSubsectionsStore: ExperimentSubsectionsStore;

  @lazyInject(DocumentsStore)
  documentsStore: DocumentsStore;

  constructor() {
    makeAutoObservable(this);
  }

  fetchExperiment = async (id: string) => {
    const experiment = await this.experimentSubsectionsService.fetchExperiment(id);

    this.experimentSubsectionsStore.setExperiment(experiment);

    this.documentsStore.setAttachedFiles(experiment.attachedFiles);
  };
}
