import _ from 'lodash';

import { lazyInject, provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { ZonesStore } from '../../stores';
import { ZonesListConfigService, ZonesService } from '../../services';
import {
  TGetCultureZoneExperimentListReq,
  TGetFieldReq,
} from '../../../../../../../../../../../../../api';
import {
  IChangeCultureZoneName,
  IChangeCultureZoneStyle,
  IDrawCultureZoneList,
} from '../../../../../../../../../../../../common/features/experimentsMap/hooks/useMap/useMap';
import { IShowErrorModal } from '../../../../../../../../../../../../common/utils/hooks/useErrorModal';
import { IChangeCultureZoneForm } from '../../../config/forms/changeCultureZoneName/changeCultureZoneName.form.config';
import {
  EExperimentCultureZoneType,
  EExpZoneStyle,
  IChangeExperimentCultureZone,
  IExperimentCultureZone,
  IFWExperimentCultureZone,
} from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { ICultureZone } from '../../../../../../../../../../../../../api/models/as-fields/fields/CultureZone';
import { IAddPreviousCultureForm } from '../../../config/forms/addPreviousCulture/addPreviousCulture';
import { DictionaryService } from '../../../../../../../../../../../../common/mobx/services/da-dictionary';
import { ISelectOption } from '../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { createCultureSelectOptionList } from '../../../../../../../../../../../../common/utils/helpers/selectOptions';
import { IField } from '../../../../../../../../../../../../../api/models/as-fields/fields/Field.model';
import { FieldsService } from '../../../../../../../../../../../../common/mobx/services/as-fields';
import CultureZoneExperimentService from '../../../../../../../../../../../../common/mobx/services/as-fields/CultureZoneExperiment/CultureZoneExperiment.service';
import { CreateExperimentStore } from '../../../../../../../mobx/stores';
import { TableBuilderController } from '../../../../../../../../../../../../common/features/TableBuilder/mobx/controllers';

@provide.singleton()
class ZonesController {
  @lazyInject(CreateExperimentStore)
  protected createExperimentStore: CreateExperimentStore;

  @lazyInject(ZonesStore)
  protected zonesStore: ZonesStore;

  @lazyInject(ZonesService)
  protected zonesService: ZonesService;

  @lazyInject(DictionaryService)
  protected dictionaryService: DictionaryService;

  @lazyInject(FieldsService)
  protected fieldsService: FieldsService;

  @lazyInject(CultureZoneExperimentService)
  protected cultureZoneExpService: CultureZoneExperimentService;

  @lazyInject(TableBuilderController)
  protected tableBuilderController: TableBuilderController;

  @lazyInject(ZonesListConfigService)
  protected zonesListConfigService: ZonesListConfigService;

  fetchField = async (payload: TGetFieldReq): Promise<IField> => {
    if (!payload?.id) {
      return;
    }

    const field = await this.fieldsService.getField(payload);

    return field;
  };

  culturesSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { getDictionaryEntityList } = this.dictionaryService;
    const {
      setCultureList,
      setPreviousCultureCurrentPage,
      setPreviousCultureTotalPages,
      setPreviousCultureOptionList,
      setPreviousCultureSearchQuery,
    } = this.zonesStore;

    const { content, totalPages } = await getDictionaryEntityList(
      {
        remoteName: 'culture',
        attrs: { useInAssistance: true },
        latestVersion: true,
        nameFilter: searchQuery,
      },
      { size: 20 }
    );

    if (_.isArray(content)) {
      setCultureList(content);

      const cultureSelectOptionList = createCultureSelectOptionList(content);

      /**
       * Изменение параметров для работы пагинации в дропдауне
       */
      setPreviousCultureCurrentPage(0);

      setPreviousCultureTotalPages(totalPages);

      setPreviousCultureOptionList(cultureSelectOptionList);

      setPreviousCultureSearchQuery(searchQuery);

      return cultureSelectOptionList;
    }
  };

  createFwExpZoneFromCZ = (zone: ICultureZone): IFWExperimentCultureZone => {
    const cultureList = zone.predecessorList.map(p => p.culture);
    const prevCultureSelectOptionList = createCultureSelectOptionList(cultureList);

    const fwExpZone: IFWExperimentCultureZone = {
      cultureZoneId: zone.id,
      name: zone?.name || 'Безымянный',
      polyId: null,
      cultureZone: zone,
      prevCultureSelectOptionList,
      type: zone?.experimentReady ? EExperimentCultureZoneType.Experiment : null,
      styleType: zone?.experimentReady ? EExpZoneStyle.ReadyToExperiment : EExpZoneStyle.Other,
      isSelected: false,
    };

    return fwExpZone;
  };

  createFwExpZoneFromExpZone = (expZone: IExperimentCultureZone): IFWExperimentCultureZone => {
    const prevCultureSelectOptionList = expZone.previousCultures.length
      ? createCultureSelectOptionList(expZone.previousCultures)
      : createCultureSelectOptionList(expZone.cultureZone.predecessorList.map(p => p.culture));

    const fwExpZone: IFWExperimentCultureZone = {
      cultureZoneId: expZone.cultureZone.id,
      name: expZone.cultureZone?.name || 'Безымянный',
      polyId: null,
      cultureZone: expZone.cultureZone,
      prevCultureSelectOptionList,
      type: expZone.type,
      styleType:
        expZone.type === EExperimentCultureZoneType.Control
          ? EExpZoneStyle.Control
          : EExpZoneStyle.Experiment,
      isSelected: true,
    };

    return fwExpZone;
  };

  allowCultureZonesDrawing = (drawCultureZoneList: IDrawCultureZoneList): void => {
    const {
      fwExpCultureZoneListWithoutPolyId,
      setIdToFwExpCultureZone,
      setIdToUnchangedFwExpCultureZone,
    } = this.zonesStore;

    const zoneListWithPolyId = drawCultureZoneList(fwExpCultureZoneListWithoutPolyId);

    /**
     * Сортирует участки, чтобы отображать сначала активные
     */
    const zoneListSortedByIsSelected = zoneListWithPolyId.sort(
      (zone1, zone2) => Number(zone2.isSelected) - Number(zone1.isSelected)
    );

    setIdToFwExpCultureZone(zoneListSortedByIsSelected);
    setIdToUnchangedFwExpCultureZone(zoneListSortedByIsSelected);
  };

  fetchCultureZoneList = async (payload: TGetCultureZoneExperimentListReq): Promise<void> => {
    const { setIsAllowMapAccess, setIdToFwExpCultureZoneWithoutPolyId } = this.zonesStore;
    const { getAllCultureZoneList } = this.zonesService;
    const { selectedExp } = this.createExperimentStore;

    if (!selectedExp) {
      return;
    }

    const { organization, seasonYear, culture } = selectedExp;

    const [fetchedExpZoneList, fetchedCultureZoneList] = await getAllCultureZoneList(payload, {
      organizationId: organization.id,
      seasonYear,
      withGeometry: true,
      size: 2000,
      cultureId: culture.id,
      onlyWithoutExperiment: true,
    });

    if (_.isArray(fetchedExpZoneList) && _.isArray(fetchedCultureZoneList)) {
      const idToFwCultureZone: Map<string, IFWExperimentCultureZone> = new Map<
        string,
        IFWExperimentCultureZone
      >();

      fetchedCultureZoneList.forEach(zone => {
        const fwExpZone = this.createFwExpZoneFromCZ(zone);

        idToFwCultureZone.set(fwExpZone.cultureZoneId, fwExpZone);
      });

      fetchedExpZoneList.forEach(expZone => {
        const fwExpZone = this.createFwExpZoneFromExpZone(expZone);

        idToFwCultureZone.set(fwExpZone.cultureZoneId, fwExpZone);
      });

      const fwCultureZoneList = Array.from(idToFwCultureZone.values());
      const hasZonesToWork = fwCultureZoneList.some(zone => zone.type);

      if (hasZonesToWork) {
        setIdToFwExpCultureZoneWithoutPolyId(fwCultureZoneList);
        setIsAllowMapAccess(true);
      }
    }

    const config = this.zonesListConfigService.createConfig();
    this.tableBuilderController.initiateTable(config);
  };

  changeZoneType = (
    fwZone: IFWExperimentCultureZone,
    changeCultureZoneStyle: IChangeCultureZoneStyle
  ): void => {
    const { setFwExpCultureZone } = this.zonesStore;

    setFwExpCultureZone(fwZone);

    changeCultureZoneStyle(fwZone);
  };

  saveExpZoneList = async (showErrorHandler?: IShowErrorModal): Promise<boolean> => {
    const { selectedExp } = this.createExperimentStore;
    const { fwExpCultureZoneList, clearIdToUnchangedFwExpCultureZone } = this.zonesStore;
    const { changeCultureZoneExperimentList } = this.cultureZoneExpService;

    clearIdToUnchangedFwExpCultureZone();

    const payload = fwExpCultureZoneList.reduce<IChangeExperimentCultureZone[]>(
      (zoneList, fwZone) => {
        if (fwZone.type && fwZone.isSelected) {
          const zone: IChangeExperimentCultureZone = {
            experimentId: selectedExp.id,
            type: fwZone.type,
            cultureZoneId: fwZone.cultureZoneId,
            previousCultureIds: fwZone.prevCultureSelectOptionList.map<string>(
              option => option.value as string
            ),
          };

          zoneList.push(zone);
        }

        return zoneList;
      },
      []
    );

    const savedZoneList = await changeCultureZoneExperimentList(
      selectedExp.id,
      { zoneList: payload },
      showErrorHandler
    ).then(() => {
      this.fetchCultureZoneList({ experimentId: selectedExp?.id });
    });

    if (_.isArray(savedZoneList)) {
      return true;
    }
  };

  changeCultureZoneName = async (
    formData: IChangeCultureZoneForm,
    closeModal: () => void,
    showErrorHandler: IShowErrorModal,
    changeCultureZoneNameHandler: IChangeCultureZoneName
  ): Promise<void> => {
    const { selectedExp } = this.createExperimentStore;
    const { editableZoneId, getZone, setFwExpCultureZone, clearEditableZoneId } = this.zonesStore;
    const { changeCultureZone } = this.zonesService;

    const zone = getZone(editableZoneId);

    const changedName = formData.name.trim();

    const isSuccess = await changeCultureZone(
      {
        id: zone.cultureZoneId,
        seasonYear: selectedExp.seasonYear,
        name: changedName,
      },
      showErrorHandler
    );

    if (isSuccess) {
      setFwExpCultureZone({
        ...zone,
        name: formData.name,
        cultureZone: { ...zone.cultureZone, name: changedName },
      });

      changeCultureZoneNameHandler?.(zone.polyId, changedName);

      clearEditableZoneId();
      closeModal?.();
    }
  };

  addPreviousCulture = (
    { previousCultureId }: IAddPreviousCultureForm,
    closeModal: () => void
  ): void => {
    const {
      editableZoneId,
      previousCultureOptionList,
      getZone,
      setFwExpCultureZone,
      clearEditableZoneId,
    } = this.zonesStore;

    const zone = getZone(editableZoneId);

    const previousCulture = previousCultureOptionList.find(
      ({ value }) => value === previousCultureId
    );

    setFwExpCultureZone({
      ...zone,
      prevCultureSelectOptionList: previousCultureId ? [previousCulture] : [],
    });

    clearEditableZoneId();
    closeModal?.();
  };

  clearStore = (): void => {
    const { clearStore } = this.zonesStore;

    clearStore();
  };

  changePreviousCulturePageNumber = (): void => {
    const { previousCultureCurrentPage, setPreviousCultureCurrentPage } = this.zonesStore;

    setPreviousCultureCurrentPage(previousCultureCurrentPage + 1);
  };

  onPreviousCultureListScroll = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { previousCultureCurrentPage } = this.zonesStore;
    const { getDictionaryEntityList } = this.dictionaryService;

    const { content } = await getDictionaryEntityList(
      {
        remoteName: 'culture',
        attrs: { useInAssistance: true },
        latestVersion: true,
        nameFilter: searchQuery,
      },
      { size: 20, page: previousCultureCurrentPage }
    );

    const cultureSelectOptionList = createCultureSelectOptionList(content);

    return cultureSelectOptionList;
  };
}

export default ZonesController;
