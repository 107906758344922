import { FC, memo } from 'react';

import { Icon } from '../../Icon';

import pinIconDefaultS32 from './assets/icons/pin-icon-default-32x32.svg';
import pinIconDefaultS32Hover from './assets/icons/pin-icon-default-hover-32x32.svg';
import pinIconSelectedS32 from './assets/icons/pin-icon-selected-32x32.svg';
import pinIconSelectedS32Hover from './assets/icons/pin-icon-selected-hover-32x32.svg';
import pinIconDefaultS24 from './assets/icons/pin-icon-default-24x24.png';
import pinIconDefaultS24Hover from './assets/icons/pin-icon-default-hover-24x24.png';
import pinIconSelectedS24 from './assets/icons/pin-icon-selected-24x24.png';
import pinIconSelectedS24Hover from './assets/icons/pin-icon-selected-hover-24x24.png';

const DEFAULT_DATA_TEST_ID = 'pin-button';

interface IProps {
  size: 's32' | 's24';
  isSelected?: boolean;
  onClick?: () => void;
  dataTestId?: string;
}

const PinButton: FC<IProps> = ({ isSelected, onClick, size, dataTestId }) => {
  return (
    <Icon
      states={{
        s40: null,
        s32: {
          default: pinIconDefaultS32,
          defaultHover: pinIconDefaultS32Hover,
          selected: pinIconSelectedS32,
          selectedHover: pinIconSelectedS32Hover,
        },
        s24: {
          default: pinIconDefaultS24,
          defaultHover: pinIconDefaultS24Hover,
          selected: pinIconSelectedS24,
          selectedHover: pinIconSelectedS24Hover,
        },
      }}
      size={size}
      isSelected={isSelected}
      dataTestId={dataTestId || DEFAULT_DATA_TEST_ID}
      onClick={onClick}
    />
  );
};

PinButton.displayName = 'PinButton';

export default memo(PinButton);
