import React, { FC, useMemo, memo } from 'react';
import { AutoTooltip, TCarouselItemProps } from '@farmlink/farmik-ui';

import { IExperimentCultureZone } from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { EExperimentCultureZoneType } from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { useShowTooltip } from '../../../../../../../../../../../common/utils/hooks';

import { controlSectionIcon, experimentSectionIcon } from './assets';
import Styled from './CarouselItem.styles';

interface IHistoryCarouselItemProps extends TCarouselItemProps {
  sections: IExperimentCultureZone[];
}

const mapIconBySectionType = {
  [EExperimentCultureZoneType.Control]: controlSectionIcon,
  [EExperimentCultureZoneType.Experiment]: experimentSectionIcon,
};

const HistoryCarouselItem: FC<IHistoryCarouselItemProps> = ({
  id,
  name,
  description,
  selected,
  handleSelectItem,
  sections,
}) => {
  const { ref, showTooltip } = useShowTooltip();

  const currentSection = useMemo(() => sections.find(section => section.id === id), [sections, id]);

  const sectionIcon = useMemo(() => mapIconBySectionType[currentSection.type], [currentSection]);

  const onClickItem = () => {
    handleSelectItem(id);
  };

  return (
    <Styled.Wrapper selected={selected} onClick={onClickItem}>
      <Styled.InfoContainer>
        <AutoTooltip content={name} position="top" disabled={!showTooltip}>
          <Styled.Title ref={ref}>{name}</Styled.Title>
        </AutoTooltip>
        <Styled.DescriptionContainer>
          <Styled.SectionIcon src={sectionIcon} />
          <Styled.Description>{description}</Styled.Description>
        </Styled.DescriptionContainer>
      </Styled.InfoContainer>
    </Styled.Wrapper>
  );
};

export default memo(HistoryCarouselItem);
