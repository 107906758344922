import { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { ZonesStore } from '../../mobx/stores';
import { TableBuilder } from '../../../../../../../../../../../common/features/TableBuilder';
import { EExperimentsTableBuilderId } from '../../../../../../../../constants/configs';
import { TableBuilderController } from '../../../../../../../../../../../common/features/TableBuilder/mobx/controllers';
import { useMap } from '../../../../../../../../../../../common/features/experimentsMap/hooks';

import Styled from './ZonesList.styles';
import { ZonesListTableHooks } from './utils/hooks';

const ZonesList: FC = () => {
  const { fwExpCultureZoneList } = useStore(ZonesStore);
  const tableBuilderController = useStore(TableBuilderController);

  const tableBodyData = ZonesListTableHooks.useFormatData(fwExpCultureZoneList);

  const { selectPolygon } = useMap();

  const handleRowClick = useCallback(row => {
    selectPolygon(row.fwExpCultureZone.polyId);
  }, []);

  useEffect(() => {
    tableBuilderController.addRowsGroupList(EExperimentsTableBuilderId.Zones, tableBodyData);
    tableBuilderController.addRowClickEvent(EExperimentsTableBuilderId.Zones, handleRowClick);
  }, [tableBodyData]);

  return (
    <Styled.Wrapper>
      <TableBuilder builderId={EExperimentsTableBuilderId.Zones} stylePreset="cleared" hideHeader />
    </Styled.Wrapper>
  );
};

ZonesList.displayName = 'ZonesList';

export default observer(ZonesList);
