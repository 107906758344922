import { FC } from 'react';
import { observer } from 'mobx-react';

import { IComparisonTableBuilderCell as ICell } from '../../../models/data';
import { ComparisonTableBuilderCell as Cell } from '../../../components/cells';

interface IProps {
  cell: ICell;
}

const ComparisonTableBuilderCellAutoRenderContainer: FC<IProps> = ({ cell }) => {
  return (
    <Cell
      preset={cell.autoRenderConfig.preset}
      value={cell.autoRenderConfig.value ?? ''}
      padding={cell.autoRenderConfig.cellPadding}
    />
  );
};

ComparisonTableBuilderCellAutoRenderContainer.displayName =
  'ComparisonTableBuilderCellAutoRenderContainer';

export default observer(ComparisonTableBuilderCellAutoRenderContainer);
