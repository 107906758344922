import { FC } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../utils/helpers/mobx';
import { useSortBy } from '../../../../utils/hooks/useSortBy';
import { useTableBuilderUIContext as useContext } from '../../context/UI/TableBuilderUIContext/hooks';
import { useTableBuilderDataTestId as useDataTestId } from '../../hooks';
import { TableBuilderStore as Store } from '../../mobx/stores';
import { TableBuilderRowsGroupContainer as RowsGroupContainer } from '../rowsGroups/TableBuilderRowsGroupContainer';

import Styled from './TableBuilderContent.styles';

interface ITableBuilderContentProps {
  borderType: string;
}

const TableBuilderContent: FC<ITableBuilderContentProps> = ({ borderType }) => {
  const store = useStore(Store);

  const context = useContext();

  const rowsGroupList = useSortBy(store.getRowsGroupList(context.builderId));

  const getDataTestId = useDataTestId(context.builderId);

  return (
    <Styled.Wrapper {...getDataTestId('content')}>
      {rowsGroupList.map(rowsGroup => (
        <RowsGroupContainer rowsGroup={rowsGroup} borderType={borderType} />
      ))}
    </Styled.Wrapper>
  );
};

TableBuilderContent.displayName = 'TableBuilderContent';

export default observer(TableBuilderContent);
