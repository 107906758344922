import { makeAutoObservable } from 'mobx';
import moment from 'moment';

import {
  ITableBuilderCellConfig as ICellConfig,
  ITableBuilderColumnConfig as IColumnConfig,
  ITableBuilderRowsGroupConfig as IRowsGroupConfig,
  ITableBuilderConfig as IBuilderConfig,
} from '../../../../../../../../../../common/features/TableBuilder/models/configs';
import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { EExperimentsTableBuilderId } from '../../../../../../../constants/configs';
import { DocumentsStore } from '../../stores';
import { DocumentsActions } from '../../../components';

@provide.transient()
export class DocumentsConfigsService {
  @lazyInject(DocumentsStore)
  protected documentsStore: DocumentsStore;

  constructor() {
    makeAutoObservable(this);
  }

  createConfig = (onClickDownloadFile, onClickDeleteFile): IBuilderConfig => {
    const columnList = this.createColumnList();
    const cellList = this.createCellList(onClickDownloadFile, onClickDeleteFile);
    const rowsGroupList = this.createRowsGroupList();

    return {
      id: EExperimentsTableBuilderId.Documents,
      columnConfigList: columnList,
      cellConfigList: cellList,
      rowsGroupConfigList: rowsGroupList,
    };
  };

  createColumnList = (): IColumnConfig[] => {
    const fileName = this.createFileNameColumn();
    const fileUploadDate = this.createFileUploadDateColumn();
    const fileUploadUser = this.createFileUploadUserColumn();
    const actions = this.createActionsColumn();

    return [fileName, fileUploadDate, fileUploadUser, actions];
  };

  createFileNameColumn = (): IColumnConfig => {
    return {
      id: 'fileName',
      name: 'Название',
      width: {
        default: '1fr',
      },
    };
  };

  createFileUploadDateColumn = (): IColumnConfig => {
    return {
      id: 'fileUploadDate',
      name: 'Дата',
      width: {
        default: '1fr',
      },
    };
  };

  createFileUploadUserColumn = (): IColumnConfig => {
    return {
      id: 'fileUploadUser',
      name: 'Загрузил',
      width: {
        default: '1fr',
      },
    };
  };

  createActionsColumn = (): IColumnConfig => {
    return {
      id: 'actions',
      name: '',
      width: {
        default: '54px',
      },
    };
  };

  createCellList = (onClickDownloadFile, onClickDeleteFile): ICellConfig[] => {
    const fileName = this.createFileNameCell();
    const fileUploadDate = this.createFileUploadDateCell();
    const fileUploadUser = this.createFileUploadUserCell();
    const actions = this.createActionsCell(onClickDownloadFile, onClickDeleteFile);

    return [fileName, fileUploadDate, fileUploadUser, actions];
  };

  createFileNameCell = (): ICellConfig => {
    return {
      id: 'fileName',
      autoRenderConfig: {
        renderValue: row => row.fileName,
      },
    };
  };

  createFileUploadDateCell = (): ICellConfig => {
    return {
      id: 'fileUploadDate',
      autoRenderConfig: {
        renderValue: row => moment(row.fileUploadDate).format('DD.MM.YYYY'),
        height: 40,
      },
    };
  };

  createFileUploadUserCell = (): ICellConfig => {
    return {
      id: 'fileUploadUser',
      autoRenderConfig: {
        renderValue: row => row.fileUploadUser.fullName,
      },
    };
  };

  createActionsCell = (onClickDownloadFile, onClickDeleteFile): ICellConfig => {
    return {
      id: 'actions',
      customRenderConfig: {
        renderCell: row => (
          <DocumentsActions
            row={row}
            onClickDownloadFile={onClickDownloadFile}
            onClickDeleteFile={onClickDeleteFile}
          />
        ),
      },
    };
  };

  createRowsGroupList = (): IRowsGroupConfig[] => {
    return [
      {
        id: EExperimentsTableBuilderId.Documents,
        rowConfigList: [],
        customRenderConfig: {
          render: (_, rowsChildren) => <>{rowsChildren}</>,
        },
      },
    ];
  };
}
