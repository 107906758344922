import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../../assets/styles/colors';

import horizontalDefaultIconSvg from './assets/icons/horizontal-orientation-icon-default-24x24.svg';
import horizontalSelectedIconSvg from './assets/icons/horizontal-orientation-icon-selected-24x24.svg';
import verticalDefaultIconSvg from './assets/icons/vertical-orientation-icon-default-24x24.svg';
import verticalSelectedIconSvg from './assets/icons/vertical-orientation-icon-selected-24x24.svg';

const HorizontalOrientationButton = styled.div<{ $isSelected: boolean }>`
  width: 24px;
  height: 24px;
  
  cursor: pointer;
  
  ${({ $isSelected }) =>
    $isSelected
      ? css`
          background: url(${horizontalSelectedIconSvg});
        `
      : css`
          background: url(${horizontalDefaultIconSvg});
        `}}
`;

const VerticalOrientationButton = styled(HorizontalOrientationButton)`
  ${({ $isSelected }) =>
    $isSelected
      ? css`
          background: url(${verticalSelectedIconSvg});
        `
      : css`
          background: url(${verticalDefaultIconSvg});
        `}}
`;

const OrientationLabel = styled.div`
  font-size: 14px;
  line-height: 20px;

  margin-right: 12px;

  color: ${ECommonColor.GeneralBlack};
`;

const Name = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  margin-left: 14px;

  color: ${ECommonColor.GeneralBlack};
`;

const FlexWrapper = styled.div<{ $isHidden?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  gap: 8px;

  ${({ $isHidden }) =>
    $isHidden
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding-bottom: 12px;
  margin-bottom: 12px;

  border-bottom: 1px solid ${ECommonColor.BorderGray};
`;

const Body = styled.div<{ $isHidden?: boolean }>`
  display: flex;
  flex-wrap: wrap;

  ${({ $isHidden }) =>
    $isHidden
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}
`;

const Wrapper = styled.div``;

const StyledComparisonTableImagesStage = {
  HorizontalOrientationButton,
  VerticalOrientationButton,
  OrientationLabel,
  Name,
  FlexWrapper,
  Header,
  Body,
  Wrapper,
};

export default StyledComparisonTableImagesStage;
