import { ISelectOption } from '../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { IFormConfig } from '../../../../../../../../../../../../common/features/form/models';
import { REQUIRED_TEXT } from '../../../../../../../../../../../../common/utils/constants/dateValidation';

const AUDIT_INFO_FORM_KEY = 'auditInfo';

interface IAuditInfoForm {
  organizationId: string;
  experimentId: string;
  planStartDate: string;
  planEndDate: string;
  parentStepId: string;
  techOperationTypeId: string;
  assigneeId: string;
  name: string;
}

const createAuditInfoFormConfig = ({
  usersSearchQueryHandler,
}: {
  usersSearchQueryHandler: (v: string) => Promise<ISelectOption[]>;
}): IFormConfig<IAuditInfoForm> => {
  const commonInformationFormConfig: IFormConfig<IAuditInfoForm> = {
    formKey: AUDIT_INFO_FORM_KEY,
    form: {
      organizationId: '',
      experimentId: '',
      planStartDate: '',
      planEndDate: '',
      parentStepId: '',
      techOperationTypeId: '',
      assigneeId: '',
      name: '',
    },
    elements: {
      organizationId: {
        name: 'organizationId',
        type: 'select',
        order: 1,
        isBlocked: true,
        label: 'Организация',
      },
      experimentId: {
        name: 'experimentId',
        type: 'select',
        order: 2,
        isBlocked: true,
        label: 'Опыт',
      },
      planStartDate: {
        name: 'planStartDate',
        type: 'date',
        order: 3,
        isBlocked: true,
        label: 'Начало наблюдения',
      },
      planEndDate: {
        name: 'planEndDate',
        type: 'date',
        order: 4,
        isBlocked: true,
        label: 'Конец наблюдения',
      },
      parentStepId: {
        name: 'parentStepId',
        type: 'select',
        order: 5,
        isBlocked: true,
        label: 'Агротехнический приём',
        selectOptions: {
          defaultValue: { label: 'Не выбран', value: '' },
        },
      },
      techOperationTypeId: {
        name: 'techOperationTypeId',
        type: 'select',
        order: 6,
        isBlocked: true,
        label: 'Операция',
      },
      assigneeId: {
        name: 'assigneeId',
        type: 'select',
        dependencyName: 'experimentId',
        order: 7,
        isRequired: true,
        isLoading: true,
        label: 'Исполнитель',
        selectOptions: {
          defaultValue: { label: 'Выберите из списка', value: '' },
          emptyOption: { label: 'Выберите из списка', value: '' },
          search: {},
          searchQuery: {
            handler: usersSearchQueryHandler,
          },
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
      },
      name: {
        name: 'name',
        type: 'input',
        order: 8,
        isRequired: true,
        dependencyName: 'experimentId',
        label: 'Название наблюдения',
        inputOptions: {
          maxLength: 50,
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
      },
    },
  };

  return commonInformationFormConfig;
};

export type { IAuditInfoForm };

export { AUDIT_INFO_FORM_KEY, createAuditInfoFormConfig };
