import { changeCultureZoneNameModalConfig } from './changeCultureZoneName';
import { addPreviousCultureModalConfig } from './addPreviousCulture';

const KEY = 'createExperimentZones';

const NAME = {
  changeCultureZoneName: changeCultureZoneNameModalConfig.name,
  addPreviousCulture: addPreviousCultureModalConfig.name,
};

const CONFIG_LIST = [changeCultureZoneNameModalConfig, addPreviousCultureModalConfig];

const zonesModalConfig = {
  key: KEY,
  name: NAME,
  configList: CONFIG_LIST,
};

export default zonesModalConfig;
