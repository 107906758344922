import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import {
  IExperimentPlanDictionaryValue,
  IExperimentPlanValue as IValue,
} from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentPlanTable/ExperimentPlanTable.model';
import { IComparisonTableBuilderCellConfig as ICellConfig } from '../../../../../../../../../../../common/features/ComparisonTableBuilder/models/configs';
import { ComparisonStore } from '../../../stores';
import { TComparisonRowConfig as TRowConfig } from '../../../../types';
import { ComparisonComparableCell as ComparableCell } from '../../../../components';

@provide.transient()
class CommonComparisonCellsService {
  @lazyInject(ComparisonStore)
  protected comparisonStore: ComparisonStore;

  createCellConfigList = (rowConfig: TRowConfig, valueList: IValue[]): ICellConfig[] => {
    const cellConfigList = valueList.map((value, i) => {
      return this.createCellConfigByAttributeType(rowConfig, value, i);
    });

    return cellConfigList;
  };

  createCellConfigByAttributeType = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    switch (rowConfig.initialModel.type) {
      case 'string':
        return this.createStringCellConfig(rowConfig, value, position);

      case 'double':
        return this.createDoubleCellConfig(rowConfig, value, position);

      case 'dictionary':
        return this.createDictionaryCellConfig(rowConfig, value, position);

      case 'formula':
        return this.createDoubleCellConfig(rowConfig, value, position);

      case 'summary':
        return this.createSummaryCellConfig(rowConfig, value, position);

      default:
    }
  };

  createStringCellConfig = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    const header = this.comparisonStore.getHeaderByPosition(position);

    const cellConfig: ICellConfig = {
      columnId: header.id,
      rowId: rowConfig.id,
    };

    if (value.tooltip) {
      cellConfig.customRenderConfig = {
        render: (cell, dataTestId) => {
          return (
            <ComparableCell
              factValue={value.stringValue}
              planValue={value.tooltip as string}
              dataTestId={dataTestId}
            />
          );
        },
      };

      return cellConfig;
    }

    cellConfig.autoRenderConfig = {
      preset: 'default',
      cellPadding: '0 0 12px',
      value: value.skip ? '—' : value.stringValue,
    };

    return cellConfig;
  };

  createDoubleCellConfig = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    const header = this.comparisonStore.getHeaderByPosition(position);

    const cellConfig: ICellConfig = {
      columnId: header.id,
      rowId: rowConfig.id,
    };

    cellConfig.customRenderConfig = {
      render: (cell, dataTestId) => {
        return <ComparableCell factValue={value.doubleValue} dataTestId={dataTestId} />;
      },
    };

    return cellConfig;
  };

  createDictionaryCellConfig = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    const header = this.comparisonStore.getHeaderByPosition(position);

    const cellConfig: ICellConfig = {
      columnId: header.id,
      rowId: rowConfig.id,
    };

    if (value.tooltip) {
      cellConfig.customRenderConfig = {
        render: (cell, dataTestId) => {
          const tooltipValue = value.tooltip as IExperimentPlanDictionaryValue[];

          return (
            <ComparableCell
              factValue={this.createFullDictionaryLabel(value.dictionaryValues?.[0])}
              planValue={this.createFullDictionaryLabel(tooltipValue[0])}
              dataTestId={dataTestId}
            />
          );
        },
      };

      return cellConfig;
    }

    cellConfig.autoRenderConfig = {
      preset: 'default',
      cellPadding: '0 0 12px',
      value: value.skip ? '—' : this.createFullDictionaryLabel(value.dictionaryValues[0]),
    };

    return cellConfig;
  };

  createSummaryCellConfig = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    const header = this.comparisonStore.getHeaderByPosition(position);

    const cellConfig: ICellConfig = {
      columnId: header.id,
      rowId: rowConfig.id,
    };

    cellConfig.customRenderConfig = {
      render: (cell, dataTestId) => {
        return (
          <ComparableCell
            factValue={value.doubleValue}
            dataTestId={dataTestId}
            padding={'12px 0'}
            isBold
          />
        );
      },
    };

    return cellConfig;
  };

  protected createFullDictionaryLabel = (value: IExperimentPlanDictionaryValue): string => {
    if (value?.description) {
      return `${value?.name} (${value?.code})`;
    }

    return value?.name;
  };
}

export default CommonComparisonCellsService;
