import { useCallback, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { FullScreen } from '../../../../../../../../../common/components/ui';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { CreateExperimentStore } from '../../../../mobx/stores';
import { BackButtonContent, HeaderContent } from '../../components';
import { CREATE_EXPERIMENT_TABS, CREATE_EXPERIMENT_PATH_KEY } from '../../../../constants';
import { NavigationTabs } from '../../../../../../../../../common/features/NavigationTabs';
import { usePageContextualHelp } from '../../../../../../../../../common/hooks/usePageContextualHelp';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../../../common/constants/contextualPath';
import { useContextualHelpActions } from '../../../../../../../../../common/features/ContextualHelp/hooks';

const useCreateExperimentInfo = () => {
  const { clearStore, selectedExp, tableHeaderInfo, setTableHeaderInfo } =
    useStore(CreateExperimentStore);

  const navigate = useNavigate();

  usePageContextualHelp(EContextualParentPath.ExperimentsExperiment);
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.ExperimentsExperiment,
    ContextualPaths.ExperimentsExperimentHeaderTitle
  );

  const backButtonHandler = useCallback(() => {
    navigate('/experiments');
  }, []);

  useEffect(() => {
    return () => {
      clearStore();
    };
  }, []);

  const handleGetScrollData = useCallback(
    (_scrollTop: number) => {
      if (tableHeaderInfo.ref) {
        const isHeaderStuck =
          tableHeaderInfo.ref.current.getBoundingClientRect().top === tableHeaderInfo.stuckPosition;

        if (isHeaderStuck) {
          setTableHeaderInfo({ isStuck: true });
        } else {
          setTableHeaderInfo({ isStuck: false });
        }
      }
    },
    [tableHeaderInfo]
  );

  const isShowBackButtonContent = Boolean(selectedExp?.name);

  return (
    <FullScreen
      backButtonText={'Создание опыта'}
      backButtonHandler={backButtonHandler}
      data-test-id="create-exreriment-fullscreen"
      getScrollData={handleGetScrollData}
      headerContent={<HeaderContent />}
      backButtonContent={
        isShowBackButtonContent ? (
          <BackButtonContent selectedExp={selectedExp} ContextualHelpIcon={ContextualHelpIcon} />
        ) : null
      }
      goBackText={'К списку опытов'}
      marginBottom={0}
    >
      <NavigationTabs
        tabs={CREATE_EXPERIMENT_TABS}
        pathKey={CREATE_EXPERIMENT_PATH_KEY}
        dataTestId="create-experiment"
      />

      <Outlet />
    </FullScreen>
  );
};

export default useCreateExperimentInfo;
