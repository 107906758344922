import { AxiosError } from 'axios';

import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { AxiosService } from '../../axios/AxiosService';
import {
  SendExperimentWizardProgressReq,
  TChangeExperimentReq,
  TGetCultureZoneExperimentListReq,
  TGetDictionaryEntityListRes,
  TGetExperimentContractReq,
  TGetExperimentReq,
  TGetExperimentShortInfoRequest,
  TGetExperimentShortInfoResponse,
  TUpdateExperimentFactTableRequest,
  TUpdateExperimentFilesReq,
} from '../../../../../../api';
import { IServiceOptions, IServiceReturnData } from '../../../../models/service';
import {
  IExperiment,
  IExperimentContract,
  IExperimentCultureZone,
} from '../../../../../../api/models/as-fields/experiments';
import { createExpContractSelectOption } from '../../../../utils/helpers/selectOptions';
import { IShowErrorModal } from '../../../../utils/hooks/useErrorModal';
import { TApiRequest, TApiResponse } from '../../axios/AxiosService/Axios.service.types';
import { TUpdateExperimentNotesReq } from '../../../../../../api/endpoints/as-fields/experiments/updateExperimentNotes';

@provide.singleton()
class ExperimentService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  getExperiment = async (payload: TGetExperimentReq): Promise<IExperiment | AxiosError> => {
    const { getExperiment } = this.axiosService.api;

    try {
      const fetchedExperiment = await getExperiment(payload, { omit: ['id'] });

      return fetchedExperiment;
    } catch (e) {
      return e;
    }
  };

  getExperimentShortInfoList = async (
    payload: TGetExperimentShortInfoRequest
  ): Promise<TGetExperimentShortInfoResponse> => {
    try {
      const response = await this.axiosService.api.getExperimentShortInfo(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  getExperimentContract = async (
    payload: TGetExperimentContractReq,
    options?: IServiceOptions
  ): Promise<IServiceReturnData<IExperimentContract>> => {
    const { getExperimentContract } = this.axiosService.api;

    try {
      const fetchedExpContract = await getExperimentContract(payload, { omit: ['id'] });

      const data: IServiceReturnData<IExperimentContract> = {
        data: fetchedExpContract,
      };

      if (options?.isCreateSelectOptions) {
        data.selectOptionList = [createExpContractSelectOption(fetchedExpContract)];
      }

      return data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  changeExperiment = async (
    payload: TChangeExperimentReq,
    showErrorHandler?: IShowErrorModal
  ): Promise<IExperiment> => {
    const { changeExperiment } = this.axiosService.api;

    try {
      const fetchedExperiment = await changeExperiment(payload, { omit: ['id'] });

      return fetchedExperiment;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      showErrorHandler?.(e as AxiosError);
    }
  };

  getCultureZoneList = async (
    payload: TGetCultureZoneExperimentListReq
  ): Promise<IExperimentCultureZone[]> => {
    const { getCultureZoneExperimentList } = this.axiosService.api;

    try {
      const fetchedCultureZoneList = await getCultureZoneExperimentList(payload, {
        omit: ['experimentId'],
      });

      return fetchedCultureZoneList;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getCultureZone = async (
    payload: TApiRequest<'getExperimentCultureZone'>
  ): Promise<TApiResponse<'getExperimentCultureZone'>> => {
    const { getExperimentCultureZone } = this.axiosService.api;

    try {
      const fetchedCultureZone = await getExperimentCultureZone(payload, {
        omit: ['id'],
      });

      return fetchedCultureZone;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  sendWizardProgress = async (payload: SendExperimentWizardProgressReq): Promise<boolean> => {
    const { sendExperimentWizardProgress } = this.axiosService.api;

    try {
      await sendExperimentWizardProgress(payload, {
        omit: ['id'],
      });

      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  generateTechTaskReport = async (experimentId: string): Promise<Blob> => {
    const { generateTechTaskReport } = this.axiosService.api;

    const response = await generateTechTaskReport({ experimentId }, { responseType: 'blob' });

    return response;
  };

  generateFinalReport = async (experimentId: string): Promise<Blob> => {
    const { generateFinalReport } = this.axiosService.api;

    const response = await generateFinalReport({ experimentId }, { responseType: 'blob' });

    return response;
  };

  getExperimentList = async (
    payload: TApiRequest<'getExperimentList'>,
    config?: {
      query?: Record<string, any>;
      actions?: {
        showLoader?: () => void;
        hideLoader?: () => void;
        success?: (response: TApiResponse<'getExperimentList'>) => void;
      };
    }
  ): Promise<TApiResponse<'getExperimentList'>> => {
    try {
      config?.actions?.showLoader?.();

      const response = await this.axiosService.api.getExperimentList(payload, {
        query: config?.query,
      });

      if (config?.actions?.success) {
        config?.actions?.success?.(response);
      }

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      config?.actions?.hideLoader?.();
    }
  };

  getExperimentContractList = async (
    payload: TApiRequest<'newGetExperimentContractList'>,
    config?: {
      query?: Record<string, any>;
      actions?: {
        showLoader?: () => void;
        hideLoader?: () => void;
        success?: (response: TApiResponse<'newGetExperimentContractList'>) => void;
      };
    }
  ): Promise<TApiResponse<'newGetExperimentContractList'>> => {
    try {
      config?.actions?.showLoader?.();

      const response = await this.axiosService.api.newGetExperimentContractList(payload, {
        query: config?.query,
      });

      if (config?.actions?.success) {
        config?.actions?.success?.(response);
      }

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      config?.actions?.hideLoader?.();
    }
  };

  getExperimentFactTable = async (
    payload: TApiRequest<'getExperimentFactTable'>,
    config?: {
      actions?: {
        showLoader?: () => void;
        hideLoader?: () => void;
        success?: (response: TApiResponse<'getExperimentFactTable'>) => void;
        rejected?: (e: AxiosError) => void;
      };
    }
  ): Promise<TApiResponse<'getExperimentFactTable'>> => {
    try {
      config?.actions?.showLoader?.();

      const response = await this.axiosService.api.getExperimentFactTable(payload, {
        omit: ['id'],
      });

      if (config?.actions?.success) {
        config?.actions?.success?.(response);
      }

      return response;
    } catch (e) {
      config?.actions?.rejected?.(e);
    } finally {
      config?.actions?.hideLoader?.();
    }
  };

  getExperimentPlanTable = async (
    payload: TApiRequest<'getExperimentPlanTable'>,
    config?: {
      actions?: {
        showLoader?: () => void;
        hideLoader?: () => void;
        success?: (response: TApiResponse<'getExperimentPlanTable'>) => void;
        rejected?: (e: AxiosError) => void;
      };
    }
  ): Promise<TApiResponse<'getExperimentPlanTable'>> => {
    try {
      config?.actions?.showLoader?.();

      const response = await this.axiosService.api.getExperimentPlanTable(payload, {
        omit: ['id'],
      });

      if (config?.actions?.success) {
        config?.actions?.success?.(response);
      }

      return response;
    } catch (e) {
      config?.actions?.rejected?.(e);
    } finally {
      config?.actions?.hideLoader?.();
    }
  };

  getTotalComparisonTable = async (
    payload: TApiRequest<'getExperimentTotalComparisonTable'>,
    config?: {
      actions?: {
        showLoader?: () => void;
        hideLoader?: () => void;
        success?: (response: TApiResponse<'getExperimentTotalComparisonTable'>) => void;
        rejected?: (e: AxiosError) => void;
      };
    }
  ): Promise<TApiResponse<'getExperimentTotalComparisonTable'>> => {
    try {
      config?.actions?.showLoader?.();

      const response = await this.axiosService.api.getExperimentTotalComparisonTable(payload, {
        omit: ['id'],
      });

      if (config?.actions?.success) {
        config?.actions?.success?.(response);
      }

      return response;
    } catch (e) {
      config?.actions?.rejected?.(e);
    } finally {
      config?.actions?.hideLoader?.();
    }
  };

  updateExperimentFactTable = async (
    payload: TUpdateExperimentFactTableRequest,
    config?: {
      actions?: {
        showLoader?: () => void;
        hideLoader?: () => void;
        success?: (response: TApiResponse<'updateExperimentFactTable'>) => void;
        rejected?: (e: AxiosError) => void;
      };
    }
  ): Promise<TApiResponse<'updateExperimentFactTable'>> => {
    try {
      config?.actions?.showLoader?.();

      const response = await this.axiosService.api.updateExperimentFactTable(payload, {
        path: `${payload.id}/fact`,
      });

      if (config?.actions?.success) {
        config?.actions?.success?.(response);
      }

      return true;
    } catch (e) {
      config?.actions?.rejected?.(e);
    } finally {
      config?.actions?.hideLoader?.();
    }
  };

  updateExperimentAttachedFiles = async (
    payload: TUpdateExperimentFilesReq
  ): Promise<IExperiment> => {
    try {
      const experimnet = await this.axiosService.api.updateExperimentFiles(payload, {
        omit: ['experimentId'],
      });

      return experimnet;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  updateExperimentNotes = async (payload: TUpdateExperimentNotesReq): Promise<IExperiment> => {
    try {
      const experimnet = await this.axiosService.api.updateExperimentNotes(payload);

      return experimnet;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default ExperimentService;
