import { makeAutoObservable } from 'mobx';

import { IExperimentCultureZone } from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { ISelectOption } from '../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { DictionaryService } from '../../../../../../../../../../../common/mobx/services/da-dictionary';
import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { createDictionaryEntitySelectOptionList } from '../../../../../../../../../../../common/utils/helpers/selectOptions';
import { ICreateAgrochemicalForm } from '../../config/forms/createAgrochemicalForm';
import { toAgrochemicalValue } from '../../utils/toAgrochemicalValue';
import { AgrochemicalService } from '../services/Agrochemical.service';

import { CalculationController } from './Calculation/Calculation.controller';

const attributesForFetch = {
  humusMethod: { id: '47dab72f-ffe3-4a39-91fb-3ebef5241fea', type: 'doubleValue', field: 'humus' },
  phMethod: { id: 'e4a14583-3909-4cad-b63a-60bd7dcd76b1', type: 'doubleValue', field: 'ph' },
  poMethod: { id: '8f3d652c-50c7-4567-95f2-030877cb5662', type: 'doubleValue', field: 'po' },
  koMethod: { id: '7467bcac-17eb-4b36-8d3f-88cb6b00d740', type: 'doubleValue', field: 'ko' },
};

const attributes = {
  type: {
    id: 'fd1f9730-451d-4499-8a47-a4b03f3ee35c',
    type: 'dictionaryValue',
    field: 'type',
    attributeId: '4c832b5d-c3a8-4f88-8f90-dfe0128077fd',
  },
  mechanicalCompositionMethod: {
    id: '975b0fac-eccd-4819-b3e1-c83762cbb3f6',
    type: 'dictionaryValue',
    field: 'mechanicalComposition',
  },
  ...attributesForFetch,
};

@provide.singleton()
export class AgrochemicalController {
  constructor() {
    makeAutoObservable(this);
  }

  @lazyInject(DictionaryService)
  dictionaryService: DictionaryService;

  @lazyInject(AgrochemicalService)
  agrochemicalService: AgrochemicalService;

  @lazyInject(CalculationController)
  calculationController: CalculationController;

  createAgrochemicalValue = async (
    form: ICreateAgrochemicalForm,
    cultureZone: IExperimentCultureZone
  ) => {
    const attributeValues = Object.entries(form).reduce((acc, [key, value]) => {
      const attribute = attributes[key];

      if (attribute) {
        const attributeValue = form[attribute?.field];

        if (attributeValue) {
          const currentValue = toAgrochemicalValue(attributeValue);

          acc.push({
            examinationAttributeMethodId: attribute.attributeId ?? value,
            [attribute.type]: currentValue,
          });
        }
      }

      return acc;
    }, []);

    await this.agrochemicalService.createExaminationAttributeValues({
      sampleDate: form.date,
      values: attributeValues,
      experimentZoneId: cultureZone.id,
    });

    await this.calculationController.fetchExaminationAttributeValues(cultureZone.id);
  };

  fetchTypes = async () => {
    const { content } = await this.dictionaryService.getDictionaryEntityList({
      remoteName: 'soilType',
    });

    return createDictionaryEntitySelectOptionList(content);
  };

  fetchMechanicalComposition = async () => {
    const { content } = await this.dictionaryService.getDictionaryEntityList({
      remoteName: 'soilTexture',
    });

    return createDictionaryEntitySelectOptionList(content);
  };

  fetchOptionListByAttributeId = async (attributeId: string) => {
    const methods = await this.agrochemicalService.getMethodsByAttributeId(attributeId);

    return methods.map(method => ({ value: method.id, label: method.name }));
  };

  fetchOptionsListForEdit = async () => {
    const optionLists: Record<string, ISelectOption[]> = {};
    for await (const [key, value] of Object.entries(attributesForFetch)) {
      const methods = await this.fetchOptionListByAttributeId(value.id);
      optionLists[key] = methods;
    }

    return { optionLists };
  };

  fetchOptionsListAho = async () => {
    const optionLists: Record<string, ISelectOption[]> = {};
    for await (const [key, value] of Object.entries(attributes)) {
      const methods = await this.fetchOptionListByAttributeId(value.id);
      optionLists[key] = methods;
    }

    const types = await this.fetchTypes();
    const mechanicalComposition = await this.fetchMechanicalComposition();

    return { optionLists, types, mechanicalComposition };
  };
}
