import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { techOperationFormConfig, ITechOperationForm } from '../../configs/forms/techOperationForm';
import { useForm } from '../../../../../../../../../../../common/features/form/utils/hooks';
import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { HistoryStore } from '../../mobx/stores/History.store';
import { TechOperationController } from '../../mobx/controllers/TechOperation.controller';
import { HistoryController } from '../../mobx/controllers/History.contorller';
import { TechOperationStore } from '../../mobx/stores/TechOperation.store';
import { CreateExperimentStore } from '../../../../../../mobx/stores';
import { useIsOverflow } from '../../../../../../../../../../../common/utils/hooks';

import Styled from './OperationForm.style';

interface IOperationForm {
  formType: 'edit' | 'add';
}

const OperationForm: FC<IOperationForm> = ({ formType }) => {
  const { selectedExp } = useStore(CreateExperimentStore);

  const { experimentId } = useParams<{ experimentId: string }>();
  const form = useForm<ITechOperationForm>(techOperationFormConfig.formKey);
  const {
    elements,
    registerForm,
    submitForm,
    addOptionList,
    formData,
    initialFormData,
    changeInitialFormValue,
    changeListOfFormValue,
    blockElement,
    changeElementData,
  } = form;

  const isFormChanged = useMemo(
    () => !_.isEqual(formData, initialFormData),
    [formData, initialFormData]
  );

  const isRequiredFieldsNotEmpty = useMemo(
    () =>
      _.values(
        _.omit(formData, [
          'phenophaseFrom',
          'phenophaseTo',
          'bbshFrom',
          'bbshTo',
          'usageMethodType',
        ])
      ).every(item => Boolean(item)),
    [formData]
  );

  const { activeCultureZone, currentTechOperation, getExperimentStepById, nutritionHistories } =
    useStore(HistoryStore);
  const { onChangeTechOperation } = useStore(HistoryController);
  const {
    getBbchCodeById,
    getPhenophaseCodeById,
    getBbchOptionList,
    getPhenophaseOptionList,
    getTechOperationTypesOptionList,
    getPhenophases,
    createTechOperation,
    updateTechOperation,
    getUsageMethodTypeOptions,
  } = useStore(TechOperationController);
  const { phenphase: currentPhenphases, bbch: currentBbches } = useStore(TechOperationStore);

  const { closeModal, openModalByModalId } = useModal();

  const ref = useRef();
  const isOverflow = useIsOverflow(ref);

  const currentExperimentId = useMemo(
    () => selectedExp?.id ?? experimentId,
    [selectedExp, experimentId]
  );
  const {
    date: DateComponent,
    typeOperation: TypeOperation,
    usageMethodType: UsageMethodType,
    bbshFrom: BbshFrom,
    bbshTo: BbshTo,
    name: Name,
    phenophaseFrom: PhenophaseFrom,
    phenophaseTo: PhenophaseTo,
  } = elements;

  useEffect(() => {
    registerForm(techOperationFormConfig);
    (async () => {
      /**
       * Когда режим редактирования, не выполняем запрос за списком операций, так как
       * запрещена смена её названия.
       */

      if (formType !== 'edit') {
        const techOperationsTypes = await getTechOperationTypesOptionList();
        addOptionList('typeOperation', techOperationsTypes);
      }

      /**
       * Отображаем при загрузке модального окна текущую фенофазу, чтобы данные не
       * прыгали.
       */
      if (currentTechOperation?.phenophase) {
        addOptionList('phenophaseFrom', [
          {
            label: currentTechOperation?.phenophase.parent.name,
            value: currentTechOperation?.phenophase.parent.id,
          },
        ]);
      }

      if (currentTechOperation?.phenophaseEnd) {
        addOptionList('phenophaseTo', [
          {
            label: currentTechOperation?.phenophaseEnd.parent.name,
            value: currentTechOperation?.phenophaseEnd.parent.id,
          },
        ]);
      }

      await getPhenophases(activeCultureZone);
      const phenophase = getPhenophaseOptionList();
      addOptionList('phenophaseFrom', phenophase);
      addOptionList('phenophaseTo', phenophase);

      const usageMethodTypeList = await getUsageMethodTypeOptions();
      addOptionList('usageMethodType', usageMethodTypeList);
    })();
  }, []);

  useEffect(() => {
    if (formData?.phenophaseFrom) {
      const bbch = getBbchOptionList(formData.phenophaseFrom);
      addOptionList('bbshFrom', bbch);
    } else {
      changeListOfFormValue({ bbshFrom: '' });
    }
  }, [formData?.phenophaseFrom]);

  useEffect(() => {
    if (formData?.phenophaseTo) {
      const bbch = getBbchOptionList(formData.phenophaseTo);
      addOptionList('bbshTo', bbch);
    } else {
      changeListOfFormValue({ bbshTo: '' });
    }
  }, [formData?.phenophaseTo]);

  useEffect(() => {
    if (!formData?.bbshFrom && !formData?.bbshTo) {
      changeElementData('bbshTo', {
        schema: {
          isShownError: false,
          errorTitle: '',
        },
      });
    } else {
      const fromCode = getBbchCodeById(formData?.bbshFrom);
      const toCode = getBbchCodeById(formData?.bbshTo);

      if (fromCode > toCode) {
        changeElementData('bbshTo', {
          schema: {
            isShownError: true,
            errorTitle: '"BBCH до" не должна быть меньше "BBCH от"',
          },
        });
      } else {
        changeElementData('bbshTo', {
          schema: {
            isShownError: false,
            errorTitle: '',
          },
        });
      }
    }
  });

  useEffect(() => {
    if (!formData?.phenophaseFrom && !formData?.phenophaseTo) {
      changeElementData('phenophaseTo', {
        schema: {
          isShownError: false,
          errorTitle: '',
        },
      });
    } else {
      const fromCode = getPhenophaseCodeById(formData?.phenophaseFrom);
      const toCode = getPhenophaseCodeById(formData?.phenophaseTo);

      if (fromCode > toCode) {
        changeElementData('phenophaseTo', {
          schema: {
            isShownError: true,
            errorTitle: '"Фенофаза до" не должна быть меньше "Фенофазы от"',
          },
        });
      } else {
        changeElementData('phenophaseTo', {
          schema: {
            isShownError: false,
            errorTitle: '',
          },
        });
      }
    }
  });

  const addOnSuccess = useCallback(() => {
    submitForm(async forms => {
      const id = await createTechOperation(forms, activeCultureZone, currentExperimentId);
      const experimentStep = getExperimentStepById(id);
      onChangeTechOperation(experimentStep);
      openModalByModalId('addNutritionHistoryInventoryValues');
    });
  }, [activeCultureZone, currentExperimentId]);

  const editOnSuccess = useCallback(() => {
    submitForm(async forms => {
      await updateTechOperation(
        forms,
        activeCultureZone,
        currentTechOperation,
        currentExperimentId
      );

      closeModal();
    });
  }, [activeCultureZone, currentTechOperation, currentExperimentId]);

  const getPhenphaseByBbchId = useCallback(
    (bbchId?: string) => {
      if (!bbchId) {
        return '';
      }

      const currentBbch = currentBbches.find(item => item.id === bbchId);

      const currentPhenophase = currentPhenphases.find(item => item.id === currentBbch?.parentId);

      return currentPhenophase?.id ?? '';
    },
    [currentBbches, currentPhenphases]
  );

  useEffect(() => {
    if (formType === 'edit') {
      // Если у нас режим редактирования, то блокируем поле 'Тип операции'.
      blockElement('typeOperation', true);

      /**
       * Когда режим редактирования, то опшен для поля "Тип операции" явно
       * хардкодим из полученной модели. Это необходимо для того, чтобы правильно
       * отобразить наименование без лишних запросов на бэк.
       */
      if (currentTechOperation?.techOperationType) {
        addOptionList('typeOperation', [
          {
            label: currentTechOperation?.techOperationType?.name,
            value: currentTechOperation?.techOperationType?.id,
          },
        ]);
      }

      if (currentTechOperation) {
        const phenophaseFrom = getPhenphaseByBbchId(currentTechOperation?.phenophase?.id);
        const phenophaseTo = getPhenphaseByBbchId(currentTechOperation?.phenophaseEnd?.id);

        const currentNutritionHistoryDate = nutritionHistories.find(
          history => history.experimentStep.id === currentTechOperation.id
        )?.date;

        changeListOfFormValue({
          name: currentTechOperation.name,
          date: currentNutritionHistoryDate ? new Date(currentNutritionHistoryDate) : null,
          typeOperation: currentTechOperation.techOperationType.id,
          phenophaseFrom,
          phenophaseTo,
          bbshFrom: currentTechOperation?.phenophase?.id ?? '',
          bbshTo: currentTechOperation?.phenophaseEnd?.id ?? '',
          usageMethodType: currentTechOperation?.usageMethodType?.id,
        });

        changeInitialFormValue({
          name: currentTechOperation.name,
          date: currentNutritionHistoryDate ? new Date(currentNutritionHistoryDate) : null,
          typeOperation: currentTechOperation.techOperationType.id,
          phenophaseFrom,
          phenophaseTo,
          bbshFrom: currentTechOperation?.phenophase?.id ?? '',
          bbshTo: currentTechOperation?.phenophaseEnd?.id ?? '',
          usageMethodType: currentTechOperation?.usageMethodType?.id,
        });
      }
    }
  }, [currentTechOperation, getPhenphaseByBbchId]);

  const successButton = useMemo(() => {
    const handler = formType === 'add' ? addOnSuccess : editOnSuccess;

    return {
      title: formType === 'add' ? 'Добавить' : 'Сохранить',
      handler,
      disabled: formType === 'add' ? !isRequiredFieldsNotEmpty : !isFormChanged,
    };
  }, [formType, isFormChanged, addOnSuccess, editOnSuccess, isRequiredFieldsNotEmpty]);

  const denyButton = useMemo(() => {
    return { title: 'Отменить', handler: closeModal };
  }, [closeModal]);

  return (
    <Styled.Wrapper>
      <Styled.ContentWrapper ref={ref}>
        <Styled.Attribute width="45%" marginBottom="24px" marginRight="12px">
          {Name && <Name />}
        </Styled.Attribute>
        <Styled.Attribute width="45%" marginBottom="24px">
          {DateComponent && <DateComponent />}
        </Styled.Attribute>
        <Styled.Attribute width="100%" marginBottom="24px">
          {TypeOperation && <TypeOperation />}
        </Styled.Attribute>
        <Styled.Attribute width="100%" marginBottom="24px">
          {UsageMethodType && <UsageMethodType />}
        </Styled.Attribute>
        <Styled.Attribute width="45%" marginBottom="24px" marginRight="12px">
          {PhenophaseFrom && <PhenophaseFrom />}
        </Styled.Attribute>
        <Styled.Attribute width="45%" marginBottom="24px">
          {BbshFrom && <BbshFrom />}
        </Styled.Attribute>
        <Styled.Attribute width="45%" marginBottom="24px" marginRight="12px">
          {PhenophaseTo && <PhenophaseTo />}
        </Styled.Attribute>
        <Styled.Attribute width="45%" marginBottom="24px">
          {BbshTo && <BbshTo />}
        </Styled.Attribute>
      </Styled.ContentWrapper>

      <Styled.FooterWrapper $isOverflow={isOverflow}>
        <ModalFooter successButton={successButton} denyButton={denyButton} />
      </Styled.FooterWrapper>
    </Styled.Wrapper>
  );
};

export default observer(OperationForm);
