import { FC, memo } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { IReference } from '../../../../../../api/models/as-fields/references/Reference.model';

import Styled from './ContextualHelpToolTipIcon.styles';

interface IProps {
  help: IReference;
}

const ContextualHelpToolTipIcon: FC<IProps> = ({ help }) => {
  return (
    <AutoTooltip content={help.text} disabled={!help.text}>
      <Styled.Wrapper>
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Styled.Path
            d="M7 0.5C3.1402 0.5 0 3.64024 0 7.50004C0 11.3598 3.1402 14.5 7 14.5C10.8598 14.5 14 11.3598 14 7.50004C14 3.64024 10.8598 0.5 7 0.5ZM7 13.2273C3.84194 13.2273 1.27273 10.6581 1.27273 7.50004C1.27273 4.34202 3.84194 1.77273 7 1.77273C10.1581 1.77273 12.7273 4.34202 12.7273 7.50004C12.7273 10.6581 10.158 13.2273 7 13.2273Z"
            fill="#151F32"
          />

          <Styled.Path
            d="M7.00017 3.46948C6.5324 3.46948 6.15186 3.85028 6.15186 4.31835C6.15186 4.78599 6.5324 5.16645 7.00017 5.16645C7.46794 5.16645 7.84849 4.78599 7.84849 4.31835C7.84849 3.85028 7.46794 3.46948 7.00017 3.46948Z"
            fill="#151F32"
          />

          <Styled.Path
            d="M7.00013 6.43945C6.64869 6.43945 6.36377 6.72437 6.36377 7.07582V10.894C6.36377 11.2454 6.64869 11.5304 7.00013 11.5304C7.35158 11.5304 7.6365 11.2454 7.6365 10.894V7.07582C7.6365 6.72437 7.35158 6.43945 7.00013 6.43945Z"
            fill="#151F32"
          />
        </svg>
      </Styled.Wrapper>
    </AutoTooltip>
  );
};

ContextualHelpToolTipIcon.displayName = 'ContextualHelpToolTipIcon';

export default memo(ContextualHelpToolTipIcon);
