import { FC, memo } from 'react';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';

import { useComparisonTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { TComparisonTableBuilderCellPreset as TCellPreset } from '../../../models/types/cells';

import Styled from './ComparisonTableBuilderCell.styles';

interface IProps {
  preset: TCellPreset;
  value: string | number;
  isMainRowCell?: boolean;
  padding?: string;
}

const ComparisonTableBuilderCell: FC<IProps> = ({ preset, value, isMainRowCell, padding }) => {
  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>(value);

  const getDataTestId = useDataTestId({ componentName: isMainRowCell ? 'row' : 'cell' });

  return (
    <AutoTooltip content={value} position={'bottom'} disabled={!isShowTooltip}>
      <Styled.Wrapper
        ref={ref}
        $preset={preset}
        $padding={padding}
        $isMainRowCell={isMainRowCell}
        {...getDataTestId()}
      >
        {value}
      </Styled.Wrapper>
    </AutoTooltip>
  );
};

ComparisonTableBuilderCell.displayName = 'ComparisonTableBuilderCell';

export default memo(ComparisonTableBuilderCell);
