import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';
import { TableBuilderController } from '../../../../../../../../common/features/TableBuilder/mobx/controllers';
import { ContractsListConfigsService } from '../../services';
import { TableBuilderStore } from '../../../../../../../../common/features/TableBuilder/mobx/stores';
import { TableFiltersBuilderController } from '../../../../../../../../common/features/TableFiltersBuilder/mobx/controllers';
import { TApiRequest } from '../../../../../../../../common/mobx/services/axios/AxiosService/Axios.service.types';
import { EContractsTableBuilderId } from '../../../../../constants/configs';
import { IContractsFilters } from '../../../../ContractsFilters/models';
import { ExperimentService } from '../../../../../../../../common/mobx/services/as-fields';

@provide.transient()
class ContractsListController {
  @lazyInject(ContractsListConfigsService)
  protected configService: ContractsListConfigsService;

  @lazyInject(ExperimentService)
  protected experimentService: ExperimentService;

  @lazyInject(TableBuilderStore)
  protected tableBuilderStore: TableBuilderStore;

  @lazyInject(TableFiltersBuilderController)
  protected tableFiltersBuilderController: TableFiltersBuilderController<IContractsFilters>;

  @lazyInject(TableBuilderController)
  protected tableBuilderController: TableBuilderController;

  initiateTable = (): void => {
    const config = this.configService.createConfig();

    this.tableBuilderController.initiateTable(config);

    this.tableBuilderController.addPaginationScrollEvent(EContractsTableBuilderId.Contracts, () => {
      return this.fetchContractList(true);
    });
  };

  /**
   * Метод получения списка договоров согласно фильтрам.
   * @param payload - выбранные фильтры.
   * @param isSavePagination - флаг, который говорит о том, сохраняем мы пагинацию или нет.
   * Когда активируется запрос после обновленных фильтров, то обнуляем пагинацию.
   */
  fetchContractList = async (isSavePagination?: boolean): Promise<void> => {
    const appliedFilters = this.tableFiltersBuilderController.getAppliedFilters(
      EContractsTableBuilderId.Contracts
    );

    if (!isSavePagination) {
      this.tableBuilderController.addCurrentPage(EContractsTableBuilderId.Contracts, 0);
      this.tableBuilderController.addTotalPages(EContractsTableBuilderId.Contracts, 0);
    }

    const currentPage = this.tableBuilderStore.getCurrentPage(EContractsTableBuilderId.Contracts);

    const payload: TApiRequest<'newGetExperimentContractList'> = {
      ...appliedFilters,
    };

    await this.experimentService.getExperimentContractList(payload, {
      query: {
        page: isSavePagination ? currentPage : 0,
        size: 25,
      },
      actions: {
        showLoader: () => {
          this.tableBuilderController.showLoader(EContractsTableBuilderId.Contracts);
        },
        hideLoader: () => {
          this.tableBuilderController.hideLoader(EContractsTableBuilderId.Contracts);
          this.tableBuilderController.hideDefaultPlug(EContractsTableBuilderId.Contracts);
        },
        success: response => {
          this.tableBuilderController.addTotalPages(
            EContractsTableBuilderId.Contracts,
            response.totalPages
          );

          this.tableBuilderController.addRowsToRowsGroupListById(
            EContractsTableBuilderId.Contracts,
            EContractsTableBuilderId.Contracts,
            response.content,
            {
              isClearPreviousList: !isSavePagination,
            }
          );
        },
      },
    });
  };
}

export default ContractsListController;
